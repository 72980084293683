import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Backdrop,
  Zoom,
} from '@material-ui/core';
// import { Select as MultiSelect } from 'react-select';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import CheckIcon from '@material-ui/icons/Check';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWorkers,
  workersReducer,
  updateWorker,
  setWorkerForDetail,
  verifyWorker,
  selectAProject,
  clearWorkerHistory,
} from '../../redux/workers';
import { useToasts } from 'react-toast-notifications';

import * as yup from 'yup';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SearchInput from 'src/components/SearchInput';
import SortSelection from 'src/components/SortSelection';
import { Link } from 'react-router-dom';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import { downloadExcel } from 'react-export-table-to-excel';
import { getSkills, jobReducer } from 'src/redux/jobs';
import defaultProjectImage from '../../assests/Projects Icon.png';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import { useContext } from 'react';
import UserContext from 'src/context/UserContext';
import { getContractors } from 'src/redux/users';
import useAccessPerView from 'src/common/Access';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  disabledLink: {
    pointerEvents: 'none',
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  verify: {
    color: '#81B711',
    backgroundColor: 'rgba(129, 183, 52,.3)',
    '&:hover': {
      color: '#81B750',

      backgroundColor: 'rgba(129, 183, 52,.5)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  downloadAll: {
    margin: '0px',
  },
  sortByContainer: {
    marginRight: '1.5rem',
  },
  table: {
    overflowX: 'hidden',
  },
  viewDetailsButton: {
    padding: '.6rem',
    fontSize: '12px',
    textTransform: 'capitalize',
    borderRadius: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    downloadAll: {
      margin: '10px 0',
    },
    sortByContainer: {
      marginRight: '0px',
    },
    table: {
      overflowX: 'scroll',
    },
    viewDetailsButton: {
      padding: '.3rem',
      fontSize: '2vw',
      margin: '5px 0',
    },
  },
}));

function WorkerProfile() {
  // console.log('WorkerProfile Mounted');

  const { isViewMode } = useAccessPerView('WORKER_ONBOARDING');
  // return <div>Hello world....</div>;
  //! VARIABLES
  const [modals, setModals] = useState({
    editWorker: false,
    detail: false,
  });
  const [selectedWorker] = useState(null);
  const [worker, setWorker] = useState({});
  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    filterBy: 0,
  });
  const [filteredArray, setFilteredArray] = useState([]);
  const [classifiedProject, setClassifiedProject] = useState('');
  const [currentProject, setCurrentProject] = useState('');
  const [LabourContractor, setLabourContractor] = useState('');
  const [userData, setUserData] = useContext(UserContext);

  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();
  const { addToast } = useToasts();
  // let navigate = useNavigate();

  let userDetails = JSON.parse(localStorage.getItem('userDetails'));

  //! SELECTORS
  const { loading, workersList, selectedProject } = useSelector(workersReducer);
  const { skillsList } = useSelector(jobReducer);

  const { projectClassificationList } = useSelector(projectReducer);

  // //! LIFE-CYCLE
  useEffect(() => {
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
      dispatch(getSkills());
    }
    if (projectClassificationList.length > 0) {
      dispatch(getContractors(projectClassificationList[0]?.projectId));
      if (selectedProject) {
        setCurrentProject(selectedProject ?? '');
        setClassifiedProject(selectedProject ? [selectedProject] : []);
        if (!workersList) {
          dispatch(clearWorkerHistory());

          return dispatch(getWorkers(selectedProject?.projectId));
        }
      } else {
        setCurrentProject(projectClassificationList[0]);
        setClassifiedProject([projectClassificationList[0]]);
        if (!workersList) {
          dispatch(getWorkers(projectClassificationList[0]?.projectId));
        }
        // dispatch(getWorkers(0));
      }
    }
  }, [projectClassificationList.length]);
  const header = [
    'WORKER NAME',
    'PHONE',
    'ADHAR CARD',
    'PAN CARD',
    'POLICE VERIFICATION',
    'HEALTH CARD',
    'BANK VERIFICATION',
  ];
  const body = workersList?.map((worker) => [
    worker?.fullName ?? 'N/A',
    worker?.phoneNumber ?? 'N/A',
    worker?.adharVerified ?? 'N/A',
    worker?.panVerified ?? 'N/A',
    worker?.policeVerification ?? 'N/A',
    worker?.healthCard ?? 'N/A',
    worker?.bankVerified ?? 'N/A',
  ]);

  function handleDownloadExcel() {
    downloadExcel({
      fileName: `WorkerList_${
        classifiedProject[0]?.name ?? 'Project_Not_Available'
      }`,
      sheet: 'Worker Sheet - Bettamint',
      tablePayload: {
        header,
        // accept two different data structures
        body: body,
      },
    });
  }

  //! METHODS
  const handleSearch = (e) => {
    const { value } = e.target;

    setFilters({ ...filters, search: value });

    if (value) {
      const data = parseInt(filters.sortBy) !== 0 ? filteredArray : workersList;

      let newFilter = data.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilteredArray(newFilter);
    }

    if (!value) {
      // if (filters.sortBy) {
      // 	handleFilters({ target: { value: filters.sortBy } });
      // }
      // if (dateRange[1] !== null) {
      // 	return handleDateRange(dateRange);
      // }
      return setFilteredArray([]);
    }
  };

  const redirectTopDetailPage = (worker) => {
    // let data = workersList.filter((item) => item.workerId === worker.workerId);
    dispatch(setWorkerForDetail(worker));
  };

  const classifyProject = (project) => {
    if (project) {
      let currentLabourContractorValue = 0;
      dispatch(getContractors(project?.projectId));
      setLabourContractor(0);
      dispatch(selectAProject(project));
      if (LabourContractor && currentLabourContractorValue !== 0) {
        const { projectId } = project;

        dispatch(getWorkers(projectId, LabourContractor?.userId));
        setCurrentProject(project);
        return setClassifiedProject(
          projectClassificationList.filter(
            (project) => project.projectId === projectId && project
          )
        );
      }

      const { projectId } = project;
      dispatch(getWorkers(projectId));
      setCurrentProject(project);
      setClassifiedProject(
        projectClassificationList.filter(
          (project) => project.projectId === projectId && project
        )
      );
    } else {
      dispatch(getContractors());
      setLabourContractor(0);
      dispatch(selectAProject(null));
      dispatch(getWorkers(0));
      setCurrentProject('');
      return setClassifiedProject('');
    }
  };

  const handleFilters = (e) => {
    const { value, name } = e.target;

    setFilters({ ...filters, sortBy: value });

    if (value == 0) return setFilteredArray([]);

    if (
      workersList?.filter(
        (worker) => worker?.workerSkills[0]?.skill?.name === value && value != 0
      ).length === 0
    )
      return setFilteredArray(null);

    setFilteredArray(
      workersList?.filter(
        (worker) => worker?.workerSkills[0]?.skill?.name === value
      )
    );
  };

  const makeVerificationOnWorker = async (workerId) => {
    let resp = await dispatch(verifyWorker(workerId));
    if (resp.status === 200) {
      dispatch(getWorkers());
      return addToast('Verified Successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  };

  const handleFiltersStatus = (e) => {
    const { value, name } = e.target;
    setFilters({ ...filters, filterBy: value });
    if (value == 0) return setFilteredArray([]);
    if (
      workersList?.filter(
        (worker) => worker?.workerTypeId === value && value != 0
      ).length === 0
    )
      return setFilteredArray(null);

    setFilteredArray(
      workersList?.filter((worker) => worker?.workerTypeId === value)
    );
  };
  const handleLabourContractor = (value) => {
    if (!value) {
      dispatch(getWorkers(currentProject?.projectId));
      setLabourContractor(0);
      return setLabourContractor(null);
    }
    setLabourContractor(value);
    dispatch(getWorkers(currentProject?.projectId, value?.userId));
  };
  const renderWorkersList = () => (
    <Grid
      container
      style={{
        position: 'relative',
        overflowX: 'auto',
      }}
    >
      <>
        <Grid
          container
          item
          xs={12}
          justify="space-between"
          style={{ marginBottom: 15 }}
        >
          <Grid
            container
            item
            md={8}
            xs={12}
            justify="flex-start"
            alignItems="center"
          >
            <Grid item md={6} xs={12} container alignItems="center">
              <Grid item md={3} xs={3}>
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    fontFamily: 'Roboto',
                  }}
                >
                  Workers |
                </Typography>
              </Grid>
              <Grid md={9} xs={12}>
                <FilterProjectComponent
                  projectClassificationList={projectClassificationList}
                  src={
                    !classifiedProject[0]?.url
                      ? defaultProjectImage
                      : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                  }
                  value={currentProject}
                  onChange={classifyProject}
                />
              </Grid>
            </Grid>
            {userData?.user.leadTypeId !== 'LabourContractor' && (
              <Grid md={4} xs={12} item>
                <FilterByLabourContractorComponent
                  value={LabourContractor}
                  onChange={handleLabourContractor}
                  setInitialUser={(user) => setLabourContractor(user)}
                  currentProject={classifiedProject[0]}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            item
            md={3}
            xs={12}
            justify="flex-end"
            alignItems="center"
            className={classes.downloadAll}
          >
            <Typography
              style={{
                color: '#81B734',
                fontSize: 14,
                marginRight: 5,
                fontFamily: 'Roboto',
                fontWeight: '500',
                cursor: 'pointer',
              }}
              onClick={handleDownloadExcel}
            >
              Download All
            </Typography>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                background: '#81B734',
                width: 25,
                height: 25,
                borderRadius: 5,
                cursor: 'pointer',
                marginRight: 10,
              }}
              onClick={handleDownloadExcel}
            >
              <SystemUpdateAltIcon
                fontSize="small"
                style={{ color: 'white', textAlign: 'center' }}
              />
            </Grid>
            <Grid item>
              <Link
                to="create-worker"
                className={isViewMode ? classes.disabledLink : ''}
              >
                <Button
                  variant="contained"
                  startIcon={<PersonAddIcon />}
                  style={{
                    background: '#81B734',
                    color: '#fff',
                    borderRadius: '10px',
                    textTransform: 'capitalize',
                  }}
                  disabled={isViewMode}
                >
                  Add Worker
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          container
          // spacing={3}
          // style={{ marginTop: '1rem' }}
          alignItems="center"
          className="input-container-nav"
          justify="space-around"
        >
          {/* container */}
          <Grid item md={7} xs={12} container justify="flex-start">
            {/* //! 1.SortInput */}
            <Grid
              item
              md={4}
              xs={12}
              container
              alignItems="center"
              justify="space-between"
              // className='padding5'

              style={{
                padding: '2px',
              }}
              className={classes.sortByContainer}
            >
              <Grid item md={4} xs={3}>
                <small>Skills:</small>
              </Grid>
              <Grid item md={8} xs={9}>
                <SortSelection
                  initialValue="Skills"
                  value={filters.sortBy}
                  handleFilters={handleFilters}
                  data={skillsList?.map((skill) => ({
                    value: skill.name,
                    label: skill.name,
                  }))}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              container
              alignItems="center"
              justify="space-between"
              // className='padding5'

              style={{
                padding: '2px',
                // marginLeft: '10px',
              }}
            >
              <Grid item xs={3} md={4}>
                <small>Filter By:</small>
              </Grid>
              <Grid item xs={9} md={8}>
                <SortSelection
                  value={filters.filterBy}
                  initialValue={'Filter'}
                  handleFilters={handleFiltersStatus}
                  data={[
                    {
                      value: 'Online',
                      label: 'Online',
                    },
                    {
                      value: 'Offline',
                      label: 'Offline',
                    },
                  ]}
                />
              </Grid>
            </Grid>
            {/* //! 2.DatePickerInput */}
          </Grid>
          {/* //! 3.SearchInput */}
          <Grid
            item
            md={5}
            xs={12}
            container
            // justify='space-evenly'
            alignItems="center"
            style={{ padding: '2px' }}
          >
            <Grid item md={2} xs={3}>
              <small>Search:</small>
            </Grid>
            <Grid item xs={9} style={{ position: 'relative' }}>
              <SearchInput
                search={filters.search}
                handleSearch={handleSearch}
                // className='font12'
              />
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <CircularProgress style={{ color: '#81B734' }} />
          </div>
        ) : workersList?.length === 0 ? (
          <Grid container justify="center" style={{ padding: '1rem' }}>
            No Search Found!
          </Grid>
        ) : (
          <Zoom in={workersList?.length > 0 ? true : false}>
            <Grid container item xs={12} className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      #
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      WORKER NAME
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      PHONE
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      ADHAR CARD
                    </TableCell>
                    {/* <TableCell
											align='center'
											className={classes.headerCellText1}>
											PAN CARD
										</TableCell> */}
                    {/* <TableCell
											align='center'
											className={classes.headerCellText1}>
											POLICE VERIFICATION
										</TableCell> */}
                    {/* <TableCell
											align='center'
											className={classes.headerCellText1}>
											HEALTH CARD
										</TableCell> */}
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      BANK VERIFICATION
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      STATUS
                    </TableCell>
                    {/* <TableCell align='center' className={classes.headerCellText1}>
										JOBS COMPLETED
									</TableCell> */}
                    {/* <TableCell align='center' className={classes.headerCellText}>
										AVG WAGES
									</TableCell> */}
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      ACTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredArray?.length ? (
                    filteredArray?.map((val, index) => (
                      <TableRow hover key={val.workerId}>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          <Grid container item xs={12} alignItems="center">
                            {/* <Grid item xs={3}>
													<img
														src='/static/images/bettamintImages/workerprofile.png'
														alt='orderProfile'
														style={{
															width: '30px',
															height: '30px',
															marginRight: 4,
														}}
													/>
												</Grid> */}

                            <Grid item xs={12}>
                              <Typography
                                style={{
                                  fontSize: 12,
                                  fontWeight: 'bold',
                                  fontFamily: 'Roboto',
                                }}
                              >
                                {val.fullName ?? 'N/A'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          <Grid item xs={12}>
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto',
                              }}
                            >
                              {val.phoneNumber}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          {val.adharVerified ? (
                            <CheckIcon style={{ color: 'green' }} />
                          ) : (
                            <CloseIcon style={{ color: 'tomato' }} />
                          )}
                        </TableCell>
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.panVerified ? (
														<CheckIcon style={{ color: 'green' }} />
													) : (
														<CloseIcon style={{ color: 'tomato' }} />
													)}
												</TableCell> */}
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.policeVerification ? (
														<CheckIcon style={{ color: 'green' }} />
													) : (
														<CloseIcon style={{ color: 'tomato' }} />
													)}
												</TableCell> */}
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.healthCard ? (
														<CheckIcon style={{ color: 'green' }} />
													) : (
														<CloseIcon style={{ color: 'tomato' }} />
													)}
												</TableCell> */}
                        {/* <TableCell align='center' className={classes.headerCellText}>
											<CheckIcon
												fontSize='small'
												style={{ color: '#25D921', textAlign: 'center' }}
											/>
										</TableCell> */}
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          {val.bankVerified ? (
                            <CheckIcon style={{ color: 'green' }} />
                          ) : (
                            <CloseIcon style={{ color: 'tomato' }} />
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          {val.workerTypeId}
                        </TableCell>
                        {/* <TableCell
											align='center'
											className={classes.headerCellText}
											style={{ fontWeight: 'bold' }}
										>
											₹621
										</TableCell> */}
                        <TableCell
                          width="20%"
                          align="center"
                          className={classes.headerCellText}
                        >
                          <Grid
                            container
                            justify={
                              userDetails?.user?.userTypeId === 'SuperAdmin'
                                ? 'space-between'
                                : 'center'
                            }
                          >
                            <Grid item>
                              <Link to="detail">
                                <Button
                                  className={classes.buttonPurpleOpacity}
                                  size="small"
                                  style={{
                                    fontSize: 12,
                                    padding: '.6rem',
                                    textTransform: 'capitalize',
                                    borderRadius: '10px',
                                  }}
                                  onClick={() => {
                                    redirectTopDetailPage(val);
                                  }}

                                  // startIcon={<VisibilityIcon />}
                                >
                                  View Details
                                </Button>
                              </Link>
                            </Grid>
                            {userDetails?.user?.userTypeId === 'SuperAdmin' && (
                              <Grid item>
                                {val.bankVerified && val.adharVerified && (
                                  <Button
                                    className={`${classes.verify} ${classes.viewDetailsButton}`}
                                    size="small"
                                    disabled={!val.isVerified ? false : true}
                                    onClick={() =>
                                      makeVerificationOnWorker(val?.workerId)
                                    }
                                    // onClick={() => redirectTopDetailPage(val)}

                                    // startIcon={<VisibilityIcon />}
                                  >
                                    {!val.isVerified ? 'Verify' : 'Verified'}
                                  </Button>
                                )}
                              </Grid>
                            )}
                          </Grid>
                          {/* <Button
												size='small'
												className={classes.buttonPurpleOpacity}
												style={{
													fontSize: 12,
													paddingTop: '.3rem',
													marginBottom: '.5rem',
												}}
												onClick={() => {
													setModals({ ...modals, editWorker: true });
													setSelectedWorker(val);
													setWorker(val);
												}}
												// startIcon={<EditIcon />}
											>
												Edit
											</Button> */}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : filters.search !== '' || filteredArray === null ? (
                    <TableRow>No Search Found!</TableRow>
                  ) : (
                    workersList?.map((val, index) => (
                      <TableRow hover key={val.workerId}>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          <Grid container item xs={12} alignItems="center">
                            <Grid item xs={12}>
                              <Typography
                                style={{
                                  fontSize: 12,
                                  fontWeight: 'bold',
                                  fontFamily: 'Roboto',
                                }}
                              >
                                {val.fullName ?? 'N/A'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          <Grid item xs={12}>
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'Roboto',
                              }}
                            >
                              {val.phoneNumber}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          {val.adharVerified ? (
                            <CheckIcon style={{ color: 'green' }} />
                          ) : (
                            <CloseIcon style={{ color: 'tomato' }} />
                          )}
                        </TableCell>
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.panVerified ? (
														<CheckIcon style={{ color: 'green' }} />
													) : (
														<CloseIcon style={{ color: 'tomato' }} />
													)}
												</TableCell> */}
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.policeVerification ? (
														<CheckIcon style={{ color: 'green' }} />
													) : (
														<CloseIcon style={{ color: 'tomato' }} />
													)}
												</TableCell> */}
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.healthCard ? (
														<CheckIcon style={{ color: 'green' }} />
													) : (
														<CloseIcon style={{ color: 'tomato' }} />
													)}
												</TableCell> */}

                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          {val.bankVerified ? (
                            <CheckIcon style={{ color: 'green' }} />
                          ) : (
                            <CloseIcon style={{ color: 'tomato' }} />
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          {val.workerTypeId}
                        </TableCell>

                        <TableCell
                          width="20%"
                          align="center"
                          className={classes.headerCellText}
                        >
                          <Grid
                            container
                            justify={
                              userDetails?.user?.userTypeId === 'SuperAdmin'
                                ? 'space-between'
                                : 'center'
                            }
                          >
                            <Grid item>
                              <Link to="detail">
                                <Button
                                  className={`${classes.buttonPurpleOpacity}
																		${classes.viewDetailsButton}`}
                                  size="small"
                                  onClick={() => {
                                    redirectTopDetailPage(val);
                                  }}

                                  // startIcon={<VisibilityIcon />}
                                >
                                  View Details
                                </Button>
                              </Link>
                            </Grid>
                            {userDetails?.user?.userTypeId === 'SuperAdmin' && (
                              <Grid item>
                                {val.bankVerified && val.adharVerified && (
                                  <Button
                                    className={`${classes.verify} ${classes.viewDetailsButton}`}
                                    size="small"
                                    disabled={!val.isVerified ? false : true}
                                    onClick={() =>
                                      makeVerificationOnWorker(val?.workerId)
                                    }
                                    // onClick={() => redirectTopDetailPage(val)}

                                    // startIcon={<VisibilityIcon />}
                                  >
                                    {!val.isVerified ? 'Verify' : 'Verified'}
                                  </Button>
                                )}
                              </Grid>
                            )}
                          </Grid>
                          {/* <Button
												size='small'
												className={classes.buttonPurpleOpacity}
												style={{
													fontSize: 12,
													paddingTop: '.3rem',
													marginBottom: '.5rem',
												}}
												onClick={() => {
													setModals({ ...modals, editWorker: true });
													setSelectedWorker(val);
													setWorker(val);
												}}
												// startIcon={<EditIcon />}
											>
												Edit
											</Button> */}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Zoom>
        )}
      </>
    </Grid>
  );

  const handleWorkerFields = (e) => {
    if (
      e.target.name === 'identityNumber' ||
      e.target.name === 'wage' ||
      e.target.name === 'phoneNumber' ||
      e.target.name === 'rating'
    ) {
      setWorker({
        ...worker,

        [e.target.name]: parseInt(e.target.value),
      });
      return;
    }
    setWorker({
      ...worker,

      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = () => {
    if (
      worker.fullName !== selectedWorker.fullName ||
      worker.skillLevel !== selectedWorker.skillLevel ||
      worker.rating !== selectedWorker.rating ||
      worker.wage !== selectedWorker.wage ||
      worker.identityNumber !== selectedWorker.identityNumber ||
      worker.phoneNumber !== selectedWorker.phoneNumber
    ) {
      dispatch(updateWorker(worker));
      return;
    }
  };
  return (
    <Page className={classes.root} id="zoom-level" title="Workers Profile">
      <Container maxWidth={false}>
        <Grid
          container
          style={{
            background: 'white',
            padding: '1em',
            borderRadius: '10px',
          }}
        >
          <Dialog
            open={modals.editWorker}
            onClose={() => setModals({ ...modals, editWorker: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Worker</DialogTitle>
            <DialogContent>
              <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Grid container justify="center">
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    id="fullName"
                    name="fullName"
                    label="Full Name"
                    value={worker.fullName}
                    onChange={handleWorkerFields}
                    // helperText={worker.fullName === '' && 'Field is required'}
                    // error={worker.fullName === ''}
                    // value={formik.values.fullName}
                    // onChange={formik.handleChange}
                    // error={
                    // 	formik.touched.fullName && Boolean(formik.errors.fullName)
                    // }
                    // helperText={
                    // 	formik.touched.fullName && formik.errors.fullName
                    // }
                    style={{ marginBottom: '1rem' }}
                  />
                  <TextField
                    fullWidth
                    id="identityNumber"
                    name="identityNumber"
                    label="Identity Number"
                    type="number"
                    value={worker.identityNumber}
                    onChange={handleWorkerFields}
                    // helperText={
                    // 	worker.identityNumber === Number.NaN &&
                    // 	'Field is required'
                    // }
                    // error={worker.identityNumber === Number.NaN}
                    // value={formik.values.identityNumber}
                    // onChange={formik.handleChange}
                    // error={
                    // 	formik.touched.identityNumber &&
                    // 	Boolean(formik.errors.identityNumber)
                    // }
                    // helperText={
                    // 	formik.touched.identityNumber &&
                    // 	formik.errors.identityNumber
                    // }
                    style={{ marginBottom: '1rem' }}
                  />
                  <TextField
                    fullWidth
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone"
                    type="number"
                    value={worker.phoneNumber}
                    onChange={handleWorkerFields}
                    // value={formik.values.phoneNumber}
                    // onChange={formik.handleChange}
                    // error={
                    // 	formik.touched.phoneNumber &&
                    // 	Boolean(formik.errors.phoneNumber)
                    // }
                    // helperText={
                    // 	formik.touched.phoneNumber && formik.errors.phoneNumber
                    // }
                    style={{ marginBottom: '1rem' }}
                  />
                  {/* <TextField
											fullWidth
											id='status'
											name='status'
											label='Status'
											value={formik.values.status}
											onChange={formik.handleChange}
											error={
												formik.touched.status && Boolean(formik.errors.status)
											}
											helperText={formik.touched.status && formik.errors.status}
											style={{ marginBottom: '1rem' }}
										/> */}
                  <TextField
                    fullWidth
                    id="rating"
                    name="rating"
                    label="Rating"
                    type="number"
                    value={worker.rating}
                    onChange={handleWorkerFields}
                    // value={formik.values.rating}
                    // onChange={formik.handleChange}
                    // error={
                    // 	formik.touched.rating && Boolean(formik.errors.rating)
                    // }
                    // helperText={formik.touched.rating && formik.errors.rating}
                    style={{ marginBottom: '1rem' }}
                  />
                  <TextField
                    fullWidth
                    id="wage"
                    name="wage"
                    label="Wage"
                    type="number"
                    value={worker.wage}
                    onChange={handleWorkerFields}
                    // value={formik.values.wage}
                    // onChange={formik.handleChange}
                    // error={formik.touched.wage && Boolean(formik.errors.wage)}
                    // helperText={formik.touched.wage && formik.errors.wage}
                    style={{ marginBottom: '1rem' }}
                  />
                  <TextField
                    fullWidth
                    id="skillLevel"
                    name="skillLevel"
                    label="Skill Level"
                    value={worker.skillLevel}
                    onChange={handleWorkerFields}
                    // type='email'
                    // value={formik.values.skillLevel}
                    // onChange={formik.handleChange}
                    // error={
                    // 	formik.touched.skillLevel &&
                    // 	Boolean(formik.errors.skillLevel)
                    // }
                    // helperText={
                    // 	formik.touched.skillLevel && formik.errors.skillLevel
                    // }
                    style={{ marginBottom: '1rem' }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={loading}
                variant="outlined"
                size="small"
                startIcon={<EditIcon />}
                color="primary"
                onClick={handleSubmit}
              >
                Update Worker
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => setModals({ ...modals, editWorker: false })}
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {renderWorkersList()}
          {/* {edit && renderViewWorkersDetail()} */}
          {/* {createNewProject && renderCreateProject()} */}
        </Grid>
      </Container>
    </Page>
  );
}

export default WorkerProfile;
