import { Avatar, makeStyles, TextField, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  projectInput: {
    fontSize: '8px !important',
  },
}));

const FilterProjectComponent = (props) => {
  const {
    projectClassificationList,
    src,
    value,
    onChange,
    capitalLabel = false,
  } = props;

  const classes = useStyles();

  return (
    <div style={{ display: 'flex' }}>
      {projectClassificationList && (
        <Grid container alignItems="center">
          <Grid item style={{ paddingRight: '10px' }}>
            <Avatar
              alt="Project Image"
              src={src}
              style={{ width: '40px', height: '40px', marginLeft: '.5rem' }}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              autoSelect
              size="small"
              options={projectClassificationList}
              getOptionLabel={(option) => option.name}
              style={{ minWidth: '200px' }}
              value={value}
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={capitalLabel ? 'SELECT PROJECT' : 'Select Project'}
                  InputLabelProps={{
                    style: { color: '#ADBAC3' }, // Change label color
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      {/* <Select
				style={{
					marginLeft: '.8rem',
					fontFamily: 'Roboto',
					fontWeight: 600,
					backgroundColor: 'none',
				}}
				native
				value={value}
				onChange={onChange}
				inputProps={
					{
						// name: 'age',
						// id: 'age-native-simple',
					}
				}
				disableUnderline={true}
				classes={{ root: classes.selectRoot }}> */}
      {/* <option value={0} style={{ padding: '5px' }}>
					{' '}
					Filter by Project
				</option> */}

      {/* {projectClassificationList &&
					projectClassificationList?.map((project) => (
						<option key={project.projectId} value={project.projectId}>
							{project.name}
						</option>
					))} */}
      {/* </Select> */}
    </div>
  );
};

export default FilterProjectComponent;
