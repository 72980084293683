import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Grid,
  IconButton,
  TextField,
  makeStyles,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import MapDrawShapeManager from 'google-maps-draw-shape-lib';
import Geocode from 'react-geocode';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { useToasts } from 'react-toast-notifications';

import useOnclickOutside from 'react-cool-onclickoutside';
import LayersIcon from '@material-ui/icons/Layers';
import { v4 as uuidv4 } from 'uuid';
// import { useToasts } from 'react-toast-notifications';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '1rem',
    fontFamily: 'Roboto',
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',

    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  mainTitle: {
    fontWeight: 600,
    fontSize: '1rem',
    fontFamily: 'Roboto',
  },
  input: {
    display: 'none',
  },
  ImagePlaceHolder: {
    cursor: 'pointer',
  },
  labels: {
    color: 'rgba(173, 186, 195, 1)',
    fontWeight: 600,
    fontSize: '10px',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    paddingBottom: '.5rem',
  },
  inputItem: {
    marginBottom: '1.3rem',
  },
  btn: {
    fontSize: '12px',
    backgroundColor: 'rgba(129, 183, 52, 1)',
    color: '#fff',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    padding: '.6rem 1.3rem',
  },
  btn1: {
    marginRight: '1rem',
  },
  colorPrimary: {
    color: '#1cf119',
  },
  projectName: {
    paddingRight: '1rem',
  },
  inputContainer: {
    paddingLeft: '15px',
  },
  controlContainer: {
    position: 'absolute',
    marginTop: '2rem',
    right: 0,
    left: 0,
    bottom: '5%',
    margin: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    projectName: {
      paddingRight: 0,
    },
    inputContainer: {
      paddingLeft: 0,
    },
    btn1: {
      marginRight: 0,
      marginBottom: 10,
    },
  },
}));

// Polygon Label
function CustomOverlay() {}

CustomOverlay.prototype = new window.google.maps.OverlayView();

CustomOverlay.prototype.addLabel = function (position, text, map, labelColor) {
  this.position = position;
  this.text = text;
  this.map = map;
  this.div = null;
  this.bgColor = labelColor;
  this.setMap(map);
};

CustomOverlay.prototype.onAdd = function () {
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.textAlign = 'center';
  div.style.padding = '0px 15px';
  div.style.backgroundColor = this.bgColor;
  div.style.borderRadius = '10px';
  div.style.color = 'white';
  div.style.fontWeight = 'bold';
  div.style.fontSize = '16px';
  div.style.lineHeight = '30px';
  div.innerHTML = this.text;
  this.div = div;
  const panes = this.getPanes();
  panes.overlayLayer.appendChild(div);
};

CustomOverlay.prototype.draw = function () {
  const overlayProjection = this.getProjection();
  const position = overlayProjection.fromLatLngToDivPixel(this.position);
  const div = this.div;
  div.style.left = position.x + 'px';
  div.style.top = position.y + 'px';
};

CustomOverlay.prototype.onRemove = function () {
  this.div.parentNode.removeChild(this.div);
  this.div = null;
};

const RenderLabelField = ({ polygon, updatePolygonLabel, removePolygon }) => {
  const value = polygon?.defaultLabel ?? '';
  const backgroundColor = polygon?.labelColor ?? 'N/A';
  const [text, setText] = useState(value);
  const [showLabel, setShowLabel] = useState(false);

  useEffect(() => {
    setShowLabel(polygon.label !== 'N/A');
  }, [polygon.label]);

  const defaultStyles = {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    transition: 'top 300ms ease, transform 300ms ease',
    top: showLabel ? '11%' : '0%',
    visibility: showLabel ? 'visible' : 'hidden',
  };

  if (!polygon.parentId || polygon.label !== 'N/A') return null;

  const setDivRef = (element) => {
    if (element) {
      setTimeout(() => {
        setShowLabel(true);
      }, 300);
    }
  };

  return (
    <FormControl style={defaultStyles} innerRef={setDivRef}>
      <TextField
        placeholder="Enter Region Label..."
        label="Region Label"
        focused
        variant="filled"
        onChange={(e) => setText(e.target.value)}
        onFocus={(event) => {
          event.target.select();
        }}
        InputLabelProps={{ style: { color: 'white' } }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{
                  padding: 0,
                  margin: 0,
                  border: '4px solid white',
                }}
                onClick={() => updatePolygonLabel(text, polygon)}
              >
                <CheckIcon style={{ color: '#52b202' }} />
              </IconButton>
              <IconButton
                style={{
                  padding: 0,
                  margin: '0px 0px 0px 5px',
                  border: '4px solid white',
                }}
                onClick={() => removePolygon(polygon.id)}
              >
                <CloseIcon style={{ color: 'rgb(238, 27, 27)' }} />
              </IconButton>
            </InputAdornment>
          ),
          disableUnderline: true,
          style: {
            color: 'white',
            fontWeight: 'bold',
            borderRadius: '10px',
            backgroundColor,
            padding: 5,
            maxWidth: 300,
          },
        }}
      />
    </FormControl>
  );
};

const MapView = (props) => {
  const [state, setState] = useState({
    mapLoaded: false,
    drawingMode: false,
    drawFreeHandMode: false,
    shape: [],
    polygons: [],
    activePolygonId: null,
  });

  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: props.activeProject?.latitude ?? 12.9716,
      lng: props.activeProject?.longitude ?? 77.5946,
    },
    zoom: 13,
  });
  const [satellite, setSatellite] = useState(true);
  const [loadedMap, setLoadedMap] = useState(null);

  const mapDrawShapeManagerRef = useRef(null);
  const { addToast } = useToasts();

  useEffect(() => {
    if (!value && !props.activeProject) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setDefaultProps({
            ...defaultProps,
            center: { lat: latitude, lng: longitude },
          });
        },
        () => {
          alert(
            'Failed to get user location. Please make sure your browser supports location services and that you have allowed location sharing.'
          );
        }
      );
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    //Edit mode is on
    const preRenderPolygons = props.activeProject;
    if (loadedMap && preRenderPolygons) {
      handlePrerendering();
    }
  }, [loadedMap]); // eslint-disable-line

  //! INSTANCES
  const classes = useStyles();

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  function onGoogleApiLoaded(map) {
    setState((prevState) => ({ ...prevState, mapLoaded: true }));
    mapDrawShapeManagerRef.current = [];
    setLoadedMap(map);
  }

  const handlePrerendering = () => {
    preAddPolygonsFromActiveProject();
    preAddLocationFromActiveProject();
  };

  const preAddPolygonsFromActiveProject = () => {
    let parentId = null;
    props.activeProject.geofencingList.forEach((polygon) => {
      const { shapeFeatures } = preAddPolygon({
        ...polygon,
        parentId,
      });
      const isParentProject = typeof shapeFeatures.parentId !== 'string';
      if (isParentProject) {
        // addedPolygon was parent/project
        parentId = shapeFeatures.id;
      } else {
        // addedPolygon was child/sub-region
        parentId = shapeFeatures.parentId;
      }
    });
  };

  const preAddLocationFromActiveProject = () => {
    Geocode.fromLatLng(
      props.activeProject.latitude,
      props.activeProject.longitude
    ).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const center = {
          lat: props.activeProject.latitude,
          lng: props.activeProject.longitude,
        };
        setValue(address);
        setState((prevState) => ({ ...prevState, center }));
        props.handleLocationChange(address);
      },
      (error) => {
        setValue(error.message);
      }
    );
  };

  /**
   * Polygon is added to the map in two steps
   * 1: create a MapDrawShapeManagerRef instance
   * 2: add a polygon coordinates to the MapDrawShapeManager instance
   * @param {Object} polygon
   * @returns {Object} Added polygon
   */
  const preAddPolygon = (polygon) => {
    const { boundary, name } = polygon;
    const convertedCoordinates = convertCoordinatesToLatLng(boundary);
    const polygonRef = createPolygonRef({
      ...polygon,
      ...convertedCoordinates,
    });
    const newPolygon = addPolygonToState(polygonRef, true);
    updatePolygonLabel(name, newPolygon);
    return polygonRef;
  };

  /**
   * Converts coordinates to latlng format and retains the parentId.
   * @param {Object} boundary
   * @returns {Object} Converted boundary with latlng coordinates
   */
  const convertCoordinatesToLatLng = (boundary) => {
    const convertedLatLng = boundary.map((item) => ({
      lat: item.latitude,
      lng: item.longitude,
    }));
    return { ...boundary, coordinates: convertedLatLng };
  };

  const createPolygonRef = (polygon) => {
    const createdPolygon = createPolygonRefWithFeatures(polygon.parentId);
    createdPolygon.shapeRef.initDrawnShape(polygon.coordinates);
    createdPolygon.shapeRef.setDrawingMode(false);
    return { ...polygon, ...createdPolygon };
  };

  /**
   *
   * Entry point for adding and updating the refs providing base to initiate
   * polygon drawings.
   */
  const handleDrawingIfMapIsLoaded = () => {
    if (!loadedMap) {
      return;
    }
    const isDrawingEnabled = state.drawingMode;
    if (isDrawingEnabled) {
      stopDrawing();
    } else {
      startDrawing();
      addOrUpdatePolygonToMap();
    }
  };

  const addOrUpdatePolygonToMap = () => {
    const lastPolygon =
      mapDrawShapeManagerRef.current[mapDrawShapeManagerRef.current.length - 1];
    let parentId;
    if (lastPolygon) {
      parentId = lastPolygon.parentId || lastPolygon.id;
    }
    createPolygonRefWithFeatures(parentId);
  };

  function generateRandomColor() {
    let color = Math.floor(Math.random() * 16777215).toString(16);
    color = color.padStart(6, '0');
    color = `#${color}`;
    return color;
  }

  /**
   * Darkens a given hex color by a specified percentage.
   * @param {string} hexColor - Hex color code (e.g., "#RRGGBB").
   * @param {number} percent - Percentage to darken (0 to 100).
   * @returns {string} Darkened hex color code.
   */
  function darkenColor(hexColor, percent) {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    const darkerR = Math.max(0, r - Math.floor((percent / 100) * 255));
    const darkerG = Math.max(0, g - Math.floor((percent / 100) * 255));
    const darkerB = Math.max(0, b - Math.floor((percent / 100) * 255));

    const darkerHex = `#${(darkerR * 65536 + darkerG * 256 + darkerB)
      .toString(16)
      .padStart(6, '0')}`;
    return darkerHex;
  }

  function getPolygonOptions() {
    const color = generateRandomColor();
    const labelColor = darkenColor(color, 30);
    return {
      polygonOptions: {
        clickable: false,
        fillColor: color,
        fillOpacity: 0.1,
        strokeColor: color,
        strokeWeight: 4,
        strokeOpacity: 1,
        labelColor,
      },
    };
  }

  const handleAddAction = (polygon) => {
    validateAndAddPolygonToState(polygon);
  };

  const handleRemoveAction = (refId) => {
    clearPolygon(refId);
  };

  /**
   *  The handleDrawCallback is called for two actions (Add, Remove) but
   *  there is no explicit way of knowing which type of action invoked the
   *  callback. hence we need to find a way to differentiate between them.
   * @param {Object} polygon  - { refId, labelColor }
   */
  const handleDrawCallback = (polygon) => {
    const { coordinates, shapeFeatures } = polygon;
    const isRemoveAction = coordinates.length === 0;
    if (isRemoveAction) {
      handleRemoveAction(shapeFeatures.id);
    } else {
      handleAddAction(polygon);
    }
  };

  const isValidRegion = (projectBoundary, regionBoundary) => {
    const innerBoundaryLatLngs = (childCoordinates) =>
      childCoordinates.map(
        (coord) => new window.google.maps.LatLng(coord.lat, coord.lng)
      );
    const outerBoundaryLatLngs = (parentCoordinates) =>
      parentCoordinates.map(
        (coord) => new window.google.maps.LatLng(coord.lat, coord.lng)
      );

    const outerBoundaryPolygon = new window.google.maps.Polygon({
      paths: outerBoundaryLatLngs(projectBoundary),
    });

    const isInnerBoundaryInside = innerBoundaryLatLngs(regionBoundary).every(
      (vertex) =>
        window.google.maps.geometry.poly.containsLocation(
          vertex,
          outerBoundaryPolygon
        )
    );
    return isInnerBoundaryInside;
  };

  /**
   * Creates a new MapDrawShapeManager instance with features.
   * @param {String} parentId - Parent ID
   * @returns {Object} { shapeRef, shapeFeatures }
   */
  const createPolygonRefWithFeatures = (parentId) => {
    const { polygonOptions } = getPolygonOptions();
    const shapeFeatures = createShapeFeatures(parentId, polygonOptions);
    const shapeRef = createShapeManager(shapeFeatures, polygonOptions);
    mapDrawShapeManagerRef.current.push(shapeRef);
    return { shapeRef, shapeFeatures };
  };

  /**
   * Creates shape features (id, label color, etc.).
   * @param {String} parentId - Parent ID
   * @param {Object} polygonOptions - Polygon options
   * @returns {Object} Shape features
   */
  const createShapeFeatures = (parentId, polygonOptions) => {
    return {
      parentId,
      id: uuidv4(),
      labelColor: polygonOptions.labelColor,
    };
  };

  /**
   * Creates a MapDrawShapeManager instance.
   * @param {Object} shapeFeatures - Shape features
   * @param {Object} polygonOptions - Polygon options
   * @returns {Object} Shape manager instance
   */
  const createShapeManager = (shapeFeatures, polygonOptions) => {
    const shapeRefInstance = new MapDrawShapeManager(
      loadedMap,
      (shapeCoordinates) =>
        handleDrawCallback({ coordinates: shapeCoordinates, shapeFeatures }),
      true,
      state.drawFreeHandMode,
      polygonOptions,
      props.initialPointInnerHtml,
      props.deletePointInnerHtml
    );
    shapeRefInstance.id = shapeFeatures.id;
    shapeRefInstance.parentId = shapeFeatures.parentId;
    return shapeRefInstance;
  };

  const stopDrawing = () => {
    //update ref
    const lastRef = mapDrawShapeManagerRef.current.pop();
    if (lastRef) {
      lastRef.setDrawingMode(false);
      lastRef.resetDrawnShape();
    }
    //update state
    setState((prevState) => ({ ...prevState, drawingMode: false }));
  };

  const startDrawing = () => {
    setState((prevState) => ({ ...prevState, drawingMode: true }));
  };

  // function setDrawingMode(drawingMode) {
  //   mapDrawShapeManagerRef.current.setDrawingMode(drawingMode);
  //   setState((prevState) => ({ ...prevState, drawingMode }));
  // }

  // function setDrawFreeHandMode(drawFreeHandMode) {
  //   mapDrawShapeManagerRef.current.setDrawFreeHandMode(drawFreeHandMode);
  //   setState((prevState) => ({ ...prevState, drawFreeHandMode }));
  // }

  function removeLastShape() {
    if (mapDrawShapeManagerRef.current.length === 0) {
      return;
    }
    const lastShapeIndex = mapDrawShapeManagerRef.current.length - 1;
    const lastShapeRef = mapDrawShapeManagerRef.current[lastShapeIndex];
    lastShapeRef.setDrawingMode(false);
    const refWithoutPolygonExist = !lastShapeRef.drawnShape;
    let refIdToRemove = lastShapeRef.id;
    if (refWithoutPolygonExist) {
      const secondLastRef = mapDrawShapeManagerRef.current[lastShapeIndex - 1];
      if (!secondLastRef) {
        return;
      }
      refIdToRemove = secondLastRef.id;
    }
    clearPolygon(refIdToRemove);
  }

  const removePolygonFromState = (id) => {
    setState((prevState) => {
      const removeChildPolygons = (parentId, polygons) => {
        const updatedPolygons = polygons.filter((poly) => {
          if (poly.id === parentId) {
            poly.polygonOverlay.setMap(null);
            return false; // Remove the parent polygon
          }
          if (poly.parentId === parentId) {
            poly.polygonOverlay.setMap(null);
            // Recursively remove child polygons of this child
            removeChildPolygons(poly.id, polygons);
            return false;
          }
          return true; // Keep other polygons
        });
        return updatedPolygons;
      };

      const polygon = prevState.polygons.find((p) => p.id === id);
      if (polygon) {
        const updatedPolygons = removeChildPolygons(id, prevState.polygons);
        if (props.onShapeChangeCallback) {
          dispatchDropCallback(polygon, updatedPolygons);
        }
        return { ...prevState, polygons: updatedPolygons };
      }
      return prevState;
    });
  };

  const removePolygonFromRef = (id) => {
    const polygonIndex = mapDrawShapeManagerRef.current.findIndex(
      (ref) => ref.id === id
    );
    if (polygonIndex !== -1) {
      mapDrawShapeManagerRef.current[polygonIndex].resetDrawnShape();
      mapDrawShapeManagerRef.current[polygonIndex].setDrawingMode(false);
      const cascadeEffect =
        !mapDrawShapeManagerRef.current[polygonIndex].parentId;
      if (cascadeEffect) {
        cascadeChildRegions(polygonIndex);
      }
      setState((prevState) => ({ ...prevState, drawingMode: false }));
      mapDrawShapeManagerRef.current.splice(polygonIndex, 1);
    }
  };

  const cascadeChildRegions = (parentIndex) => {
    const { id: parentId } = mapDrawShapeManagerRef.current[parentIndex];
    const refChilds = mapDrawShapeManagerRef.current.filter(
      (ref) => ref.parentId === parentId
    );
    refChilds.forEach((children) => {
      children.resetDrawnShape();
      children.setDrawingMode(false);
      const childIndex = mapDrawShapeManagerRef.current.findIndex(
        (ref) => ref.id === children.id
      );
      mapDrawShapeManagerRef.current.splice(childIndex, 1);
    });
  };

  const getButtonTextForAddingPolygon = () => {
    let labelSuffix = '';
    let labelPreffix = '';
    const drawingInProcess = state.drawingMode;
    if (drawingInProcess) {
      labelPreffix = 'Stop ';
    } else {
      labelPreffix = 'Start ';
    }
    const parentProjectExists = state.polygons.some(
      (polygon) => !polygon.parentId
    );
    if (parentProjectExists) {
      const numChildRegions = state.polygons.filter(
        (polygon) => polygon.parentId
      ).length;
      labelSuffix = 'marking sub region ';
      labelSuffix += numChildRegions + 1;
    } else {
      labelSuffix = 'marking project boundary';
    }
    return labelPreffix + labelSuffix;
  };

  const clearPolygon = (id) => {
    removePolygonFromRef(id);
    removePolygonFromState(id);
  };

  const addPolygonOverlay = async (polygon, label, labelColor, map) => {
    const bounds = new window.google.maps.LatLngBounds();
    polygon.coordinates.forEach((coord) => {
      bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
    });
    const center = bounds.getCenter();
    polygon.polygonOverlay.addLabel(center, label, map, labelColor);
  };

  const updateStateWithPolygon = (prevState, polygon) => {
    const polygons = [...prevState.polygons, polygon];
    if (props.onShapeChangeCallback) {
      dispatchAddCallback(polygon, polygons);
    }
    return {
      ...prevState,
      shape: polygon.coordinates,
      drawingMode: false,
      polygons: polygons,
      polygonCount: prevState.polygonCount + 1,
    };
  };

  const validateAndAddPolygonToState = React.useMemo(
    () => (polygon) => {
      const isValid = validatePolygon(polygon);
      if (isValid) {
        addPolygonToState(polygon);
      } else {
        showErrorAndCleanup(polygon);
      }
    },
    []
  );

  const addPolygonToState = (polygon, initialAdd = false) => {
    const { coordinates, shapeFeatures } = polygon;
    const { id, labelColor, parentId } = shapeFeatures;
    const maxLabelCount = getMaxLabel(initialAdd);
    const label = shapeFeatures.parentId
      ? `Sub-region (${maxLabelCount + 1})`
      : `Project region`;

    const newPolygon = {
      id,
      parentId,
      coordinates,
      polygonOverlay: new CustomOverlay(),
      defaultLabel: 'Enter Label ...',
      labelColor,
      label: 'N/A',
    };

    if (!initialAdd && !shapeFeatures.parentId) {
      addPolygonOverlay(newPolygon, label, labelColor, loadedMap);
    }
    setState((prevState) => updateStateWithPolygon(prevState, newPolygon));
    return newPolygon;
  };

  const showErrorAndCleanup = (polygon) => {
    removePolygonFromRef(polygon.shapeFeatures.id);
    addToast(
      'Project Sub-Region should reside inside of the project boundary!',
      {
        appearance: 'error',
        autoDismiss: true,
      }
    );
  };

  /**
   * We only need to validate if the subject (added polygon) is sub-region
   * i.e all the coordinates lie inside the parent project boundary.
   * @param {Object} polygon
   * @returns {Boolean}
   */
  const validatePolygon = (polygon) => {
    const { shapeFeatures } = polygon;
    const isSubRegion = typeof shapeFeatures.parentId === 'string';
    if (isSubRegion) {
      const isValidSubRegion = validateSubRegion(polygon);
      return isValidSubRegion;
    }
    return true;
  };

  const validateSubRegion = (polygon) => {
    const { shapeFeatures } = polygon;
    const parentRegion = getParentRegion(shapeFeatures.parentId);

    const parentRegionCoordinates = parentRegion.coordinates;
    const subRegionCoordinates = polygon.coordinates;

    const isOutOfProject = !isValidRegion(
      parentRegionCoordinates,
      subRegionCoordinates
    );
    if (isOutOfProject) {
      return false;
    }
    return true;
  };

  const getParentRegion = (parentId) => {
    let parent = null;
    setState((prevState) => {
      parent = prevState.polygons.find((polygon) => polygon.id === parentId);
      return prevState;
    });
    return parent;
  };

  const dispatchAddCallback = (polygon, updatedPolygons) => {
    const convertedArray = updatedPolygons.map((polygon) => {
      const boundary = polygon.coordinates.map((coord) => {
        return {
          latitude: coord.lat,
          longitude: coord.lng,
        };
      });
      const name = polygon.label;
      return { name, boundary };
    });
    const callBackObject = {
      action: 'add',
      item: polygon,
      updatedPolygons: convertedArray,
    };
    props.onShapeChangeCallback(callBackObject);
  };

  const dispatchDropCallback = (polygon, updatedPolygons) => {
    const convertedArray = updatedPolygons.map((polygon) => {
      const boundary = polygon.coordinates.map((coord) => {
        return {
          latitude: coord.lat,
          longitude: coord.lng,
        };
      });
      const name = polygon.label;
      return { name, boundary };
    });
    const callBackObject = {
      action: 'drop',
      item: polygon,
      updatedPolygons: convertedArray,
    };
    props.onShapeChangeCallback(callBackObject);
  };

  const dispatchUpdateCallback = (polygon, updatedPolygons) => {
    const convertedArray = updatedPolygons.map((polygon) => {
      const boundary = polygon.coordinates.map((coord) => {
        return {
          latitude: coord.lat,
          longitude: coord.lng,
        };
      });
      const name = polygon.label;
      return { name, boundary };
    });
    const callBackObject = {
      action: 'update',
      item: polygon,
      updatedPolygons: convertedArray,
    };
    props.onShapeChangeCallback(callBackObject);
  };

  const getMaxLabel = (initialAdd) => {
    if (initialAdd) {
      return mapDrawShapeManagerRef.current.length - 1;
    } else {
      let max = 0;
      state.polygons.forEach((polygon) => {
        const match = polygon.label.match(/\((\d+)\)/);
        const regionNumber = match ? parseInt(match[1], 10) : 0;
        if (regionNumber > max) {
          max = regionNumber;
        }
      });
      return max;
    }
  };

  const changeMapType = () => {
    setSatellite(!satellite);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)}
          style={{
            cursor: 'pointer',
            padding: '5px',
            borderBottom: '1px solid #222',
            fontFamily: 'Roboto',
          }}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const handleSelect =
    ({ description }) =>
    () => {
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        props.handleLocationChange(description);
        const { lat, lng } = getLatLng(results[0]);
        setDefaultProps({
          ...defaultProps,
          center: {
            lat,
            lng,
          },
          zoom: 20,
        });
      });
    };

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const ref = useOnclickOutside(() => {});

  function convertLatLongToLatitudeLongitude() {
    const convertedArray = state.polygons.map((polygon) => {
      const boundary = polygon.coordinates.map((coord) => {
        return {
          latitude: coord.lat,
          longitude: coord.lng,
        };
      });
      const name = polygon.label;
      return { name, boundary };
    });
    return convertedArray;
  }

  const handleDone = () => {
    const coordinatesData = convertLatLongToLatitudeLongitude();
    console.log(coordinatesData);
    console.log(JSON.stringify(coordinatesData));
    window?.ReactNativeWebView?.postMessage(JSON.stringify(coordinatesData));
  };

  const updatePolygonLabel = (label, polygon) => {
    addPolygonOverlay(polygon, label, polygon.labelColor, loadedMap);
    polygon.label = label;
    setState((prevState) => {
      const index = prevState.polygons.findIndex(
        (poly) => poly.id === polygon.id
      );
      prevState.polygons.splice(index, 1, polygon);
      if (props.onShapeChangeCallback) {
        dispatchUpdateCallback(polygon, prevState.polygons);
      }
      return {
        ...prevState,
      };
    });
  };

  return (
    <Grid xs={12} container>
      <div
        style={{
          height: props.miniVariant ? '55vh' : '100vh',
          width: '100%',
          position: 'relative',
          // zoom: "105%",
        }}
      >
        <div
          ref={ref}
          style={{
            position: 'absolute',
            top: '2%',
            left: '5%',
            zIndex: 1,
            width: '80%',
          }}
        >
          <TextField
            inputProps={{ style: { fontFamily: 'Roboto' } }}
            variant="outlined"
            size="small"
            fullWidth
            value={value}
            onChange={handleInput}
            disabled={props.activeProject && props.activeProject?.activeWorkers}
            placeholder="Enter location"
            style={{
              width: '100%',
              // padding: '10px 14px',
              // background: 'none',
              borderRadius: '4px',
              // border: 'none',
              backgroundColor: '#fff',
              // borderColor: '',
              fontFamily: 'Roboto',
              // outline: 'none',
            }}
          />
          <p
            style={{
              margin: '4px 14px 0px',
              color: 'red',
              fontSize: '12px',
              fontWeight: '0',
            }}
          >
            {props.showErrors && 'Location is required'}
          </p>
          {status === 'OK' && (
            <ul
              style={{
                listStyleType: 'none',
                position: 'absolute',
                top: '45px',
                fontFamily: 'Roboto',
                backgroundColor: '#fff',
                zIndex: '1',
                width: '100%',
              }}
            >
              {(!props.activeProject || !props?.activeProject?.activeWorkers) &&
                renderSuggestions()}
            </ul>
          )}
        </div>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyAp1MrpbYeGqv7hwCFDbPOd7rlrxTazAFQ',
          }}
          zoom={18}
          center={!state.drawingMode && defaultProps.center}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.defaultZoom}
          yesIWantToUseGoogleMapApiInternals
          mapTypeControl
          // onChildClick={ ( key ) => console.log( key, "haha" ) }
          onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
          // style={{ position: 'relative' }}
          options={() => ({
            mapTypeId: satellite ? 'hybrid' : 'roadmap',
            gestureHandling: 'greedy',
            zoomControlOptions: {
              position: 7,
            },
            streetViewControl: true,
            fullscreenControl: true,
          })}
        />
        {state.mapLoaded && (
          <Grid
            container
            justify="center"
            className={`${classes.controlContainer}`}
            style={{ width: props.miniVariant ? '100%' : '80%' }}
          >
            <Grid
              item
              container
              justify="space-between"
              className="center"
              md={props.miniVariant ? 7 : 12}
              // style={{ border: "1px solid red" }}
            >
              <Grid item md={3} xs={12}>
                <Button
                  fullWidth
                  startIcon={<LayersIcon />}
                  onClick={changeMapType}
                  variant="contained"
                  size="small"
                  style={{ marginBottom: 10, fontSize: 11 }}
                >
                  {!satellite ? 'Satlite View' : 'Roadmap View'}
                </Button>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  style={{ marginBottom: 10, fontSize: 11 }}
                  fullWidth
                  size="small"
                  variant="contained"
                  // className='btn-control'
                  onClick={handleDrawingIfMapIsLoaded}
                >
                  {getButtonTextForAddingPolygon()}
                </Button>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  disabled={state.polygons.length === 0}
                  onClick={removeLastShape}
                  style={{ marginBottom: 10, fontSize: 11 }}
                >
                  Clear Last Region
                </Button>
              </Grid>
              {props.displayDoneAction && (
                <Grid item md={2} xs={12}>
                  <Button
                    // style={{ marginBottom: 10 }}
                    fullWidth
                    size="small"
                    variant="contained"
                    // className='btn-control'

                    onClick={handleDone}
                    style={{ marginBottom: 10, fontSize: 11 }}
                  >
                    Done
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {state.polygons.map((polygon) => (
          <RenderLabelField
            key={polygon.id}
            polygon={polygon}
            updatePolygonLabel={updatePolygonLabel}
            removePolygon={handleRemoveAction}
          />
        ))}
      </div>
    </Grid>
  );
};

export default MapView;

MapView.defaultProps = {
  mapBootstrap: {
    libraries: ['drawing'],
  },
  activeProject: null,
  displayDoneAction: true,
  miniVariant: false,
  showErrors: false,
  handleLocationChange: () => {},
  mapOptions: {
    mapTypeId: 'roadmap',
    minZoom: 5,
    maxZoom: 20,
    gestureHandling: 'greedy',
    disableDefaultUI: true,
    scrollwheel: true,
    clickableIcons: false,
    rotateControl: false,
    tilt: 0,
  },
  defaultZoom: 7,
  initialPointInnerHtml:
    '<button class="btn-initial-point" title="Initial Point"></button>',
  deletePointInnerHtml:
    '<button class="btn-delete-point" title="Delete">X</button></div>',
};
