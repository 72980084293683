import React, { Suspense, useEffect, useRef, useState } from 'react';
import Page from 'src/components/Page';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  CardHeader,
  Button,
  Divider,
  Tooltip,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryPie,
  VictoryStack,
  VictoryTheme,
} from 'victory';
import {
  countReducer,
  getAttendanceMetrics,
  getAttendanceTrendline,
  getContractorsStats,
  getCountsData,
  getFieldNoteMetrics,
  getFinancialProgressMetrics,
  getLabourExpenseMetrics,
  getLabourTurnoverMetrics,
  getMandays,
  getPayments,
  getWorkeforce,
  getWorkerSkills,
  getWorkforceMetrics,
} from 'src/redux/count';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import { getSkills } from 'src/redux/jobs';
import defaultProjectImage from '../../assests/Projects Icon.png';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { VictoryTooltip } from 'victory-tooltip';
import AddIcon from '@material-ui/icons/Add';

import { Bar } from 'react-chartjs-2';

// import Carousel from "react-material-ui-carousel";
import {
  downloadFIeldNoteReport,
  fieldNoteReducer,
  getFieldNoteList,
  markFieldNoteAction,
} from 'src/redux/fieldNote';
import toast, { Toaster } from 'react-hot-toast';

import DownloadButtonFieldNote from '../../assests/downloadButtonFieldNote.svg';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import TimerIcon from '@material-ui/icons/Timer';
import RestoreIcon from '@material-ui/icons/Restore';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import {
  getBOQProgress,
  getFinancialProgressData,
  getProjectBudget,
  productivityReducer,
} from 'src/redux/productivity';
import EventNoteIcon from '@material-ui/icons/EventNote';
// import BOQProgressGreenContainer from 'src/components/productivity/BOQProgressGreenContainer';

import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { getContractors } from 'src/redux/users';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// import Carousal from './component/Carousal';
const Carousal = React.lazy(() => import('./component/Carousal'));
const BOQProgressGreenContainer = React.lazy(() =>
  import('src/components/productivity/BOQProgressGreenContainer')
);
// import FilterProjectComponent from 'src/components/FilterProjectComponent';
const FilterProjectComponent = React.lazy(() =>
  import('src/components/FilterProjectComponent')
);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    borderRadius: '3px',

    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    borderRadius: '10px',

    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    textTransform: 'capitalize',
    borderRadius: '3px',

    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  cardPaddingRight1: {
    paddingRight: '1.5rem',
  },
  padRight: {
    paddingRight: '0.75rem',
  },
  padLeft: {
    paddingLeft: '0.75rem',
  },
  marginTop: {
    marginTop: '0px',
  },
  width: {
    width: '100%',
  },

  caroselHeight: {
    height: '38em',
  },
  cardMarginRight: {
    marginRight: '2rem',
  },
  cardHeight: {
    height: '450px',
    marginBottom: '0px',
  },
  paddingRight: {
    paddingRight: '1.5rem',
  },
  customWidth: {
    maxWidth: 80,
  },
  robotoFont: {
    fontFamily: 'Roboto',
  },
  [theme.breakpoints.down('sm')]: {
    cardPaddingRight: {
      paddingRight: '0',
    },

    padRight: {
      paddingRight: '0',
    },
    padLeft: {
      paddingLeft: '0',
    },
    marginTop: {
      marginTop: '1.5rem',
    },
    width: {
      width: '100%',
    },
    caroselHeight: {
      height: '30em',
    },
    cardMarginRight: {
      marginRight: '0px',
    },
    cardHeight: {
      height: '40em',
      marginBottom: '1.5rem',
    },
    paddingRight: {
      paddingRight: '0',
    },
    cardPaddingRight1: {
      paddingRight: '0',
    },
  },
}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Dashboard = React.memo(() => {
  const [classifiedProject, setClassifiedProject] = useState('');
  const [pieSelectedDate, setPieSelectedDate] = useState(new Date());
  const [currentProjectContractor, setCurrentProjectContractor] = useState('');
  const [currentProjectSkillset, setCurrentProjectSkillset] = useState('');

  //Attendance Graph
  const [attendanceSelectedDate, setAttendanceSelectedDate] = useState(null);
  const [attendanceCurrentWeek, setAttendanceCurrentWeek] = useState(null);
  const [currentProject, setCurrentProject] = useState('');

  //Payments Graph
  const [currentProjectPayments, setCurrentProjectPayments] = useState('');
  const [paymentsSelectedDate, setPaymentsSelectedDate] = useState(null);
  const [paymentsCurrentWeek, setPaymentsCurrentWeek] = useState(null);

  //Carousel
  // const [activeIndex, setActiveIndex] = useState(0);
  const [items, setItems] = useState([]);
  const emptyItems = [null, null];
  const [currentProjectCarousel, setCurrentProjectCarousel] = useState('');

  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [filterCarosuel, setFilterCarosuel] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterName, setFilterName] = React.useState('Filter');
  const [filteredFiledNote, setFilteredFiledNote] = React.useState(null);

  const [currentProjectFinancial, setCurrentProjectFinancial] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);

  const [startDate, endDate] = dateRange;
  const [currentProjectBudget, setCurrentProjectBudget] = useState('');

  const [LabourContractor, setLabourContractor] = useState('');
  const [currentProjectLabourTurnover, setCurrentProjectLabourTurnover] =
    useState('');
  const [labourTurnoverDate, setLabourTurnoverDate] = useState('');
  const [carousalLoading, setCarousalLoading] = useState(false);

  const [chartWidth, setChartWidth] = useState(600);
  const [wideChartWidth, setWideChartWidth] = useState(900);
  const chartContainerRef = useRef(null);
  const wideChartContainerRef = useRef(null);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (chartContainerRef.current) {
  //       setChartWidth(chartContainerRef.current.offsetWidth);
  //     }
  //     if (wideChartContainerRef.current) {
  //       setWideChartWidth(wideChartContainerRef.current.offsetWidth);
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);
  //   handleResize();

  //   return () => window.removeEventListener('resize', handleResize);
  // }, [chartWidth, wideChartWidth]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleItemClick = (index) => {
    setCurrentIndex(index);
  };
  const handleLabourContractor = async (newValue) => {
    setLabourContractor(newValue);

    if (!newValue) {
      dispatch(getFieldNoteMetrics(currentProjectCarousel?.projectId));

      let resp = await dispatch(
        getFieldNoteList(currentProjectCarousel?.projectId)
      );
      if (resp?.status === 200) {
        setItems(resp?.data);
      }

      return;
    }

    dispatch(
      getFieldNoteMetrics(currentProjectCarousel?.projectId, newValue?.userId)
    );
    let resp = await dispatch(getFieldNoteList(0, newValue?.userId));
    if (resp?.status === 200) {
      setItems(resp?.data);
    }
  };
  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let currentUserRole = JSON.parse(localStorage.getItem('userDetails')).user
    .userTypeId;
  let leadTypeId = JSON.parse(localStorage.getItem('userDetails')).user
    .leadTypeId;
  //! SELECTORS
  const { projectClassificationList } = useSelector(projectReducer);
  const { fieldNoteList } = useSelector(fieldNoteReducer);
  const {
    financialGraphData,
    projectBudgetLoading,

    projectBudgetData,
  } = useSelector(productivityReducer);
  const {
    loading,
    counts,
    // workforce,
    // mandays,
    workerSkill,
    workerSkillLoading,
    attendanceTrendlineLoading,
    attendanceTrendline,
    contractorsStats,
    paymentsLoading,
    payments,
    contractorsStatsLoading,
    attendanceMetrics,
    workforceMetrics,
    fieldNoteMetrics,
    fieldNoteMetricsLoading,
    labourExpenseMetrics,
    labourExpenseMetricsLoading,
    financialProgressMetrics,
    labourTurnoverMetrics,
  } = useSelector(countReducer);
  const [zoomDomain, setZoomDomain] = useState();
  const chartRef = useRef(null);

  const classifyProjectBOQGreenContainer = (project) => {
    if (project) {
      setCurrentProject(project);
      setCurrentProjectPayments(project);
      setCurrentProjectContractor(project);
      setCurrentProjectCarousel(project);
      setCurrentProjectFinancial(project);
      setCurrentProjectBudget(project);
      setCurrentProjectSkillset(project);
      setCurrentProjectLabourTurnover(project);
      dispatch(getLabourTurnoverMetrics(project?.projectId));
      dispatch(getContractors(project?.projectId));

      dispatch(getBOQProgress(project?.projectId));
      dispatch(getBOQProgress(project?.projectId));
      dispatch(getFieldNoteList(project?.projectId));
      dispatch(getBOQProgress(project?.projectId));

      //! METRICS
      dispatch(getLabourExpenseMetrics(project?.projectId));
      dispatch(getFieldNoteMetrics(project?.projectId));
      dispatch(getFinancialProgressMetrics(project?.projectId));
      dispatch(getLabourTurnoverMetrics(project?.projectId));
      dispatch(getWorkforceMetrics(project?.projectId));
      dispatch(getAttendanceMetrics(project?.projectId));

      dispatch(
        getWorkerSkills(
          '2022-08-31T19:00:00Z',
          moment().utc().format(),
          project.projectId
        )
      );
      dispatch(
        getAttendanceTrendline(
          project.projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
      dispatch(
        getContractorsStats(project.projectId, moment().format('YYYY-MM-DD'))
      );
      dispatch(
        getPayments(
          project.projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
    } else {
      dispatch(getContractors(projectClassificationList[0]?.projectId));

      dispatch(
        getLabourTurnoverMetrics(projectClassificationList[0]?.projectId)
      );
      setCurrentProjectLabourTurnover(projectClassificationList[0]);

      setCurrentProject(projectClassificationList[0]);
      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));
      dispatch(getFieldNoteList(projectClassificationList[0]?.projectId));
      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));

      //! METRICS
      dispatch(
        getLabourExpenseMetrics(projectClassificationList[0]?.projectId)
      );
      dispatch(getFieldNoteMetrics(projectClassificationList[0]?.projectId));
      dispatch(
        getFinancialProgressMetrics(projectClassificationList[0][0]?.projectId)
      );
      dispatch(
        getLabourTurnoverMetrics(projectClassificationList[0]?.projectId)
      );
      dispatch(getWorkforceMetrics(projectClassificationList[0]?.projectId));
      dispatch(getAttendanceMetrics(projectClassificationList[0]?.projectId));

      dispatch(
        getWorkerSkills(
          '2022-08-31T19:00:00Z',
          moment().utc().format(),
          projectClassificationList[0].projectId
        )
      );
      dispatch(
        getAttendanceTrendline(
          projectClassificationList[0].projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
      dispatch(
        getContractorsStats(
          projectClassificationList[0].projectId,
          moment().format('YYYY-MM-DD')
        )
      );
      dispatch(
        getPayments(
          projectClassificationList[0].projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );

      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));
      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));
      dispatch(getFieldNoteList(projectClassificationList[0]?.projectId));
      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));
    }
  };

  const labels = workerSkill?.map(
    (item) =>
      `${item.skillCode} ${item.data.reduce(
        (total, num) => total + num.count,
        0
      )}`
  );

  console.log('projectClassificationList', projectClassificationList);
  const options = {
    tooltips: {
      callbacks: {
        // Customize tooltip label for each dataset
        label: function (tooltipItem, data) {
          const datasetLabel =
            data.datasets[tooltipItem.datasetIndex].label || '';
          const value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

          return `${
            workerSkill[tooltipItem.index]?.skill
          } - ${datasetLabel}: ${value}`;
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Skilled by workers',
        color: 'rgba(112, 112, 112, 1)',
      },
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false, // Set to false to define custom width and height
    // width: 500, // Set the desired width
    // height: 300,
    scales: {
      x: {
        stacked: true,
        // grid: { display: false },
      },
      y: {
        stacked: true,
        // grid: { display: false },
      },
    },
    indexAxis: 'y',
    elements: {
      bar: {
        borderRadius: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    },
  };

  function transformData(data = workerSkill) {
    if (!data) return [];
    const skills = [
      { skill: 'G.Labour', skillCode: 'GNL' },
      { skill: 'Barbender', skillCode: 'BBD' },
      { skill: 'Mason', skillCode: 'MSN' },
      { skill: 'Carpenter', skillCode: 'CPT' },
      { skill: 'Stager', skillCode: 'STG' },
      { skill: 'Scaffolding', skillCode: 'SCF' },
      { skill: 'Machine', skillCode: 'MOP' },
      { skill: 'Gypsum W..', skillCode: 'GYP' },
      { skill: 'Painter', skillCode: 'PTR' },
      { skill: 'Electrician', skillCode: 'ELC' },
    ];

    const defaultSkillData = {
      helper: 0,
      skilled: 0,
      supervisor: 0,
    };

    const transformed = skills.map((skill) => {
      const apiData = data.find((d) => d.skillCode === skill.skillCode) || {
        data: [],
      };

      const skillData = {
        helper:
          apiData.data.find((skillData) => skillData.skillType === 'Helper')
            ?.count || 0,
        skilled:
          apiData.data.find((skillData) => skillData.skillType === 'Skilled')
            ?.count || 0,
        supervisor:
          apiData.data.find((skillData) => skillData.skillType === 'Supervisor')
            ?.count || 0,
      };

      return {
        x: skill.skillCode,
        ...defaultSkillData,
        ...skillData,
      };
    });

    return transformed;
  }

  const getTotalCounts = (arr = workerSkill) => {
    let totalCounts = { Skilled: 0, Supervisor: 0, Helper: 0 };
    arr.forEach((item) => {
      item.data.forEach((skillData) => {
        if (skillData.skillType in totalCounts) {
          totalCounts[skillData.skillType] += skillData.count;
        }
      });
    });
    console.log('totalCounts', totalCounts);
    return totalCounts;
  };
  console.log('workerSkill', workerSkill);

  const markFieldNote = async (id, value) => {
    let resp = await dispatch(markFieldNoteAction(id, value));
    if (resp?.status === 200) {
      dispatch(getFieldNoteList());
      toast.success('Field Note Mark Successfully!', {
        position: 'top-right',
      });
      handleClose();
    } else {
      toast.error('Field Note Marked unsuccessful!', {
        position: 'top-right',
      });
    }
  };

  const markTheFieldNoteFromServer = async (id, action) => {
    let formData = new FormData();
    //formData.append('VerificationImage', image);
    formData.append('Action', action);
    let resp = await dispatch(markFieldNoteAction(id, formData));
    if (resp?.status === 200) {
      //dispatch(getFieldNoteList());
      toast.success('Field Note Mark Successfully!', {
        position: 'top-right',
      });
      setTimeout(() => {
        window.location.reload();
      }, 400);
    } else {
      toast.error('Something went wrong! Please try again later', {
        position: 'top-right',
      });
    }
    //setOpenFileUploadModal(false);
  };

  const printStatus = (action) => {
    if (action == 'MarkAsResolved') {
      return 'Resolved';
    } else if (action == 'ReworkRequired') {
      return 'Rework';
    } else {
      return 'Pending';
    }
  };

  const aggregatedData = {};
  function getRandomColor(monthArray) {
    // const letters = "0123456789ABCDEF";
    // let color = "#";
    // for (let i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;
    let data = monthArray.map((item, index) => {
      return financialGraphData[index].costbySOW.colorCode;
    });
  }
  let data1 = {};

  // Function to extract unique color codes for each label
  function extractColors(data) {
    data = data || [];
    const colorMap = {};
    data.forEach((item) => {
      const costBySOWArray = Array.isArray(item.costbySOW)
        ? item.costbySOW
        : [item.costbySOW];
      costBySOWArray.forEach((cost) => {
        if (!colorMap[cost.label]) {
          colorMap[cost.label] = cost.colorCode;
        }
      });
    });
    return colorMap;
  }

  function transformCostData(data) {
    data = data || [];
    function getLargestCostByLabel(costBySOW) {
      const costMap = {};
      costBySOW.forEach((cost) => {
        if (!costMap[cost.label] || costMap[cost.label] < cost.actualCost) {
          costMap[cost.label] = cost.actualCost;
        }
      });
      return costMap;
    }
    const transformed = data.map((item) => {
      const costBySOWArray = Array.isArray(item.costbySOW)
        ? item.costbySOW
        : [item.costbySOW];
      const largestCostByLabel = getLargestCostByLabel(costBySOWArray);
      return { x: item.month, ...largestCostByLabel };
    });
    const topmostBars = transformed.map((monthData) => {
      let topMostLabel = '';
      let maxY = 0;
      for (const [label, value] of Object.entries(monthData)) {
        if (label !== 'x' && value > maxY) {
          maxY = value;
          topMostLabel = label;
        }
      }
      return { ...monthData, isTopmostLabel: topMostLabel };
    });
    return topmostBars;
  }

  // Ensure that financialGraphData is defined
  if (financialGraphData?.length > 0) {
    financialGraphData.forEach((item) => {
      const month = item.month;
      const costData = item.costbySOW;

      if (!aggregatedData[month]) {
        aggregatedData[month] = {};
      }

      if (Array.isArray(costData)) {
        costData.forEach((costItem) => {
          const label = costItem.label;
          const actualCost = costItem.actualCost;

          if (!aggregatedData[month][label]) {
            aggregatedData[month][label] = 0;
          }

          aggregatedData[month][label] += actualCost;
        });
      } else if (costData) {
        const label = costData.label;
        const actualCost = costData.actualCost;

        if (!aggregatedData[month][label]) {
          aggregatedData[month][label] = 0;
        }

        aggregatedData[month][label] += actualCost;
      }
    });

    const months = financialGraphData.map((item) => item.month); // Use the months from financialGraphData

    const labelSet = new Set();

    // Collect all unique labels across all months
    months.forEach((month) => {
      const labels = Object.keys(aggregatedData[month]);
      labels.forEach((label) => labelSet.add(label));
    });

    const datasets = Array.from(labelSet).map((label) => {
      return {
        label,
        data: months.map((month) => aggregatedData[month][label] || 0),
        backgroundColor: financialGraphData
          .filter(
            (item) =>
              Array.isArray(item.costbySOW) &&
              item.costbySOW.some((cost) => cost.label === label)
          )
          .map((item) => {
            const costItem = item.costbySOW.find(
              (cost) => cost.label === label
            );
            return costItem ? `#${costItem.colorCode}` : 'red'; // fallback to red if colorCode not found
          }),

        stack: '1',
      };
    });

    // Define your chart data and options
    data1 = {
      labels: months,
      datasets,
    };
  } else {
    // Handle the case where financialGraphData is empty
    // You might want to display an error message or take other appropriate actions.
  }

  const options1 = {
    plugins: {
      title: {
        display: true,
        text: 'Skilled by workers',
      },
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    // width: 400, // Set the desired width
    // height: 289,

    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
      yAxes: [
        {
          ticks: {
            callback: (label, index, labels) => {
              return '₹ ' + label;
            },
          },
        },
      ],
    },
    indexAxis: 'y',
  };

  //! LIFE-CYCLE-METHODS --------------------------------
  const fetchInitialData = () => {
    dispatch(getCountsData());
    dispatch(getMandays(0));
    dispatch(getProjectsClassificationList());
    dispatch(getSkills());
  };

  const fetchProjectRelatedData = (projectId) => {
    dispatch(getContractors(projectId));
    dispatch(getLabourTurnoverMetrics(projectId));
    dispatch(getLabourExpenseMetrics(projectId));
    dispatch(getFinancialProgressMetrics(projectId));
    dispatch(getFieldNoteMetrics(projectId));
    dispatch(getFieldNoteList(projectId));
    dispatch(getBOQProgress(projectId));
    dispatch(
      getWorkerSkills(
        '2022-08-31T19:00:00Z',
        moment().utc().format(),
        projectId
      )
    );
    dispatch(
      getAttendanceTrendline(
        projectId,
        moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      )
    );
    dispatch(getContractorsStats(projectId, moment().format('YYYY-MM-DD')));
    dispatch(
      getPayments(
        projectId,
        moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      )
    );
    dispatch(getAttendanceMetrics(projectId));
    dispatch(getWorkforceMetrics(projectId));
    dispatch(getFinancialProgressData(projectId));
  };

  const handleProjectSetup = (project) => {
    setCurrentProjectLabourTurnover(project);
    setCurrentProjectCarousel(project);
    setCurrentProject(project);
    setCurrentProjectContractor(project);
    setCurrentProjectSkillset(project);
    setCurrentProjectPayments(project);
    setCurrentProjectFinancial(project);
    classifyProjectBudget(project);
  };

  const handleRoleSpecificSetup = (projectId, project) => {
    if (currentUserRole !== 'SuperAdmin') {
      dispatch(getWorkeforce(projectId));
    } else {
      dispatch(getWorkeforce(0));
    }
    handleProjectSetup(project);
  };
  console.log('counts', counts);
  useEffect(() => {
    if (!counts) {
      fetchInitialData();
    }

    if (projectClassificationList.length > 0) {
      const currentProject = projectClassificationList[0];
      const projectId = currentProject?.projectId;

      fetchProjectRelatedData(projectId);
      handleRoleSpecificSetup(projectId, currentProject);
    }
  }, [projectClassificationList.length]);

  useEffect(() => {
    if (fieldNoteList?.length === 0) {
      setItems(null);
    }
  }, [fieldNoteList?.length, items?.length]);

  const classifyProjectFinancial = (project) => {
    if (project) {
      setCurrentProjectFinancial(project);
      dispatch(getFinancialProgressData(project?.projectId));
    } else {
      setCurrentProjectFinancial(projectClassificationList[0]);
      dispatch(
        getFinancialProgressData(projectClassificationList[0]?.projectId)
      );
    }
  };
  const handleDateRange = (update) => {
    let [start, end] = update;
    setDateRange(update);
  };
  const handleDateRangeLabourTurnover = (date) => {
    if (!date) {
      dispatch(
        getLabourTurnoverMetrics(currentProjectLabourTurnover?.projectId)
      );
      setLabourTurnoverDate('');
    } else {
      dispatch(
        getLabourTurnoverMetrics(
          currentProjectLabourTurnover?.projectId,
          moment(date).format('YYYY-MM-DD HH:mm:ss')
        )
      );
      setLabourTurnoverDate(date);
    }
  };
  let layout = 'app';
  let paymentYAxisLimit =
    payments?.length > 0 && payments?.map((item) => item.DueAmount);

  const workersCardObj = [
    {
      id: 3,
      name: 'Total Projects',
      workers: counts?.totalProjects,
      // img: "/static/images/bettamintImages/vector2.png",
      img: '/static/images/bettamintImages/dashboard1.png',

      percent: '+12%',
      link: `/${layout}/project`,
    },
    {
      id: 2,
      name:
        leadTypeId === 'LabourContractor'
          ? 'Active Worker'
          : 'Total Contractors',
      workers:
        leadTypeId === 'LabourContractor'
          ? counts?.activeWorkers
          : counts?.totalContractor,
      img: '/static/images/bettamintImages/active-workers.png',
      percent: '-5%',
      // link: `/${layout}/users`,
    },
    {
      id: 4,
      name: 'Worker Present',
      workers: counts?.workerPresent,
      // img: '/static/images/bettamintImages/vector2.png',
      img: '/static/images/bettamintImages/worker-present.png',

      link: `/${layout}/attendance`,

      percent: '+10%',
    },
    {
      id: 1,
      name: 'Worker Absent',
      workers: counts?.workerAbsent,
      // img: '/static/images/bettamintImages/dashboard1.png',
      img: '/static/images/bettamintImages/worker-absent.png',

      percent: '+10%',
      link: `/${layout}/attendance`,
    },
  ];

  const getBarData = () => {
    return attendanceTrendline?.map((item) => ({
      x: item.Day,
      Absent: item.Absent ?? 0,
      Present: item.Present ?? 0,
    }));
  };

  let currentPresent = attendanceTrendline?.filter((item) => {
    if (moment().format('ddd').toUpperCase() === item.Day) {
      return item;
    }
  });
  console.log('attendanceTrendline', attendanceTrendline);
  let currentAbsent = attendanceTrendline?.filter((item) => {
    if (moment().format('ddd').toUpperCase() === item.Day) {
      return item;
    }
  });

  const classifyProject = (project) => {
    if (project) {
      dispatch(getAttendanceMetrics(project?.projectId));

      dispatch(
        getAttendanceTrendline(
          project.projectId,
          attendanceCurrentWeek?.startOfWeek
            ? attendanceCurrentWeek.startOfWeek.format('YYYY-MM-DD HH:mm:ss')
            : moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          attendanceCurrentWeek?.endOfWeek
            ? attendanceCurrentWeek?.endOfWeek?.format('YYYY-MM-DD HH:mm:ss')
            : moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
      setCurrentProject(project);
      const { projectId } = project;
      setClassifiedProject(
        projectClassificationList.filter(
          (project) => project.projectId === projectId && project
        )
      );
      // dispatch(getWorkeforce(projectId));
      // dispatch(getMandays(projectId));
    } else {
      dispatch(getAttendanceMetrics(projectClassificationList[0]?.projectId));

      setCurrentProject(projectClassificationList[0]);
      if (currentUserRole !== 'SuperAdmin') {
        setClassifiedProject(projectClassificationList[0]);
        dispatch(getWorkeforce(projectClassificationList[0]?.projectId));
        dispatch(getMandays(projectClassificationList[0]?.projectId));
      } else {
        setClassifiedProject('');
        dispatch(getWorkeforce(0));
        dispatch(getMandays(0));
      }
      dispatch(
        getAttendanceTrendline(
          projectClassificationList[0]?.projectId,
          attendanceCurrentWeek?.startOfWeek
            ? attendanceCurrentWeek?.startOfWeek?.format('YYYY-MM-DD HH:mm:ss')
            : moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          attendanceCurrentWeek?.endOfWeek
            ? attendanceCurrentWeek?.endOfWeek?.format('YYYY-MM-DD HH:mm:ss')
            : moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
    }
  };

  const classifyProjectContractor = (project) => {
    if (project) {
      dispatch(
        getContractorsStats(
          project.projectId,
          moment(pieSelectedDate).format('YYYY-MM-DD')
          // moment().endOf("week").format("YYYY-MM-DD HH:mm:ss")
        )
      );
      setCurrentProjectContractor(project);
    } else {
      setCurrentProjectContractor(projectClassificationList[0]);

      dispatch(
        getContractorsStats(
          projectClassificationList[0].projectId,
          moment(pieSelectedDate).format('YYYY-MM-DD')
        )
      );
    }
  };

  const classifyProjectSkillset = (project) => {
    if (project) {
      dispatch(getWorkforceMetrics(project?.projectId));

      dispatch(
        getWorkerSkills(
          '2022-08-31T19:00:00Z',
          moment().utc().format(),
          project.projectId
        )
      );
      setCurrentProjectSkillset(project);
    } else {
      dispatch(getWorkforceMetrics(projectClassificationList[0]?.projectId));

      setCurrentProjectSkillset(projectClassificationList[0]);
      dispatch(
        getWorkerSkills(
          '2022-08-31T19:00:00Z',
          moment().utc().format(),
          projectClassificationList[0].projectId
        )
      );
    }
  };
  const classifyProjectPayments = (project) => {
    if (project) {
      dispatch(getLabourExpenseMetrics(project?.projectId));

      dispatch(
        getPayments(
          project.projectId,
          paymentsCurrentWeek?.startOfWeek
            ? paymentsCurrentWeek.startOfWeek.format('YYYY-MM-DD HH:mm:ss')
            : moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          paymentsCurrentWeek?.endOfWeek
            ? paymentsCurrentWeek?.endOfWeek?.format('YYYY-MM-DD HH:mm:ss')
            : moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
      setCurrentProjectPayments(project);
    } else {
      dispatch(
        getLabourExpenseMetrics(projectClassificationList[0]?.projectId)
      );

      setCurrentProjectPayments(projectClassificationList[0]);
      dispatch(
        getPayments(
          projectClassificationList[0].projectId,
          paymentsCurrentWeek?.startOfWeek
            ? paymentsCurrentWeek.startOfWeek.format('YYYY-MM-DD HH:mm:ss')
            : moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          paymentsCurrentWeek?.endOfWeek
            ? paymentsCurrentWeek?.endOfWeek?.format('YYYY-MM-DD HH:mm:ss')
            : moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
    }
  };

  const onChangeDatePayments = (date) => {
    if (!date) {
      setPaymentsSelectedDate(null);
      setPaymentsCurrentWeek(null);
      return dispatch(
        getPayments(
          currentProjectPayments.projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
    }
    // Calculate the start and end of the week
    const startOfWeek = moment(date).startOf('week'); // Sunday
    const endOfWeek = moment(date).endOf('week'); // Saturday
    setPaymentsSelectedDate(date);
    setPaymentsCurrentWeek({
      startOfWeek,
      endOfWeek,
    });

    dispatch(
      getPayments(
        currentProjectPayments.projectId,
        startOfWeek.format('YYYY-MM-DD HH:mm:ss'),
        endOfWeek.format('YYYY-MM-DD HH:mm:ss')
      )
    );
  };
  const onChangePieDate = (date) => {
    setPieSelectedDate(date);
    dispatch(
      getContractorsStats(
        currentProjectContractor.projectId,
        // moment().startOf("year").format("YYYY-MM-DD HH:mm:ss"),
        moment(date).format('YYYY-MM-DD')
        // moment().endOf("year").format("YYYY-MM-DD HH:mm:ss")
      )
    );
  };
  const onChangeDateAttendance = (date) => {
    setAttendanceSelectedDate(date);
    // Calculate the start and end of the week
    var now = moment(date);
    var sunday = now.clone().weekday(0);
    var saturday = now.clone().weekday(6);
    //const startOfWeek = moment(date).startOf('week'); // Sunday
    //const endOfWeek = moment(date).endOf('week'); // Saturday
    //const formatedDate = moment(date);

    if (date) {
      dispatch(
        getAttendanceTrendline(
          currentProject?.projectId,
          sunday.format('YYYY-MM-DD HH:mm:ss'),
          saturday.format('YYYY-MM-DD HH:mm:ss')
        )
      );
      setAttendanceCurrentWeek({
        startOfWeek: date,
        endOfWeek: date,
      });
    } else {
      setAttendanceCurrentWeek(null);
      dispatch(
        getAttendanceTrendline(
          currentProject?.projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
    }
  };

  // const handleDownloadExcel = () => {
  //   downloadExcel({
  //     fileName: File,
  //     sheet: "Filed Notes Sheet",
  //   });
  // };
  function generateRandomHexColors(numColors) {
    const colors = [];

    for (let i = 0; i < numColors; i++) {
      const randomColor =
        '#' + Math.floor(Math.random() * 16777215).toString(16); // Generate a random 6-digit hex color
      colors.push(randomColor);
    }

    return colors;
  }

  const fetchItems = async (project = currentProject) => {
    let resp = await dispatch(getFieldNoteList(project?.projectId));
    if (resp?.status === 200) {
      setItems(resp?.data);
    }
  };

  const classifyProjectCarousel = async (project) => {
    setCarousalLoading(true);
    if (project) {
      setCurrentProjectCarousel(project);
      dispatch(getContractors(project?.projectId));
      dispatch(getFieldNoteMetrics(project?.projectId));
      await fetchItems(project);
    } else {
      setCurrentProjectCarousel(projectClassificationList[0]);
      dispatch(getFieldNoteMetrics(projectClassificationList[0]?.projectId));
      dispatch(getContractors(projectClassificationList[0]?.projectId));

      let resp = await dispatch(
        getFieldNoteList(projectClassificationList[0]?.projectId)
      );

      if (resp?.status === 200) {
        setItems(resp?.data);
      }
    }
    setCarousalLoading(false);
  };

  const classifyProjectLabourTurnover = async (project) => {
    if (project) {
      dispatch(getLabourTurnoverMetrics(project?.projectId));

      setCurrentProjectLabourTurnover(project);
    } else {
      setCurrentProjectLabourTurnover(projectClassificationList[0]);
      dispatch(
        getLabourTurnoverMetrics(projectClassificationList[0]?.projectId)
      );
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCarouselFilter = (filterBy, value) => {
    handleClose();
    if (filterBy === '') {
      setFilterName('Filter');
      setFilteredFiledNote(null);
    } else {
      setFilterName(filterBy);
      setFilteredFiledNote(
        fieldNoteList?.filter((item) => item.action === value)
      );
    }
  };
  function getMaxPresent(data) {
    if (data.length === 0) {
      return 0; // Return 0 if the array is empty
    }

    let maxPresent = data[0].present; // Initialize maxPresent with the first element's present value

    for (let i = 1; i < data.length; i++) {
      if (data[i].present > maxPresent) {
        maxPresent = data[i].present; // Update maxPresent if a larger value is found
      }
    }

    return maxPresent;
  }
  const handleFielNoteDownload = async () => {
    let resp = await dispatch(
      downloadFIeldNoteReport(currentProjectCarousel?.projectId)
    );
    const contentType = resp.headers['content-type'];

    if (resp.status === 200) {
      if (contentType === 'application/json; charset=utf-8') {
        return toast.error('No Data Found!');
      }
      if (resp.data.success === false) return toast.error('No Data Found!');

      toast.success('Excel file downloading');
      const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileURL;
      // Set the download attribute if you want to specify a file name
      link.setAttribute(
        'download',
        `Field Note_ProjectId_${
          currentProjectCarousel?.projectId
        } ${moment().format('MMM - YYYY')}.xlsx`
      );

      // Append the link to the DOM
      document.body.appendChild(link);

      // Trigger a click to initiate the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    } else if (resp.status === 500) {
      toast.error('No Data Found!');
    } else {
      toast.error('Something went wrong!');
    }
  };

  const CustomTooltip = ({ x, y, datum }) => (
    <g>
      <rect x={x} y={y} width="140" height="40" rx="5" ry="5" fill="black" />{' '}
      <circle cx={x + 10} cy={y + 20} r="5" fill={`#${datum.colorCode}`} />{' '}
      <text
        x={x + 20}
        y={y + 25}
        fill="white"
        fontSize="10px"
        fontWeight="bold"
      >
        {`${datum.y.toLocaleString()}`}
        {`\n ${datum.x} Finishes`}
      </text>
    </g>
  );

  const renderBars = (data) => {
    if (!data) return <></>;

    const barComponents = [];
    const colorMap = extractColors(financialGraphData);
    const uniqueLabels = Object.keys(colorMap);
    uniqueLabels.forEach((label) => {
      const filteredData = data.map((datum) => ({
        x: datum.x,
        y: datum[label] || 0,
        label: label,
        colorCode: colorMap[label],
        isTopmost: datum.isTopmostLabel === label,
      }));
      barComponents.push(
        <VictoryBar
          key={label}
          barWidth={16}
          data={filteredData}
          x="x"
          y="y"
          style={{
            data: {
              fill: ({ datum }) => `#${datum.colorCode}`,
              cornerRadius: ({ datum }) =>
                datum.isTopmost ? { top: 5 } : { top: 0 },
            },
          }}
          alignment="start"
          barRatio={0.8}
          labelComponent={
            <VictoryTooltip
              flyoutComponent={<CustomTooltip />}
              flyoutStyle={{ fill: 'transparent' }}
            />
          }
        />
      );
    });
    return barComponents;
  };

  const renderWorkersCard = () =>
    loading ? (
      <Grid
        container
        style={{
          height: '70px',
          marginBottom: '1em',
        }}
      >
        {[1, 1, 1, 1].map((item, index) => (
          <Grid
            item
            xs={3}
            key={index}
            style={{}}
            className={`${index === 0 ? classes.padRight : classes.padLeft}`}
          >
            <Skeleton count={1} height={70} borderRadius={10} />
          </Grid>
        ))}
      </Grid>
    ) : (
      // 'loading...'
      <Grid
        container
        item
        xs={12}
        // style={{ border: '2px solid red' }}
        justify="space-between"
        alignItems="center"
        className={classes.cardPaddingRight}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          className={classes.cardPaddingRight1}
          alignItems="center"
          justify="space-between"
          // style={{ border: "2px solid red" }}
        >
          {workersCardObj.map(
            (val, index) =>
              index <= 1 && (
                <Grid
                  container
                  item
                  md={6}
                  xs={12}
                  style={{
                    marginBottom: '1em',
                  }}
                  key={index + 1}
                  // justify='center'
                  alignItems="center"
                  className={`${
                    index === 0 ? classes.padRight : classes.padLeft
                  }`}
                >
                  <Card
                    elevation={6}
                    style={{
                      borderRadius: 10,
                      width: '100%',
                      cursor: 'pointer',
                      height: '70px',
                    }}
                    onClick={() =>
                      val?.link
                        ? navigate(val.link, {
                            replace: true,
                            // state: { from: location },
                          })
                        : null
                    }
                  >
                    <CardContent style={{ padding: '10px' }}>
                      <Grid
                        container
                        justify="space-between"
                        // alignItems="center"
                        item
                        xs={12}
                      >
                        <Grid item>
                          <Grid item xs>
                            <Typography
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: 12,
                                color: '#B2BEC7',
                              }}
                            >
                              {val.name}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            direction="row"
                            justify="space-between"
                            item
                            xs={12}
                          >
                            <Grid item xs={4}>
                              <Typography
                                style={{
                                  fontFamily: 'Roboto',
                                  fontSize: 20,
                                  fontWeight: '500',
                                }}
                              >
                                {loading ? (
                                  <CircularProgress
                                    style={{
                                      color: '#81B734',
                                      width: '25px',
                                      height: '25px',
                                    }}
                                  />
                                ) : (
                                  val.workers
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            style={
                              {
                                // background: '#A179F2',
                                // height: 50,
                                // width: 50,
                                // borderRadius: 10,
                              }
                            }
                          >
                            {val.name === 'Total Contractors' ||
                            val.name === 'Worker Present' ? (
                              <img
                                src={val.img}
                                alt="logo1"
                                // style={{ width: '30px' }}
                              />
                            ) : (
                              <img
                                src={val.img}
                                alt="logo1"
                                // style={{ width: '30px' }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          // className={classes.cardPaddingRight}
          alignItems="center"
          justify="space-between"
        >
          {workersCardObj.map(
            (val, index) =>
              index > 1 && (
                <Grid
                  container
                  item
                  md={6}
                  xs={12}
                  style={{
                    marginBottom: '1em',
                  }}
                  // className={index === 0 && classes.cardPaddingRight}
                  key={index + 1}
                  // justify='center'
                  alignItems="center"
                  // justifyContent="space-between"
                  className={`${
                    index === 2 ? classes.padRight : classes.padLeft
                  }`}
                >
                  <Card
                    elevation={6}
                    style={{
                      borderRadius: 10,
                      width: '100%',
                      cursor: 'pointer',
                      height: '70px',
                    }}
                    onClick={() =>
                      navigate(val.link, {
                        replace: true,
                        // state: { from: location },
                      })
                    }
                  >
                    <CardContent style={{ padding: '10px' }}>
                      <Grid
                        container
                        justify="space-between"
                        // alignItems="center"
                        item
                        xs={12}
                      >
                        <Grid item>
                          <Grid item xs>
                            <Typography
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: 12,
                                color: '#B2BEC7',
                              }}
                            >
                              {val.name}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            direction="row"
                            justify="space-between"
                            item
                            xs={12}
                          >
                            <Grid item xs={4}>
                              <Typography
                                style={{
                                  fontFamily: 'Roboto',
                                  fontSize: 20,
                                  fontWeight: '500',
                                }}
                              >
                                {loading ? (
                                  <CircularProgress
                                    style={{
                                      color: '#81B734',
                                      width: '25px',
                                      height: '25px',
                                    }}
                                  />
                                ) : (
                                  val.workers
                                )}
                              </Typography>
                              {/* <Typography
                                fullWidth
                                style={{
                                  fontFamily: "Roboto",
                                  fontSize: "9px",
                                  fontWeight: "400",
                                  color: "#B2BEC7",
                                }}
                              >
                                Daily Stats*
                              </Typography> */}
                            </Grid>
                            {/* <Grid item xs={1}>
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              color: val.percent === "-5%" ? "#FF0000" : "#81B734",
                              fontSize: 12,
                              fontWeight: "600",
                            }}
                          >
                            {val.percent}
                          </Typography>
                        </Grid> */}
                          </Grid>
                          {/* <Grid item xs>
                        <Typography
                          style={{
                            fontSize: 8,
                            fontFamily: "Roboto",
                            color: "#B2BEC7",
                          }}
                        >
                          Daily Status
                        </Typography>
                      </Grid> */}
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            justify="center"
                            alignItems="center"
                            style={{
                              background: '#A179F2',
                              // height: 50,
                              // width: 50,
                              borderRadius: 10,
                            }}
                          >
                            {val.name === 'Total Contractors' ||
                            val.name === 'Worker Present' ? (
                              <img
                                src={val.img}
                                alt="logo1"
                                // style={{ width: '30px' }}
                              />
                            ) : (
                              <img
                                src={val.img}
                                alt="logo1"
                                // style={{ width: '30px' }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )
          )}
        </Grid>
      </Grid>
    );

  const renderCarts = () => {
    const totalAbsentStudents = getBarData()?.reduce(
      (sum, day) => sum + day.Absent,
      0
    );
    const totalPresentStudents = getBarData()?.reduce(
      (sum, day) => sum + day.Present,
      0
    );
    const turnoverRate =
      totalPresentStudents > 0
        ? ((totalAbsentStudents / totalPresentStudents) * 100).toFixed(2)
        : 'N/A';

    return (
      <Grid
        container
        item
        xs={12}
        className={classes.cardPaddingRight}
        alignItems="center"
        justify="space-between"
        style={{ paddingTop: '10px' }}
      >
        <Grid item md={6} xs={12} className={classes.cardPaddingRight1}>
          <Grid style={{ paddingTop: '10px' }}>
            <Card
              elevation={6}
              style={{
                width: '100%',
                borderRadius: 10,
                height: '520px',
              }}
              className={classes.cardHeight}
            >
              <CardHeader
                title={
                  <Grid container alignItems="center" justify="space-between">
                    <Grid container alignItems="center" justify="space-between">
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: 20,
                            fontWeight: '500',
                            fontFamily: 'Roboto',
                            // color: "#D9D9D9",
                          }}
                        >
                          Attendance
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          size="small"
                          fullWidth
                          className={classes.buttonPurpleOpacity}
                          // size="small"
                          onClick={() =>
                            navigate('../attendance', {
                              replace: true,
                              // state: { from: location },
                            })
                          }
                        >
                          Attendance
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{ marginTop: '.6rem' }}
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item md={9} xs={12}>
                        <Suspense fallback={'Loading...'}>
                          <FilterProjectComponent
                            projectClassificationList={
                              projectClassificationList
                            }
                            src={
                              !classifiedProject[0]?.url
                                ? defaultProjectImage
                                : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                            }
                            value={currentProject}
                            onChange={classifyProject}
                          />
                        </Suspense>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <DatePicker
                          selected={attendanceSelectedDate}
                          onChange={onChangeDateAttendance}
                          // startDate={startDate}
                          // endDate={endDate}
                          // selectsRange
                          isClearable={true}
                          className="border-datepicker"
                          placeholderText="Date Filter"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
              <CardContent>
                {projectClassificationList?.length === 0 ? (
                  <Grid
                    // maxWidth="md"
                    style={{
                      height: '250px',
                    }}
                    container
                    justifyContent="center"
                  >
                    'No Data Found'
                  </Grid>
                ) : (
                  <Grid
                    // maxWidth="md"
                    style={{
                      height: '250px',
                    }}
                  >
                    {/* // IDHR HE */}
                    <VictoryChart
                      domainPadding={{ x: 15, y: 30 }}
                      theme={VictoryTheme.grayscale}
                      width={chartWidth}
                      padding={{ top: 15, bottom: 30, left: 10, right: 0 }}
                    >
                      <VictoryAxis
                        dependentAxis
                        style={{
                          axis: { stroke: 'transparent' },
                          ticks: { stroke: 'transparent' },
                          tickLabels: {
                            fill: 'rgba(112, 112, 112, 1)',
                            fontSize: 14,
                            fontFamily: 'Roboto',
                          }, // Change label color
                        }}
                      />
                      <VictoryAxis
                        tickLabelComponent={<VictoryLabel dx={20} />}
                        style={{
                          axis: { stroke: 'black' }, // Adjust x-axis color as needed
                          ticks: { stroke: 'black' },
                          tickLabels: {
                            fill: 'rgba(112, 112, 112, 1)',
                            fontSize: 14,
                            fontFamily: 'Roboto',
                          }, // Change label color
                        }}
                      />
                      <VictoryGroup
                        offset={20}
                        colorScale={['RGB(133, 186, 89)', '#9872EF', , 'grey']}
                      >
                        <VictoryBar
                          cornerRadius={{ top: 4, bottom: 0 }}
                          barWidth={16}
                          data={getBarData()}
                          x="x"
                          labels={({ datum }) =>
                            datum.Present?.toLocaleString()
                          }
                          // labelComponent={<VictoryLabel dy={50} />}
                          y="Present"
                          style={{ labels: { fill: 'RGB(133, 186, 89)' } }}
                          alignment="start"
                          barRatio={0.8}
                          labelComponent={
                            <VictoryTooltip
                              label={({ datum }) =>
                                `${datum.x}: ${datum.Present || datum.Absent}`
                              }
                              style={{
                                fill: 'white', // Set the background color to white
                              }}
                              flyoutStyle={{
                                padding: 10, // Adjust the padding as needed

                                // padding: "5px 1rem",
                                // color: "red",
                                fill: 'RGB(133, 186, 89)', // Background color of the tooltip box
                                stroke: 'RGB(133, 186, 89)', // Border color of the tooltip box
                                strokeWidth: 2, // Border width of the tooltip box
                                // You can further customize other flyout styles as needed
                              }}
                              textStyle={{
                                padding: 8, // Add padding to the text content
                                // You can also customize other text styles like font size, font family, etc. here
                              }}
                            />
                          }
                        />
                        <VictoryBar
                          cornerRadius={{ top: 4, bottom: 0 }}
                          barWidth={16}
                          data={getBarData()}
                          x="x"
                          labels={({ datum }) => datum.Absent.toLocaleString()}
                          // labelComponent={<VictoryLabel dy={50} />}
                          y="Absent"
                          style={{ labels: { fill: '#9872EF' } }}
                          alignment="start"
                          barRatio={0.8}
                          labelComponent={
                            <VictoryTooltip
                              label={({ datum }) =>
                                `${datum.x}: ${datum.Present || datum.Absent}`
                              }
                              style={{
                                fill: 'white', // Set the background color to white
                              }}
                              flyoutStyle={{
                                padding: '10px', // Adjust the padding as needed
                                fill: '#9872EF', // Background color of the tooltip box
                                stroke: '#9872EF', // Border color of the tooltip box
                                strokeWidth: 2, // Border width of the tooltip box
                                // You can further customize other flyout styles as needed
                              }}
                              textStyle={{
                                padding: 8, // Add padding to the text content
                                // You can also customize other text styles like font size, font family, etc. here
                              }}
                            />
                          }
                        />
                      </VictoryGroup>
                    </VictoryChart>
                  </Grid>
                )}
                <Grid container justify="space-between">
                  <Grid
                    xs={5}
                    item
                    container
                    justify="flex-start"
                    style={{ margin: '0 0 10px 0' }}
                  >
                    <Typography>
                      Turnover Rate
                      <span
                        style={{
                          padding: '3px 4px',
                          borderRadius: 10,
                          margin: '0px 2px',
                          fontSize: '1.1rem',
                          backgroundColor: '#81B734',
                          color: 'white',
                        }}
                      >
                        {turnoverRate !== 'N/A'
                          ? `${turnoverRate}%`
                          : turnoverRate}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    container
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      style={{
                        marginRight: '12px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: 'rgba(178, 190, 199, 1)',
                          fontSize: '11px',
                        }}
                      >
                        Present Workers
                      </Typography>
                      <div className="skilled-box"></div>
                    </Grid>
                    <Grid
                      item
                      style={{
                        marginRight: '12px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: 'rgba(178, 190, 199, 1)',
                          fontSize: '11px',
                        }}
                      >
                        Absent Workers
                      </Typography>
                      <div className="supervisor-box"></div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  justify="space-between"
                  style={{ marginTop: '1.5rem' }}
                >
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={5}
                    xs={12}
                    justify="space-around"
                    style={{
                      background: '#F9F9F9',
                      borderRadius: 10,
                      width: '100%',
                      // marginBottom: '1.5rem',
                    }}
                  >
                    <Grid>
                      <Typography
                        style={{ fontFamily: 'Roboto', fontSize: 13 }}
                      >
                        Present <br /> Workers Today
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: 40,
                          color: 'rgb(133, 186, 89)',
                        }}
                      >
                        {currentPresent?.length > 0 ? (
                          currentPresent[0]?.Present
                        ) : projectClassificationList?.length === 0 ? (
                          0
                        ) : (
                          <CircularProgress
                            style={{
                              color: 'rgb(133, 186, 89)',

                              width: '25px',
                              height: '25px',
                            }}
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={5}
                    xs={12}
                    className={classes.marginTop}
                    justify="space-around"
                    style={{
                      background: '#F9F9F9',
                      borderRadius: 10,
                      width: '100%',
                    }}
                  >
                    <Grid>
                      <Typography
                        style={{ fontFamily: 'Roboto', fontSize: 13 }}
                      >
                        Absent
                        <br />
                        Workers Today
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: 40,
                          color: '#A179F2',
                        }}
                      >
                        {currentAbsent?.length > 0 ? (
                          currentAbsent[0]?.Absent
                        ) : projectClassificationList?.length === 0 ? (
                          0
                        ) : (
                          <CircularProgress
                            style={{
                              color: '#A179F2',

                              width: '25px',
                              height: '25px',
                            }}
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12} className={classes.cardPaddingRight}>
          <Grid style={{ paddingTop: '10px' }}>
            <Card
              elevation={6}
              style={{
                width: '100%',
                height: '520px',

                borderRadius: 10,
                // height: '25em',
                // overflowX: 'scroll',
              }}
            >
              <CardHeader
                title={
                  <Grid container alignItems="center" justify="space-between">
                    <Grid item container justify="space-between">
                      <Grid item>
                        <Typography
                          variant="subtitle"
                          style={{
                            fontSize: 20,
                            fontWeight: '500',
                            fontFamily: 'Roboto',
                            // color: "#D9D9D9",
                          }}
                        >
                          Workforce Composition
                        </Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Button
                          size="small"
                          fullWidth
                          className={classes.buttonPurpleOpacity}
                          onClick={() =>
                            navigate('../attendance', {
                              replace: true,
                              // state: { from: location },
                            })
                          }
                        >
                          View All
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      // justify="center"
                      xs={12}
                      style={{ marginTop: '.6rem' }}
                    >
                      <FilterProjectComponent
                        projectClassificationList={projectClassificationList}
                        src={
                          !classifiedProject[0]?.url
                            ? defaultProjectImage
                            : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                        }
                        value={currentProjectSkillset}
                        onChange={classifyProjectSkillset}
                      />
                    </Grid>
                  </Grid>
                }
              />
              <CardContent>
                {projectClassificationList?.length === 0 ? (
                  <Grid
                    // maxWidth="md"
                    style={{
                      height: '250px',
                    }}
                    container
                    justifyContent="center"
                  >
                    'No Data Found'
                  </Grid>
                ) : (
                  <Container
                    // maxWidth="md"
                    style={{
                      height: '250px',
                    }}
                  >
                    <VictoryChart
                      theme={VictoryTheme.grayscale}
                      width={chartWidth}
                      domainPadding={{ x: 15, y: 30 }}
                    >
                      <VictoryAxis
                        dependentAxis
                        tickFormat={(tick) => tick.toFixed(0)}
                        style={{
                          axis: { stroke: 'transparent' },
                          ticks: { stroke: 'transparent' },
                          grid: { stroke: 'rgba(1,1,1,0.05)' },
                          tickLabels: {
                            fill: 'rgba(112, 112, 112, 1)',
                            fontSize: 14,
                            fontFamily: 'Roboto',
                          }, // Change label color
                        }}
                      />
                      <VictoryAxis
                        inDependentAxis
                        style={{
                          axis: { stroke: 'black' }, // Adjust x-axis color as needed
                          ticks: { stroke: 'black' },
                          tickLabels: {
                            fill: 'rgba(112, 112, 112, 1)',
                            fontSize: 14,
                            fontFamily: 'Roboto',
                          }, // Change label color
                        }}
                        tickLabelComponent={<VictoryLabel dx={20} />}
                      />
                      <VictoryStack
                        colorScale={['#9872EF', 'RGB(133, 186, 89)', '#3498B7']}
                        padding={{ left: 20, right: 60 }}
                      >
                        <VictoryBar
                          barWidth={16}
                          data={transformData()}
                          x="x"
                          labels={({ datum }) =>
                            `${datum.supervisor?.toLocaleString()} Supervisor \n ${
                              datum.x
                            }`
                          }
                          // labelComponent={<VictoryLabel dy={50} />}
                          y="supervisor"
                          style={{ labels: { fill: 'RGB(133, 186, 89)' } }}
                          alignment="start"
                          barRatio={0.8}
                          labelComponent={
                            <VictoryTooltip
                              label={({ datum }) => `label yahan bhi he`}
                              style={{ fill: 'white' }}
                              flyoutStyle={{
                                padding: '10px 20px', // Adjust the padding as needed
                                // padding: "5px 1rem",
                                color: 'white',
                                height: 40,
                                fontWeight: 'bold',
                                fill: 'black', // Background color of the tooltip box
                              }}
                              textStyle={{
                                padding: 8, // Add padding to the text content
                                // You can also customize other text styles like font size, font family, etc. here
                              }}
                            />
                          }
                        />
                        <VictoryBar
                          barWidth={16}
                          data={transformData()}
                          x="x"
                          labels={({ datum }) =>
                            `${datum.skilled?.toLocaleString()} Skilled \n ${
                              datum.x
                            }`
                          } // labelComponent={<VictoryLabel dy={50} />}
                          y="skilled"
                          style={{ labels: { fill: '#9872EF' } }}
                          alignment="start"
                          barRatio={0.8}
                          labelComponent={
                            <VictoryTooltip
                              label={({ datum }) => `label yahan bhi he`}
                              style={{ fill: 'white' }}
                              flyoutStyle={{
                                padding: '10px 20px', // Adjust the padding as needed
                                // padding: "5px 1rem",
                                color: 'white',
                                height: 40,
                                fontWeight: 'bold',
                                fill: 'black', // Background color of the tooltip box
                              }}
                              textStyle={{
                                padding: 8, // Add padding to the text content
                                // You can also customize other text styles like font size, font family, etc. here
                              }}
                            />
                          }
                        />
                        <VictoryBar
                          cornerRadius={{ top: 4, bottom: 0 }}
                          barWidth={16}
                          data={transformData()}
                          x="x"
                          labels={({ datum }) =>
                            `${datum.helper?.toLocaleString()} Helper \n ${
                              datum.x
                            }`
                          }
                          y="helper"
                          alignment="start"
                          barRatio={0.8}
                          style={{ labels: { fill: '##3498B7' } }}
                          labelComponent={
                            <VictoryTooltip
                              label={({ datum }) => `label yahan bhi he`}
                              style={{ fill: 'white' }}
                              flyoutStyle={{
                                padding: '10px 20px', // Adjust the padding as needed
                                // padding: "5px 1rem",
                                color: 'white',
                                height: 40,
                                fontWeight: 'bold',
                                fill: 'black', // Background color of the tooltip box
                              }}
                              textStyle={{
                                padding: 8, // Add padding to the text content
                                // You can also customize other text styles like font size, font family, etc. here
                              }}
                            />
                          }
                        />
                      </VictoryStack>
                    </VictoryChart>
                  </Container>
                )}
                <Grid container justifyContent="space-between">
                  <Typography>
                    Shortfall
                    <span
                      style={{
                        padding: '3px 4px',
                        borderRadius: 10,
                        margin: '0px 2px',
                        fontSize: '1.1rem',
                        backgroundColor: '#81B734',
                        color: 'white',
                      }}
                    >
                      {workforceMetrics || 'loading...'}
                    </span>
                  </Typography>

                  <Grid
                    item
                    xs={6}
                    container
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      style={{
                        marginRight: '12px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: 'rgba(178, 190, 199, 1)',
                          fontSize: '11px',
                        }}
                      >
                        Supervisor
                      </Typography>
                      <div className="supervisor-box"></div>
                    </Grid>
                    <Grid
                      item
                      style={{
                        marginRight: '12px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: 'rgba(178, 190, 199, 1)',
                          fontSize: '11px',
                        }}
                      >
                        Skilled
                      </Typography>
                      <div className="skilled-box"></div>
                    </Grid>
                    <Grid
                      item
                      style={{
                        marginRight: '12px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: 'rgba(178, 190, 199, 1)',
                          fontSize: '11px',
                        }}
                      >
                        Helper
                      </Typography>
                      <div className="helper-box"></div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  I
                  // justify="space-between"
                  style={{ marginTop: '1.5rem' }}
                >
                  <Grid
                    item
                    md={4}
                    xs={12}
                    container
                    style={{
                      // background: "#F9F9F9",
                      // borderRadius: 10,
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      item
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        paddingRight: '10px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Total <br /> Supervisors
                        </Typography>
                      </Grid>
                      <Grid
                      // style={{
                      //   marginRight: "10px",
                      // }}
                      >
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 40,
                            color: '#A179F2',
                          }}
                        >
                          {workerSkill?.length > 0 ? (
                            getTotalCounts().Supervisor ?? 0
                          ) : workerSkillLoading ? (
                            <CircularProgress
                              style={{
                                color: '#AF9CF6',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          ) : (
                            0
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    container
                    style={{
                      // background: "#F9F9F9",
                      // borderRadius: 10,
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      item
                      xs={12}
                      className={classes.marginTop}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        width: '100%',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Total
                          <br />
                          Skilled
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 40,
                            color: '#81B734',
                          }}
                        >
                          {workerSkill?.length > 0 ? (
                            getTotalCounts().Skilled ?? 0
                          ) : workerSkillLoading ? (
                            <CircularProgress
                              style={{
                                color: '#3498B7',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          ) : (
                            0
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={4}
                    xs={12}
                    className={classes.marginTop}
                    justify="space-around"
                    style={{
                      background: '#F9F9F9',
                      borderRadius: 10,
                      width: '100%',
                    }}
                  >
                    <Grid>
                      <Typography
                        style={{ fontFamily: 'Roboto', fontSize: 13 }}
                      >
                        Total
                        <br />
                        Helpers
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: 40,
                          color: 'rgba(52, 152, 183, 1)',
                        }}
                      >
                        {workerSkill?.length > 0 ? (
                          getTotalCounts().Helper ?? 0
                        ) : workerSkillLoading ? (
                          <CircularProgress
                            style={{
                              color: 'rgba(52, 152, 183, 1)',

                              width: '25px',
                              height: '25px',
                            }}
                          />
                        ) : (
                          0
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {leadTypeId === 'Contractor' && (
          <Grid
            item
            // md={6}
            xs={12}
            className={classes.marginTop}
            style={{ marginTop: '15px' }}
            container
            // justify='flex-end'
          >
            <Card
              elevation={12}
              style={{
                width: '100%',
                borderRadius: 10,
                minHeight: '400px', // Adjust the minimum height as needed
              }}
            >
              <CardHeader
                title={
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: 25,
                          fontWeight: '400',
                          fontFamily: 'Roboto',
                          // color: "#D9D9D9",
                        }}
                      >
                        Contractors Data
                      </Typography>
                    </Grid>

                    {/* Your FilterProjectComponent goes here */}
                  </Grid>
                }
                action={
                  <Grid container alignItems="center">
                    <Grid item style={{ marginRight: '10px' }}>
                      <FilterProjectComponent
                        projectClassificationList={projectClassificationList}
                        src={
                          !classifiedProject[0]?.url
                            ? defaultProjectImage
                            : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                        }
                        value={currentProjectContractor}
                        onChange={classifyProjectContractor}
                      />
                    </Grid>
                    <Grid
                      item
                      // container
                      // alignItems="center"
                      // justify="center"
                    >
                      {/* <Grid item> */}
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: '12px' }}
                      >
                        Date:
                      </Typography>
                      {/* </Grid> */}
                      {/* <Grid item style={{ width: "250px" }}> */}
                      <DatePicker
                        selected={pieSelectedDate}
                        onChange={onChangePieDate}
                        // startDate={startDate}
                        // endDate={endDate}
                        // selectsRange
                        isClearable={true}
                        className="border-datepicker"
                        placeholderText="Date Filter"
                      />
                      {/* </Grid> */}
                    </Grid>
                  </Grid>
                }
              />
              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <VictoryPie
                  data={
                    contractorsStats?.length >= 0
                      ? contractorsStats[0]?.map((item) => ({
                          x: item.contractor,
                          y: item.present,
                        }))
                      : [
                          { x: "dummy", y: 100 },
                          { x: "dummy1", y: 30 },
                          { x: "dummy1", y: 10 },
                          { x: "dummy1", y: 220 },
                          { x: "dummy1", y: 330 },
                          { x: "dummy1", y: 310 },
                          { x: "dummy1", y: 390 },
                        ]
                  }
                  width={700} // Adjust the width as needed
                  height={700} // Adjust the height as needed
                  labels={({ datum }) => `${datum.x}`}
                  labelComponent={
                    <VictoryLabel
                      style={{ fontSize: 10 }} // Adjust the font size as needed
                      dy={-10} // Adjust the vertical position of labels
                    />
                  }
                  responsive={true}
                  padding={20} // Adjust the padding to control the spacing between slices and the container
                  innerRadius={80} // Adjust the inner radius for a donut chart effect
                  colorScale={generateRandomHexColors(
                    contractorsStats?.length >= 0
                      ? contractorsStats[0]?.length
                      : 100
                  )}
                  // Specify your custom colors here

                  // [("#21D940", "#A179F2")]}
                /> */}
                </div>
                <Grid container>
                  <Grid item xs={5}>
                    <Typography
                      variant="subtitle"
                      style={{
                        color: 'rgba (173, 186,199, 0.2)',
                        fontSize: '18px',
                      }}
                    >
                      Contractors
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      variant="subtitle"
                      style={{
                        color: 'rgba (173, 186,199, 0.2)',
                        fontSize: '18px',
                      }}
                    >
                      {' '}
                      Workers Present
                    </Typography>
                  </Grid>
                  {contractorsStats?.length > 0
                    ? contractorsStats[0]?.map((item) => (
                        <Grid
                          container
                          key={item.contractor}
                          alignItems="center"
                        >
                          <Grid item xs={5}>
                            <Typography
                              variant="subtitle"
                              style={{
                                color: 'rgba (246, 247, 249, 1)',
                                fontSize: '12px',
                              }}
                            >
                              {item.contractor ?? 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid item xs={7} container>
                            <div
                              style={{
                                // width: item.present + "px" ?? 0,
                                width:
                                  (item?.present *
                                    getMaxPresent(contractorsStats[0])) /
                                    100 +
                                  '%',
                                backgroundColor: '#81B734',
                                height: '10px',
                              }}
                            ></div>
                            <small> {item.present ?? 'N/A'}</small>
                          </Grid>
                        </Grid>
                      ))
                    : contractorsStatsLoading
                    ? 'Loading...'
                    : 'No Data Found!'}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    );
  };

  const classifyProjectBudget = (project) => {
    if (project) {
      setCurrentProjectBudget(project);
      dispatch(getProjectBudget(project?.projectId));
    } else {
      setCurrentProjectBudget(projectClassificationList[0]);
      dispatch(getProjectBudget(projectClassificationList[0]?.projectId));
    }
  };

  function TooltipChart(title, value, tooltipTitle = '', size = 6) {
    return (
      <Grid item xs={size} container alignItems="center">
        <Grid item>
          <Typography
            style={{
              fontSize: '10px',
              fontWeight: '400',
              color: 'rgba(25, 36, 52, 1)',
              marginRight: '4px',
            }}
          >
            {title}
          </Typography>
        </Grid>
        {tooltipTitle !== '' ? (
          <BootstrapTooltip
            classes={{ tooltip: classes.customWidth }}
            title={tooltipTitle}
            placement="right"
          >
            <Grid
              item
              style={{
                backgroundColor: 'rgba(129, 183, 52, 1)',
                borderRadius: '8px',
                color: '#fff',
                padding: '3px 8px',
                cursor: 'pointer',
                fontSize: '13px',
              }}
            >
              {value}
            </Grid>
          </BootstrapTooltip>
        ) : (
          <Grid
            item
            style={{
              backgroundColor: 'rgba(129, 183, 52, 1)',
              borderRadius: '8px',
              color: '#fff',
              padding: '3px 8px',
              cursor: 'pointer',
              fontSize: '13px',
            }}
          >
            {value}
          </Grid>
        )}
      </Grid>
    );
  }

  function truncateString(str, maxLength) {
    if (str?.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    } else {
      return str;
    }
  }

  // Find the maximum value

  const maxBudgetedCost =
    projectBudgetData?.graphData.length &&
    Math.max(...projectBudgetData.graphData.map((item) => item.budgetedCost));
  const maxActualCost =
    projectBudgetData?.graphData.length &&
    Math.max(...projectBudgetData.graphData.map((item) => item.actualCost));
  const maxCommittedCost =
    projectBudgetData?.graphData.length &&
    Math.max(...projectBudgetData.graphData.map((item) => item.committedCost));
  const maxValue = Math.max(maxBudgetedCost, maxActualCost, maxCommittedCost);

  // const getBarDataBudget = () => {
  //   return projectBudgetData?.graphData?.map((item) => {
  //     let percent =
  //       item.budgetedCost <= 0 ? 0 : (item.budgetedCost / maxValue) * 100;
  //     const fullLabel = getFullLabel(item.label);
  //     return {
  //       x: truncateLabel(fullLabel), // Truncate the label if it is too long
  //       skill: truncateLabel(fullLabel), // Truncate the label if it is too long
  //       actualValue: item.actualCost <= 0 ? 0 : item.actualCost,
  //       percentage: percent === 0 ? 0 : percent <= 1 ? 1.3 : percent,
  //     };
  //   });
  // };

  const getBarDataBudget = () => {
    return projectBudgetData?.graphData?.map((item) => {
      let percent =
        item.budgetedCost <= 0 ? 0 : (item.budgetedCost / maxValue) * 100;
      return {
        x: truncateString(item.label, 6),
        skill: truncateString(item.label, 6),
        actualValue: item.budgetedCost <= 0 ? 0 : item.budgetedCost,
        percentage: percent === 0 ? 0 : percent <= 1 ? 1.3 : percent,
      };
    });
  };

  // Function to map short form labels to full names
  const getFullLabel = (shortLabel) => {
    const scope = projectBudgetData.scopeList.find(
      (item) => item.code === shortLabel
    );
    return scope ? scope.name : shortLabel;
  };

  const truncateLabel = (label, maxLength = 12) => {
    return label.length > maxLength ? label.slice(0, maxLength) + '...' : label;
  };

  // const getBarDataActualCost = () => {
  //   return projectBudgetData?.graphData?.map((item) => {
  //     let percent =
  //       item.actualCost <= 0 ? 0 : (item.actualCost / maxValue) * 100;
  //     const fullLabel = getFullLabel(item.label);
  //     return {
  //       x: truncateLabel(fullLabel), // Truncate the label if it is too long
  //       skill: truncateLabel(fullLabel), // Truncate the label if it is too long
  //       actualValue: item.actualCost <= 0 ? 0 : item.actualCost,
  //       percentage: percent === 0 ? 0 : percent <= 1 ? 1.3 : percent,
  //     };
  //   });
  // };

  const getBarDataActualCost = () => {
    return projectBudgetData?.graphData?.map((item) => {
      let percent =
        item.actualCost <= 0 ? 0 : (item.actualCost / maxValue) * 100;
      return {
        x: truncateString(item.label, 6),
        skill: truncateString(item.label, 6),
        actualValue: item.actualCost <= 0 ? 0 : item.actualCost,
        percentage: percent === 0 ? 0 : percent <= 1 ? 1.3 : percent,
      };
    });
  };

  // const getBarDataCommittedCost = () => {
  //   return projectBudgetData?.graphData?.map((item) => {
  //     let percent =
  //       item.committedCost <= 0 ? 0 : (item.committedCost / maxValue) * 100;
  //     const fullLabel = getFullLabel(item.label);
  //     return {
  //       x: truncateLabel(fullLabel), // Truncate the label if it is too long
  //       skill: truncateLabel(fullLabel), // Truncate the label if it is too long
  //       actualValue: item.actualCost <= 0 ? 0 : item.actualCost,
  //       percentage: percent === 0 ? 0 : percent <= 1 ? 1.3 : percent,
  //     };
  //   });
  // };

  const getBarDataCommittedCost = () => {
    return projectBudgetData?.graphData?.map((item) => {
      let percent =
        item.committedCost <= 0 ? 0 : (item.committedCost / maxValue) * 100;
      return {
        x: truncateString(item.label, 6),
        skill: truncateString(item.label, 6),
        actualValue: item.committedCost <= 0 ? 0 : item.committedCost,
        percentage: percent === 0 ? 0 : percent <= 1 ? 1.3 : percent,
      };
    });
  };

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  const renderVoluntaryExitsPieChart = () => {
    const { total } = labourTurnoverMetrics?.voluntaryExits || {};

    const isEmpty = [
      labourTurnoverMetrics?.voluntaryExits?.takingBreak,
      labourTurnoverMetrics?.voluntaryExits?.delayedPayment,
      labourTurnoverMetrics?.voluntaryExits?.emergency,
      labourTurnoverMetrics?.voluntaryExits?.betterwages,
    ].every((data) => !data);

    const empty = [{ x: 'Data not available', y: 100 }];
    const data = [
      { x: 'Taking a Break', y: 25 },
      { x: 'Delayed Payment', y: 25 },
      { x: 'Emergency', y: 25 },
      { x: 'Better Wages', y: 25 },
    ];

    return (
      <div
        style={{
          width: '400px',
          margin: '0 auto',
          position: 'relative',
          textAlign: 'center',
        }}
      >
        <svg viewBox="0 0 450 450">
          <defs>
            <linearGradient id="gradientA" gradientTransform="rotate(300.8)">
              <stop offset="0%" stopColor="#81B734" />
              <stop offset="100%" stopColor="#598F0C" />
            </linearGradient>
            <linearGradient id="gradientB" gradientTransform="rotate(198.24)">
              <stop offset="0%" stopColor="#A179F2" />
              <stop offset="100%" stopColor="#6944B4" />
            </linearGradient>
            <linearGradient id="gradientC" gradientTransform="rotate(198.24)">
              <stop offset="0%" stopColor="#81B734" />
              <stop offset="100%" stopColor="#81B734" />
            </linearGradient>
            <linearGradient id="gradientD" gradientTransform="rotate(198.24)">
              <stop offset="0%" stopColor="#A179F2" />
              <stop offset="100%" stopColor="#A179F2" />
            </linearGradient>
            <linearGradient id="gradientE" gradientTransform="rotate(198.24)">
              <stop offset="0%" stopColor="rgb(97, 97, 97)" />
              <stop offset="100%" stopColor="rgb(97, 97, 97)" />
            </linearGradient>
          </defs>
          <circle
            cx="200"
            cy="200"
            r="160"
            fill="white"
            style={{ filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))' }}
          />
          <VictoryPie
            standalone={false}
            width={400}
            height={400}
            data={isEmpty ? empty : data}
            innerRadius={140}
            padAngle={5}
            cornerRadius={50}
            labelRadius={({ innerRadius }) => innerRadius + 40}
            labels={({ datum }) =>
              datum.x === 'Taking a Break'
                ? `${Math.round(
                    labourTurnoverMetrics?.voluntaryExits?.takingBreak
                  )}%`
                : datum.x === 'Delayed Payment'
                ? `${Math.round(
                    labourTurnoverMetrics?.voluntaryExits?.delayedPayment
                  )}%`
                : datum.x === 'Emergency'
                ? `${Math.round(
                    labourTurnoverMetrics?.voluntaryExits?.emergency
                  )}%`
                : datum.x === 'Tardiness'
                ? `${Math.round(
                    labourTurnoverMetrics?.voluntaryExits?.betterwages
                  )}%`
                : '100%'
            }
            labelComponent={<VictoryTooltip />}
            style={{
              data: {
                fill: ({ datum }) =>
                  datum.x === 'Delayed Payment'
                    ? 'url(#gradientA)'
                    : datum.x === 'Taking a Break'
                    ? 'url(#gradientB)'
                    : datum.x === 'Emergency'
                    ? 'url(#gradientC)'
                    : datum.x === 'Better Wages'
                    ? 'url(#gradientD)'
                    : 'url(#gradientE)',
              },
            }}
          />
          <text
            x="200"
            y="200"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: '78px', fontWeight: 'bold', opacity: 0.2 }}
          >
            {total}
          </text>
        </svg>
      </div>
    );
  };

  const renderTerminationsPieChart = () => {
    const { total } = labourTurnoverMetrics?.terminations || {};

    const isEmpty = [
      labourTurnoverMetrics?.terminations?.noShow,
      labourTurnoverMetrics?.terminations?.jobEnded,
      labourTurnoverMetrics?.terminations?.misconduct,
      labourTurnoverMetrics?.terminations?.tardiness,
    ].every((data) => !data);

    const empty = [{ x: 'Data not available', y: 100 }];
    const data = [
      { x: 'No Show', y: labourTurnoverMetrics?.terminations?.noShow },
      { x: 'Job Ended', y: labourTurnoverMetrics?.terminations?.jobEnded },
      { x: 'Misconduct', y: labourTurnoverMetrics?.terminations?.misconduct },
      { x: 'Tardiness', y: labourTurnoverMetrics?.terminations?.tardiness },
    ];

    return (
      <div
        style={{
          width: '400px',
          margin: '0 auto',
          position: 'relative',
          textAlign: 'center',
        }}
      >
        <svg viewBox="0 0 450 450">
          <defs>
            <linearGradient id="gradientA" gradientTransform="rotate(300.8)">
              <stop offset="0%" stopColor="#81B734" />
              <stop offset="100%" stopColor="#598F0C" />
            </linearGradient>
            <linearGradient id="gradientB" gradientTransform="rotate(198.24)">
              <stop offset="0%" stopColor="#A179F2" />
              <stop offset="100%" stopColor="#6944B4" />
            </linearGradient>
            <linearGradient id="gradientC" gradientTransform="rotate(198.24)">
              <stop offset="0%" stopColor="#81B734" />
              <stop offset="100%" stopColor="#81B734" />
            </linearGradient>
            <linearGradient id="gradientD" gradientTransform="rotate(198.24)">
              <stop offset="0%" stopColor="#A179F2" />
              <stop offset="100%" stopColor="#A179F2" />
            </linearGradient>
            <linearGradient id="gradientE" gradientTransform="rotate(198.24)">
              <stop offset="0%" stopColor="rgb(97, 97, 97)" />
              <stop offset="100%" stopColor="rgb(97, 97, 97)" />
            </linearGradient>
          </defs>
          <circle
            cx="200"
            cy="200"
            r="160"
            fill="white"
            style={{ filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))' }}
          />
          <VictoryPie
            standalone={false}
            width={400}
            height={400}
            data={isEmpty ? empty : data}
            innerRadius={140}
            padAngle={5}
            cornerRadius={50}
            labelRadius={({ innerRadius }) => innerRadius + 40}
            labels={({ datum }) =>
              datum.x === 'No Show'
                ? `${Math.round(labourTurnoverMetrics?.terminations?.noShow)}%`
                : datum.x === 'Job Ended'
                ? `${Math.round(
                    labourTurnoverMetrics?.terminations?.jobEnded
                  )}%`
                : datum.x === 'Misconduct'
                ? `${Math.round(
                    labourTurnoverMetrics?.terminations?.misconduct
                  )}%`
                : datum.x === 'Tardiness'
                ? `${Math.round(
                    labourTurnoverMetrics?.terminations?.tardiness
                  )}%`
                : '100%'
            }
            labelComponent={
              <VictoryTooltip
                flyoutStyle={{
                  fill: 'transparent',
                  strokeWidth: 0,
                }}
              />
            }
            style={{
              data: {
                fill: ({ datum }) =>
                  datum.x === 'No Show'
                    ? 'url(#gradientA)'
                    : datum.x === 'Job Ended'
                    ? 'url(#gradientB)'
                    : datum.x === 'Misconduct'
                    ? 'url(#gradientC)'
                    : datum.x === 'Tardiness'
                    ? 'url(#gradientD)'
                    : 'url(#gradientE)',
              },
            }}
          />
          <text
            x="200"
            y="200"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: '78px', fontWeight: 'bold', opacity: 0.2 }}
          >
            {total}
          </text>
        </svg>
      </div>
    );
  };

  const PieChart = () => {
    return (
      <Grid item xs={12} style={{ marginTop: '0.8rem' }} container>
        <Card
          elevation={6}
          style={{
            width: '100%',
            borderRadius: 10,
          }}
        >
          <CardHeader
            title={
              <Grid container alignItems="center" justify="space-between">
                <Grid item container xs={6} alignItems="center">
                  <Grid container xs={12}>
                    <Grid item>Labour Turnover</Grid>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Divider
                        variant="middle"
                        flexItem
                        orientation="vertical"
                        style={{
                          height: '100%',
                          width: '2px',
                        }}
                      />
                    </div>

                    <Grid item>
                      <Button
                        size="small"
                        className={classes.buttonPurpleOpacity}
                        // startIcon={
                        //   <img
                        //     src={DownloadButtonFieldNote}
                        //     alt="FieldNote Download Button"
                        //   />
                        // }
                      >
                        View All
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container alignItems="center">
                    <Grid item xs={7}>
                      <FilterProjectComponent
                        projectClassificationList={projectClassificationList}
                        src={
                          !classifiedProject[0]?.url
                            ? defaultProjectImage
                            : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                        }
                        value={currentProjectLabourTurnover}
                        onChange={classifyProjectLabourTurnover}
                      />
                    </Grid>
                    <Grid
                      container
                      xs={5}
                      alignItems="baseline"
                      justifyContent="flex-end"
                    >
                      <Grid item style={{ marginRight: 5 }}>
                        <Typography>Filters</Typography>
                      </Grid>
                      <Grid item>
                        <DatePicker
                          showMonthYearPicker
                          // dateFormat="MMM, yyyy"
                          selected={labourTurnoverDate}
                          onChange={(update) => {
                            handleDateRangeLabourTurnover(update);
                          }}
                          isClearable={true}
                          className="border-datepicker"
                          placeholderText={moment().format('MMMM')}
                        />
                        {!startDate && (
                          <EventNoteIcon
                            style={{
                              position: 'absolute',
                              right: '5px',
                              top: '9px',
                              color: '#ADBAC3',
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={6} justify="flex-end">
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={4}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Average <br /> Turnover Rate
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#21D940',
                          }}
                        >
                          {labourTurnoverMetrics?.hasOwnProperty(
                            'averageTurnOverRate'
                          ) ? (
                            labourTurnoverMetrics?.averageTurnOverRate ?? 'N/A'
                          ) : (
                            <CircularProgress
                              style={{
                                color: '#21D940',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={4}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      // paddingRight: "10px",
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Workers <br /> Exited
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#21D940',
                          }}
                        >
                          {labourTurnoverMetrics?.hasOwnProperty(
                            'workersExited'
                          ) ? (
                            labourTurnoverMetrics?.workersExited ?? 'N/A'
                          ) : (
                            <CircularProgress
                              style={{
                                color: '#21D940',
                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Grid container style={{ height: '450px' }} justify="space-between">
              <Grid item xs={6}>
                <Typography variant="h6">Voluntary Exits</Typography>
                <>
                  {renderVoluntaryExitsPieChart()}
                  {labourTurnoverMetrics?.voluntaryExits?.takingBreak ? (
                    <Grid
                      item
                      container
                      justify="center"
                      style={{ marginTop: '10px' }}
                    >
                      <Typography
                        style={{
                          fontSize: '11px',
                          paddingRight: '5px',
                          color: '#6ba11e',
                        }}
                      >
                        Taking a break:{' '}
                        <b>
                          {labourTurnoverMetrics?.terminations?.takingBreak +
                            '%'}
                        </b>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '11px',
                          paddingRight: '5px',
                          color: '#aa86f3',
                        }}
                      >
                        Emergency:{' '}
                        <b>
                          {labourTurnoverMetrics?.terminations?.emergency + '%'}
                        </b>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '11px',
                          paddingRight: '5px',
                          color: 'rgba(255, 206, 86, 0.5)',
                        }}
                      >
                        Delayed Payment:{' '}
                        <b>
                          {labourTurnoverMetrics?.terminations?.delayedPayment +
                            '%'}
                        </b>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '11px',
                          paddingRight: '5px',
                          color: 'rgba(75, 192, 192, 0.5)',
                        }}
                      >
                        Better Wages:{' '}
                        <b>
                          {labourTurnoverMetrics?.terminations?.betterWages +
                            '%'}
                        </b>
                      </Typography>
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        item
                        container
                        justify="center"
                        style={{ marginTop: '10px' }}
                      >
                        <Typography
                          style={{
                            fontSize: '11px',
                            paddingRight: '5px',
                            color: 'rgb(83, 83, 83)',
                          }}
                        >
                          No Data: <b>'0%'</b>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Terminations</Typography>
                <Grid>
                  {renderTerminationsPieChart()}
                  {labourTurnoverMetrics?.terminations?.jobEnded ? (
                    <Grid
                      item
                      container
                      justify="center"
                      style={{ marginTop: '10px' }}
                    >
                      <Typography
                        style={{
                          fontSize: '11px',
                          paddingRight: '5px',
                          color: '#aa86f3',
                        }}
                      >
                        Job Ended:{' '}
                        <b>
                          {labourTurnoverMetrics?.terminations?.jobEnded + '%'}
                        </b>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '11px',
                          paddingRight: '5px',
                          color: '#6ba11e',
                        }}
                      >
                        No Show:{' '}
                        <b>
                          {labourTurnoverMetrics?.terminations?.noShow + '%'}
                        </b>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '11px',
                          paddingRight: '5px',
                          color: '#ffe6aa',
                        }}
                      >
                        Misconduct:{' '}
                        <b>
                          {labourTurnoverMetrics?.terminations?.misconduct +
                            '%'}
                        </b>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '11px',
                          paddingRight: '5px',
                          color: '#aa86f3',
                        }}
                      >
                        Tardiness:{' '}
                        <b>
                          {labourTurnoverMetrics?.terminations?.tardiness + '%'}
                        </b>
                      </Typography>
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        item
                        container
                        justify="center"
                        style={{ marginTop: '10px' }}
                      >
                        <Typography
                          style={{
                            fontSize: '11px',
                            paddingRight: '5px',
                            color: 'rgb(83, 83, 83)',
                          }}
                        >
                          No Data: <b>'0%'</b>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  return (
    <Page className={classes.root} title="Bettamint Dashboard">
      <Container maxWidth={false}>
        <Grid container>
          <Grid container item xs={12} className={classes.cardPaddingRight}>
            {renderWorkersCard()}
            <Suspense fallback="Loading...">
              <Grid item container>
                <BOQProgressGreenContainer
                  projectClassificationList={projectClassificationList}
                  currentProject={currentProject}
                  showButtons={false}
                  classifyProject={classifyProjectBOQGreenContainer}
                  classifiedProject={classifiedProject}
                  radius={'10px'}
                />
              </Grid>
            </Suspense>
            {renderCarts()}
            {BillingChart()}

            {ChartSecondCards()}

            <PieChart />

            {NewFiledNoteComponent()}
          </Grid>
        </Grid>
        <Toaster />
      </Container>
    </Page>
  );

  function BillingChart() {
    return (
      <Grid item xs={12} style={{ marginTop: '0.8rem' }}>
        <Card
          elevation={6}
          style={{
            width: '100%',
            borderRadius: 10,
            height: '450px',
          }}
        >
          <CardHeader
            title={
              <Grid container alignItems="center" justify="space-between">
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: 19,
                        fontWeight: '500',
                        fontFamily: 'Roboto',
                        // color: "#D9D9D9",
                      }}
                    >
                      Budgeted Vs Committed Vs Actual Cost
                    </Typography>
                  </Grid>

                  <Grid xs={8} container item>
                    <Grid container item md={4} xs={12}>
                      <Grid
                        item
                        container
                        alignItems="center"
                        justify="space-around"
                        style={{
                          background: '#F9F9F9',
                          borderRadius: 10,
                          marginRight: '10px',
                          height: '60px',
                        }}
                      >
                        <Grid>
                          <Typography
                            style={{ fontFamily: 'Roboto', fontSize: 12 }}
                          >
                            Budget <br /> Cost
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 25,
                              color: '#21D940',
                            }}
                          >
                            {projectBudgetData ? (
                              '₹' +
                              projectBudgetData?.budgetedCost?.toLocaleString()
                            ) : projectClassificationList?.length === 0 ? (
                              0
                            ) : (
                              <CircularProgress
                                style={{
                                  color: '#21D940',

                                  width: '25px',
                                  height: '25px',
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      item
                      md={4}
                      xs={12}
                      className={classes.marginTop}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        width: '100%',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 12 }}
                        >
                          Committed
                          <br />
                          Cost
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 25,
                            color: '#7E7E7E',
                          }}
                        >
                          {projectBudgetData ? (
                            '₹' +
                            projectBudgetData?.committedCost?.toLocaleString()
                          ) : projectClassificationList?.length === 0 ? (
                            0
                          ) : (
                            <CircularProgress
                              style={{
                                color: '#7E7E7E',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      item
                      md={4}
                      xs={12}
                      className={classes.marginTop}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        justify="space-around"
                        alignItems="center"
                        style={{
                          background: '#F9F9F9',
                          borderRadius: 10,
                          width: '100%',
                          marginLeft: '10px',
                          height: '60px',
                        }}
                      >
                        <Grid>
                          <Typography
                            style={{ fontFamily: 'Roboto', fontSize: 12 }}
                          >
                            Actual
                            <br />
                            Cost
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 25,
                              color: '#AF9CF6',
                            }}
                          >
                            {projectBudgetData ? (
                              '₹' +
                              projectBudgetData?.actualCost?.toLocaleString()
                            ) : projectClassificationList?.length === 0 ? (
                              0
                            ) : (
                              <CircularProgress
                                style={{
                                  color: '#AF9CF6',

                                  width: '25px',
                                  height: '25px',
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ marginTop: '.6rem' }}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item md={8} xs={12}>
                    <FilterProjectComponent
                      projectClassificationList={projectClassificationList}
                      src={
                        !classifiedProject[0]?.url
                          ? defaultProjectImage
                          : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                      }
                      value={currentProjectBudget}
                      onChange={classifyProjectBudget}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Grid container item xs={12}>
              {projectBudgetLoading ? (
                <div style={{ height: '340px', textAlign: 'center' }}>
                  Loading...
                </div>
              ) : (
                <Grid
                  style={{
                    height: '250px',
                  }}
                  xs={12}
                  ref={wideChartContainerRef}
                >
                  <VictoryChart
                    animate={{
                      duration: 1500,
                      onLoad: { duration: 1000 },
                    }}
                    theme={VictoryTheme.grayscale}
                    width={wideChartWidth * 1.2} // Increase the width for more items
                    padding={{ top: 10, bottom: 30, left: 80, right: 80 }} // Adjust padding if needed
                  >
                    <VictoryGroup
                      offset={22}
                      colorScale={['RGB(133, 186, 89)', '#7E7E7E', '#A179F2']}
                    >
                      <VictoryBar
                        barWidth={20}
                        data={getBarDataBudget()}
                        x="x"
                        labels={({ datum }) =>
                          '₹' + datum.percentage?.toLocaleString()
                        }
                        y="percentage"
                        cornerRadius={{ top: 5, bottom: 0 }}
                        style={{ labels: { fill: 'RGB(133, 186, 89)' } }}
                        alignment="start"
                        labelComponent={
                          <VictoryTooltip
                            labels={({ datum }) =>
                              `₹${datum.actualValue?.toLocaleString()}`
                            }
                            style={{ fill: 'white' }}
                            flyoutStyle={{
                              padding: 10,
                              fill: 'RGB(133, 186, 89)',
                              stroke: 'RGB(133, 186, 89)',
                              strokeWidth: 2,
                            }}
                            textStyle={{ padding: 8 }}
                          />
                        }
                      />
                      <VictoryBar
                        barWidth={20}
                        data={getBarDataCommittedCost()}
                        x="x"
                        cornerRadius={{ top: 5, bottom: 0 }}
                        labels={({ datum }) =>
                          `₹${datum.actualValue?.toLocaleString()}`
                        }
                        y="percentage"
                        style={{ labels: { fill: '#7E7E7E' } }}
                        alignment="start"
                        labelComponent={
                          <VictoryTooltip
                            style={{ fill: 'white' }}
                            flyoutStyle={{
                              padding: '10px',
                              fill: '#7E7E7E',
                              stroke: '#7E7E7E',
                              strokeWidth: 2,
                            }}
                            textStyle={{ padding: 8 }}
                          />
                        }
                      />
                      <VictoryBar
                        barWidth={20}
                        data={getBarDataActualCost()}
                        x="x"
                        cornerRadius={{ top: 5, bottom: 0 }}
                        labels={({ datum }) =>
                          `₹${datum.actualValue?.toLocaleString()}`
                        }
                        y="percentage"
                        style={{ labels: { fill: '#9872EF' } }}
                        alignment="start"
                        labelComponent={
                          <VictoryTooltip
                            label={({ datum }) =>
                              `${datum.x}: ${datum.percentage}`
                            }
                            style={{ fill: 'white' }}
                            flyoutStyle={{
                              padding: '10px',
                              fill: '#9872EF',
                              stroke: '#9872EF',
                              strokeWidth: 2,
                            }}
                            textStyle={{ padding: 8 }}
                          />
                        }
                      />
                    </VictoryGroup>

                    <VictoryAxis
                      dependentAxis
                      domain={[0, 100]}
                      tickFormat={(tick) => `₹${tick.toLocaleString()}0000`}
                    />
                    <VictoryAxis
                      tickFormat={(tick) => tick}
                      items
                      offsetX={50}
                      tickLabelComponent={<VictoryLabel dx={20} />}
                      // domainPadding={{ x: [20, 20] }} // Add padding to the x-axis to space out the items
                    />
                  </VictoryChart>
                  <Grid container style={{ marginTop: '0.5rem' }}>
                    {TooltipChart(
                      'Cost To Complete',
                      '₹' +
                        (
                          projectBudgetData?.budgetedCost -
                          projectBudgetData?.actualCost
                        ).toLocaleString(),
                      // "₹ Lakhs/month",
                      // "Lakhs/month",
                      '',
                      7
                    )}
                    <Grid
                      item
                      xs={5}
                      container
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid
                        item
                        style={{
                          marginRight: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: 'rgba(178, 190, 199, 1)',
                            fontSize: '11px',
                          }}
                        >
                          Budgeted
                        </Typography>
                        <div className="skilled-box"></div>
                      </Grid>
                      <Grid
                        item
                        style={{
                          marginRight: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: 'rgba(178, 190, 199, 1)',
                            fontSize: '11px',
                          }}
                        >
                          Committed
                        </Typography>
                        <div className="commited-box"></div>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: 'rgba(178, 190, 199, 1)',
                            fontSize: '11px',
                          }}
                        >
                          Actual
                        </Typography>
                        <div className="supervisor-box"></div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function NewFiledNoteComponent() {
    const CustomImageCard = ({ item }) => (
      <Card
        elevation={6}
        style={{
          width: '98%',
          borderRadius: 10,
          margin: '0.5rem 1rem',
          border: '1px solid rgba(0, 0, 0, 0.06)',
        }}
      >
        <CardContent>
          <Grid container justify="space-between">
            <Grid
              item
              style={{
                width: '53%',
                height: '387px',
              }}
            >
              <Grid
                item
                style={{
                  position: 'relative',
                  // maxWidth: "400px",
                }}
              >
                <img
                  src={item?.thumbnail}
                  alt={'FieldNote Visual'}
                  style={{
                    width: '98%',
                    maxHeight: '387px',

                    borderRadius: '8px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                />
                <Grid
                  container
                  justify="space-between"
                  style={{
                    maxWidth: '100px',
                    backgroundColor: 'rgba(99,99,99, 0.5)',
                    borderRadius: '10px',
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    color: '#fff',
                    height: '35px',
                    // paddingRight: "10px",
                  }}
                >
                  <Grid
                    style={{
                      width: '67%',
                      textAlign: 'right',
                      padding: '3px 0',
                      paddingRight: '5px',
                    }}
                    container
                    alignItems="center"
                  >
                    <p
                      style={{
                        fontSize: '10px',
                        fontWeight: 300,
                        width: '100%',
                      }}
                    >
                      STATUS
                    </p>
                    <p
                      style={{
                        fontSize: '12px',
                        width: '100%',
                        // paddingTop: "3px",
                      }}
                    >
                      {item?.action === '' || !item?.action
                        ? 'N/A'
                        : item.action === 'MarkAsResolved'
                        ? 'Resolved'
                        : item.action === 'ReworkRequired'
                        ? 'Rework'
                        : item.action === 'DecisionPending' && 'Pending'}
                    </p>
                  </Grid>
                  <Grid
                    container
                    style={{
                      backgroundColor: '#f5f1fdff',
                      // height: "42px",
                      // width: "120px",
                      position: 'relative',
                      // border: "1px solid red",
                      borderRadius: '10px',
                      width: '30%',
                    }}
                  >
                    {item.action === 'MarkAsResolved' ? (
                      <CheckCircleOutlinedIcon
                        style={{
                          color: '#986FEF',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          fontSize: '26px',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    ) : item.action === 'ReworkRequired' ? (
                      <RestoreIcon
                        style={{
                          color: '#986FEF',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          fontSize: '26px',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    ) : item.action === 'DecisionPending' ? (
                      <TimerIcon
                        style={{
                          color: '#986FEF',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          fontSize: '26px',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    ) : (
                      <ErrorOutlineOutlinedIcon
                        style={{
                          color: 'red',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          fontSize: '26px',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  style={{
                    padding: '0 1rem',
                    position: 'absolute',
                    bottom: '20px',
                    zIndex: 100,
                  }}
                >
                  <Grid
                    item
                    container
                    style={{
                      color: '#e0e1e1ff',
                      marginTop: '1rem',
                      // backgroundColor: "rgba(99,99,99, 0.3)",
                      // borderRadius: "10px",
                      // padding: "5px 0px 5px 5px ",
                      // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                    alignItems="center"
                    // justify="flex-start"
                  >
                    <Grid item>
                      <RoomOutlinedIcon style={{ fontSize: '30px' }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        align="left"
                        style={{
                          fontSize: '10px',
                          textShadow: '2px 2px 4px #000000',
                        }}
                      >
                        LOCATION
                      </Typography>{' '}
                      <Typography
                        style={{
                          textShadow: '2px 2px 4px #000000',
                          fontSize: '14px',
                        }}
                      >
                        {item?.location ?? 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                paddingLeft: '10px',
                width: '47%',
              }}
            >
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(126, 131, 134, 0.7)',
                      fontSize: '10px',
                    }}
                    align="left"
                  >
                    ID
                  </Typography>
                  <Typography variant="subtitle1" align="left">
                    {item?.fieldNoteId ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item>
                  <button
                    className={classes.buttonPurpleOpacity}
                    style={{
                      width: '42px',
                      padding: '10px 0',
                      backgroundColor: 'rgba(161, 120, 242, 0.20)',
                      borderRadius: '9px',
                      cursor: 'pointer',
                      border: 'none',
                    }}
                  >
                    <img
                      src={DownloadButtonFieldNote}
                      alt="FieldNote Download Button"
                    />
                  </button>
                </Grid>
              </Grid>

              <Grid
                // container
                item
                style={{
                  borderRadius: '10px',
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  padding: '10px 20px',
                  marginTop: '10px',
                }}
              >
                <Typography
                  variant="subtitle1"
                  align="left"
                  style={{
                    color: 'rgba(126, 131, 134, 0.7)',
                    fontSize: '10px',
                    fontWeight: 500,
                  }}
                >
                  SCOPE OF WORK
                </Typography>
                <Typography
                  variant="subtitle2"
                  align="left"
                  style={{
                    fontSize: '20px',
                    fontWeight: 500,
                  }}
                >
                  {item?.scopeOfWork?.name ?? 'N/A'}
                </Typography>
              </Grid>

              <Grid item style={{ marginTop: '10px' }}>
                <Grid item style={{ marginTop: '10px' }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    style={{
                      color: 'rgba(126, 131, 134, 0.7)',
                      fontSize: '10px',
                      fontWeight: 500,
                    }}
                  >
                    DESCRIPTION
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{
                      fontSize: '15px',
                      fontWeight: 400,
                    }}
                  >
                    {item?.description &&
                    item?.description?.split(' ')?.length > 15
                      ? item?.description?.split(' ')?.slice(0, 15).join(' ') +
                        '...'
                      : item?.description ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: '10px' }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    style={{
                      color: 'rgba(126, 131, 134, 0.7)',
                      fontSize: '10px',
                      fontWeight: 500,
                    }}
                  >
                    PROJECT
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{
                      fontSize: '15px',
                      fontWeight: 400,
                    }}
                  >
                    {item?.project?.name ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: '10px' }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    style={{
                      color: 'rgba(126, 131, 134, 0.7)',
                      fontSize: '10px',
                      fontWeight: 500,
                    }}
                  >
                    DATE & TIME
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{
                      fontSize: '15px',
                      fontWeight: 400,
                    }}
                  >
                    {item?.dateTime
                      ? moment(item?.dateTime).format('h:mma dddd')
                      : 'N/A'}{' '}
                    |{' '}
                    {item?.dateTime
                      ? moment(item?.dateTime).format('D MMM, YYYY')
                      : 'N/A'}{' '}
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: '10px' }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    style={{
                      color: 'rgba(126, 131, 134, 0.7)',
                      fontSize: '10px',
                      fontWeight: 500,
                    }}
                  >
                    ASSIGNED TO
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{
                      fontSize: '15px',
                      fontWeight: 400,
                    }}
                  >
                    {item?.contractor?.fullName ?? 'N/A'}
                  </Typography>
                </Grid>
                {item?.action != null && (
                  <Grid item style={{ marginTop: '10px' }}>
                    <Typography
                      variant="subtitle1"
                      align="left"
                      style={{
                        color: 'rgba(126, 131, 134, 0.7)',
                        fontSize: '10px',
                        fontWeight: 500,
                      }}
                    >
                      STATUS
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      style={{
                        fontSize: '15px',
                        fontWeight: 400,
                      }}
                    >
                      {item?.action != null ? printStatus(item.action) : 'N/A'}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <div
                style={{
                  width: '100%',
                  border: '1px solid rgba(0, 0, 0, .1)',
                  margin: '10px 0',
                }}
              ></div>

              {item.action === null && (
                <Grid
                  item
                  container
                  justify="flex-end"
                  style={{ marginTop: '10px' }}
                >
                  <Button
                    className={classes.buttonPurpleOpacity}
                    style={{
                      marginRight: '5px',
                      borderRadius: '8px',
                      width: '110px',
                      fontSize: '12px',
                      backgroundColor: 'rgba(173, 186, 195, 1)',
                      color: '#fff',
                    }}
                    // size="small"
                    onClick={() =>
                      markTheFieldNoteFromServer(
                        item.fieldNoteId,
                        'DecisionPending'
                      )
                    }
                  >
                    PENDING
                  </Button>
                  <Button
                    className={classes.buttonPurpleOpacity}
                    style={{
                      marginRight: '5px',
                      borderRadius: '8px',
                      width: '110px',
                      fontSize: '12px',
                      color: '#fff',
                      backgroundColor: 'rgba(161, 121, 242, 1)',
                    }}
                    onClick={() =>
                      markTheFieldNoteFromServer(
                        item.fieldNoteId,
                        'ReworkRequired'
                      )
                    }
                    // size="small"
                  >
                    REWORK
                  </Button>
                  <Button
                    className={classes.buttonPurpleOpacity}
                    style={{
                      borderRadius: '8px',
                      width: '110px',
                      fontSize: '12px',
                      color: '#fff',
                      backgroundColor: 'rgba(129, 183, 52, 1)',
                    }}
                    onClick={() =>
                      markTheFieldNoteFromServer(
                        item.fieldNoteId,
                        'MarkAsResolved'
                      )
                    }
                    // size="small"
                  >
                    RESOLVED
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );

    return (
      <Grid
        container
        xs={12}
        style={{
          marginTop: '1rem',
          zoom: '100%',
        }}
      >
        <Card
          elevation={6}
          style={{
            width: '100%',
            borderRadius: 10,
            // marginTop: '0.8rem',
            minHeight: '41em',
          }}
        >
          <CardHeader
            title={
              <Grid container alignItems="center" justify="space-between">
                <Grid item container xs={6} alignItems="center">
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: '500',
                        fontFamily: 'Roboto',
                        // color: "#D9D9D9",
                      }}
                    >
                      Field Notes
                    </Typography>
                  </Grid>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Divider
                      variant="middle"
                      flexItem
                      orientation="vertical"
                      style={{
                        height: '20px',
                        width: '2px',
                      }}
                    />
                  </div>

                  <Grid item>
                    <Button
                      size="small"
                      className={classes.buttonPurpleOpacity}
                      style={{
                        padding: '10px 1.3rem',
                        borderRadius: '9px',
                        marginRight: '10px',
                      }}
                      onClick={handleFielNoteDownload}
                      startIcon={
                        <img
                          src={DownloadButtonFieldNote}
                          alt="FieldNote Download Button"
                        />
                      }
                    >
                      Download
                    </Button>
                    <Link to="/app/field-notes/create">
                      <Button
                        size="small"
                        className={classes.buttonPurpleOpacity}
                        style={{
                          padding: '10px 1.3rem',
                          borderRadius: '9px',
                        }}
                        startIcon={
                          <AddIcon
                            fontSize="large"
                            style={{
                              color: '#986FEF',
                              textAlign: 'center',
                            }}
                          />
                        }
                      >
                        Add New
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
                <Grid item container xs={6}>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={4}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Resolved <br />
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#21D940',
                          }}
                        >
                          {projectClassificationList?.length === 0
                            ? 0
                            : fieldNoteMetrics?.resolved ?? (
                                <CircularProgress
                                  style={{
                                    color: '#21D940',

                                    width: '25px',
                                    height: '25px',
                                  }}
                                />
                              )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={4}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Pending <br />
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#adbac3',
                          }}
                        >
                          {projectClassificationList?.length === 0
                            ? 0
                            : fieldNoteMetrics?.pending ?? (
                                <CircularProgress
                                  style={{
                                    color: '#adbac3',

                                    width: '25px',
                                    height: '25px',
                                  }}
                                />
                              )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={4}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Rework <br />
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#AA86F3',
                          }}
                        >
                          {projectClassificationList?.length === 0
                            ? 0
                            : fieldNoteMetrics?.rework ?? (
                                <CircularProgress
                                  style={{
                                    color: '#AA86F3',

                                    width: '25px',
                                    height: '25px',
                                  }}
                                />
                              )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
          <Suspense fallback="loading">
            <Carousal
              items={
                fieldNoteList?.length > 0
                  ? fieldNoteList?.map((item) => ({
                      ...item,
                      original: `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item?.imageUrl}`,
                      thumbnail: `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item?.imageUrl}`,
                    }))
                  : []
              }
              refetch={fetchItems}
              handleProjectChange={classifyProjectCarousel}
              currentProject={currentProjectCarousel}
              isLoading={carousalLoading}
              classes={classes}
            />
          </Suspense>
        </Card>
      </Grid>
    );
  }

  function ChartSecondCards() {
    const maxY =
      paymentYAxisLimit && paymentYAxisLimit.length > 0
        ? Math.max(...paymentYAxisLimit) === 0
          ? 5000
          : Math.max(...paymentYAxisLimit)
        : 5000;
    return (
      <Grid item container style={{ marginTop: '.8rem' }}>
        <Grid
          item
          md={6}
          xs={12}
          style={{ paddingRight: 10 }}
          ref={chartContainerRef}
        >
          <Card
            elevation={6}
            style={{
              borderRadius: 10,
              height: '520px',
            }}
          >
            <CardHeader
              title={
                <Grid container alignItems="center" justify="space-between">
                  <Grid item container justify="space-between">
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: '500',
                          fontFamily: 'Roboto',
                          // color: "#D9D9D9",
                        }}
                      >
                        Labour Expense
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        className={classes.buttonPurpleOpacity}
                        // size="small"
                        onClick={() =>
                          navigate('../payments', {
                            replace: true,
                            // state: { from: location },
                          })
                        }
                      >
                        Pay Now
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ marginTop: '.6rem' }}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item md={9} xs={12}>
                      <FilterProjectComponent
                        projectClassificationList={projectClassificationList}
                        src={
                          !classifiedProject[0]?.url
                            ? defaultProjectImage
                            : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                        }
                        value={currentProjectPayments}
                        onChange={classifyProjectPayments}
                      />
                    </Grid>
                    <Grid item md={3} xs={12} className={classes.marginTop}>
                      {' '}
                      <DatePicker
                        selected={paymentsSelectedDate}
                        onChange={onChangeDatePayments}
                        // startDate={startDate}
                        // endDate={endDate}
                        // selectsRange
                        isClearable={true}
                        className="border-datepicker"
                        placeholderText="Date Filter"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
            <CardContent>
              <Grid container justify="center" alignItems="center">
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    height: '200px',
                  }}
                >
                  <VictoryChart
                    domainPadding={{ x: 15, y: 30 }}
                    theme={VictoryTheme.grayscale}
                    width={chartWidth}
                    // height={200}
                    // padding={{ top: 30, bottom: 60, left: 50, right: 30 }}
                    // padding={{ top: 30, bottom: 30, left: 60, right: 30 }}
                    padding={{ top: 15, bottom: 30, left: 10, right: 0 }}
                    domain={{
                      y: [0, maxY],
                    }}
                  >
                    <VictoryAxis
                      dependentAxis
                      style={{
                        axis: { stroke: 'black' }, // Adjust x-axis color as needed
                        ticks: { stroke: 'black' },
                        tickLabels: {
                          fill: 'rgba(112, 112, 112, 1)',
                          fontSize: 18,
                          fontFamily: 'Roboto',
                        }, // Change label color // Change label color
                      }}
                      tickFormat={(tick) => `₹${tick.toLocaleString()}`}
                    />
                    <VictoryAxis
                      style={{
                        axis: { stroke: 'black' }, // Adjust x-axis color as needed
                        ticks: { stroke: 'black' },
                        tickLabels: {
                          fill: 'rgba(112, 112, 112, 1)',
                          fontSize: 18,
                          fontFamily: 'Roboto',
                        }, // Change label color
                      }}
                      tickLabelComponent={<VictoryLabel dx={12} />}
                    />
                    <VictoryGroup
                      offset={20}
                      colorScale={['RGB(133, 186, 89)', '#9872EF', , 'grey']}
                    >
                      <VictoryBar
                        samples={100}
                        barWidth={20}
                        data={
                          payments?.length
                            ? payments.map((item) => ({
                                y: item.DueAmount,
                                x: item.Day,
                              }))
                            : []
                        }
                        x="x"
                        // labels={({ datum }) => datum.Absent}
                        labels={({ datum }) => '₹' + datum.y.toLocaleString()}
                        // labelComponent={<VictoryLabel dy={50} />}
                        y="y"
                        style={{ labels: { fill: '#9872EF' } }}
                        alignment="start"
                        barRatio={0.8}
                        labelComponent={
                          <VictoryTooltip
                            label={({ datum }) => `${datum.x}: ${datum.y}`}
                            style={{
                              fill: 'white', // Set the background color to white
                            }}
                            flyoutStyle={{
                              padding: 10, // Adjust the padding as needed

                              // padding: "5px 1rem",
                              // color: "red",
                              fill: 'RGB(133, 186, 89)', // Background color of the tooltip box
                              stroke: 'RGB(133, 186, 89)', // Border color of the tooltip box
                              strokeWidth: 2, // Border width of the tooltip box
                              // You can further customize other flyout styles as needed
                            }}
                            textStyle={{
                              padding: 8, // Add padding to the text content
                              // You can also customize other text styles like font size, font family, etc. here
                            }}
                          />
                        }
                      />
                    </VictoryGroup>
                  </VictoryChart>
                </Grid>
                <Grid container style={{ marginTop: '0.8rem' }}>
                  {/* {TooltipChart(
                    "Turnover Rate",
                    "₹20 Lakhs/month",
                    "Lakhs/month",
                    12
                  )} */}
                </Grid>
                <Grid
                  item
                  container
                  justify="flex-start"
                  style={{ margin: '0 0 10px 0' }}
                >
                  <Typography>
                    Turnonver Rate
                    <span
                      style={{
                        padding: '3px 4px',
                        borderRadius: 10,
                        margin: '0px 2px',
                        fontSize: '1.1rem',
                        backgroundColor: '#81B734',
                        color: 'white',
                      }}
                    >
                      {projectClassificationList?.length === 0
                        ? 0
                        : workforceMetrics || 'loading...'}
                    </span>
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  justify="space-between"
                  // style={{ marginTop: "1.5rem" }}
                >
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={6}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Average <br /> Daily Wage
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#81B734',
                          }}
                        >
                          {labourExpenseMetrics?.averageDailyWage >= 0 ? (
                            '₹' +
                            labourExpenseMetrics?.averageDailyWage?.toFixed(1)
                          ) : projectClassificationList?.length === 0 ? (
                            0
                          ) : (
                            <CircularProgress
                              style={{
                                color: '#21D940',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={6}
                    xs={12}
                    className={classes.marginTop}
                    justify="space-around"
                    style={{
                      background: '#F9F9F9',
                      borderRadius: 10,
                      width: '100%',
                      height: '60px',
                    }}
                  >
                    <Grid>
                      <Typography
                        style={{ fontFamily: 'Roboto', fontSize: 13 }}
                      >
                        Labour Cost
                        <br />
                        Per Month
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: 30,
                          color: '#A179F2',
                        }}
                      >
                        {labourExpenseMetrics?.totalExpenseForLast30Days >=
                        0 ? (
                          '₹' +
                          labourExpenseMetrics?.totalExpenseForLast30Days?.toFixed(
                            1
                          )
                        ) : projectClassificationList?.length === 0 ? (
                          0
                        ) : (
                          <CircularProgress
                            style={{
                              color: '#AF9CF6',

                              width: '25px',
                              height: '25px',
                            }}
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} xs={12} style={{ paddingLeft: 10 }}>
          <Card
            elevation={3}
            style={{
              borderRadius: 10,
              height: '520px',
            }}
          >
            <CardHeader
              title={
                <Grid container alignItems="center" justify="space-between">
                  <Grid item container justify="space-between">
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: '500',
                          fontFamily: 'Roboto',
                          // color: "#D9D9D9",
                        }}
                      >
                        Financial Progress
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        className={classes.buttonPurpleOpacity}
                        // size="small"
                        onClick={() =>
                          navigate('../productivity', {
                            replace: true,
                            // state: { from: location },
                          })
                        }
                      >
                        View Detail
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ marginTop: '.6rem' }}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item md={7} xs={12}>
                      <FilterProjectComponent
                        projectClassificationList={projectClassificationList}
                        src={
                          !classifiedProject[0]?.url
                            ? defaultProjectImage
                            : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                        }
                        value={currentProjectFinancial}
                        onChange={classifyProjectFinancial}
                      />
                    </Grid>
                    <Grid
                      item
                      md={5}
                      xs={12}
                      className={classes.marginTop}
                      container
                      alignItems="center"
                      justify="flex-end"
                    >
                      <Grid
                        item
                        style={{
                          width: '210px',
                          position: 'relative',
                        }}
                      >
                        <DatePicker
                          dateFormat="d MMM, yyyy"
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            handleDateRange(update);
                          }}
                          isClearable={true}
                          className="border-datepicker"
                          placeholderText={
                            moment().format('DD MMM, YYYY') +
                            ' - ' +
                            moment().add(1, 'days').format('DD MMM, YYYY')
                          }
                        />
                        {!startDate && (
                          <EventNoteIcon
                            style={{
                              position: 'absolute',
                              right: '5px',
                              top: '9px',
                              color: '#ADBAC3',
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
            <CardContent>
              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    height: '250px',
                  }}
                >
                  <VictoryChart
                    domainPadding={{ x: 15, y: 30 }}
                    theme={VictoryTheme.grayscale}
                    width={chartWidth}
                    // padding={{ top: 15, bottom: 30, left: 10, right: 0 }}
                  >
                    <VictoryAxis
                      dependentAxis
                      tickFormat={(tick) => `₹${tick.toLocaleString()}`}
                      style={{
                        axis: { stroke: 'transparent' },
                        ticks: { stroke: 'transparent' },
                        grid: { stroke: 'rgba(1,1,1,0.05)' },
                        tickLabels: {
                          fill: 'rgba(112, 112, 112, 1)',
                          fontSize: 14,
                          fontFamily: 'Roboto',
                        }, // Change label color
                      }}
                    />
                    <VictoryAxis
                      inDependentAxis
                      style={{
                        axis: { stroke: 'black' }, // Adjust x-axis color as needed
                        ticks: { stroke: 'black' },
                        tickLabels: {
                          fill: 'rgba(112, 112, 112, 1)',
                          fontSize: 14,
                          fontFamily: 'Roboto',
                        }, // Change label color
                      }}
                      tickLabelComponent={<VictoryLabel dx={10} />}
                    />
                    <VictoryStack padding={{ left: 20, right: 60 }} offset={20}>
                      {renderBars(transformCostData(financialGraphData))}
                    </VictoryStack>
                  </VictoryChart>
                </Grid>
                <Grid
                  item
                  container
                  justify="flex-start"
                  style={{ margin: '0 0 10px 0' }}
                >
                  {/* Need to return Avg Production Rate from the progress metrics api  */}
                  {/* <Typography>
                    Avg Production Rate
                    <span
                      style={{
                        padding: '3px 4px',
                        borderRadius: 10,
                        margin: '0px 2px',
                        fontSize: '1.1rem',
                        backgroundColor: '#81B734',
                        color: 'white',
                      }}
                    >
                      {'₹20 Lakhs/month' || 'N/A'}
                    </span>
                  </Typography> */}
                </Grid>

                <Grid container item xs={12} justify="space-between">
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={6}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Required <br /> Production Rate
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            color: '#81B734',
                            fontSize: isSmallScreen ? 20 : 30,
                          }}
                        >
                          {currentPresent?.length > 0 ? (
                            `₹${
                              financialProgressMetrics?.requiredProductionRate
                                ? financialProgressMetrics.requiredProductionRate.toLocaleString()
                                : 'N/A'
                            }`
                          ) : projectClassificationList?.length === 0 ? (
                            0
                          ) : (
                            <CircularProgress
                              style={{
                                color: '#21D940',
                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={6}
                    xs={12}
                    className={classes.marginTop}
                    justify="space-around"
                    style={{
                      background: '#F9F9F9',
                      borderRadius: 10,
                      width: '100%',
                      height: '60px',
                    }}
                  >
                    <Grid>
                      <Typography
                        style={{ fontFamily: 'Roboto', fontSize: 13 }}
                      >
                        Actual
                        <br />
                        Production Rate
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          fontFamily: 'Roboto',
                          color: 'rgb(161, 121, 242)',
                          fontSize: isSmallScreen ? 20 : 30,
                        }}
                      >
                        {currentAbsent?.length > 0 ? (
                          `₹${
                            financialProgressMetrics?.actualProductionRate
                              ? financialProgressMetrics.actualProductionRate.toLocaleString()
                              : 'N/A'
                          }`
                        ) : projectClassificationList?.length === 0 ? (
                          0
                        ) : (
                          <CircularProgress
                            style={{
                              color: '#21D940',
                              width: '25px',
                              height: '25px',
                            }}
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
});

export default Dashboard;
//DEPLOY
