import React, { useState, useEffect, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
  makeStyles,
  Tooltip,
  TablePagination,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import defaultProjectImage from '../../assests/Projects Icon.png';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import { getContractors, usersReducer } from 'src/redux/users';
import {
  closeCostCode,
  getListOfBOQ,
  productivityReducer,
  selectProductivityParentProject,
  shortCloseBoq,
  getBoqListV1,
  rejectChangeRequest,
  approveChangeRequest,
} from 'src/redux/productivity';
// import DatePicker, { registerLocale } from "react-datepicker";
// import es from "date-fns/locale/es";
// import EventNoteIcon from "@material-ui/icons/EventNote";
import SearchInput from 'src/components/SearchInput';
import Page from 'src/components/Page';
// import toast from "react-hot-toast";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// registerLocale("es", es);
// import NoteAddIcon from "@material-ui/icons/NoteAdd";
// import { breakString } from "src/App";
import { getNewProductivity } from '.';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import BOQAccordian from './BOQAccordian';
import SOWAccordian from './SOWAccordian';
import toast from 'react-hot-toast';
import BoqHistory from './BoqHistory';
import { Autocomplete } from '@material-ui/lab';
import ShortCloseBoqList from './ShortCloseBoqList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(1),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  button: {
    margin: '0 5px',
    color: '#fff',
    width: '220px',
    '&.active': {
      backgroundColor: '#81B734',
      color: 'white',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: '-9px',
        left: '50%',
        transform: 'translateX(-50%)',
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: '#81B734 transparent transparent transparent',
      },
    },
    '&.inactive': {
      color: '#986FEF',
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  buttonGreenOpacity: {
    color: '#81B734',
    backgroundColor: 'rgba(129, 183, 52, 0.1)',
    textTransform: 'capitalize',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: 'rgba(129, 183, 52, 0.1)',
    },
  },
  buttonRedOpacity: {
    margin: '5px 0px',
    textAlign: 'center',
    backgroundColor: 'rgba(247, 186, 175, 0.8)',
    color: 'rgba(196, 30, 0, 0.8)',
    textTransform: 'capitalize',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: 'rgba(255, 99, 71, 0.8)',
    },
    justifyContent: 'center',
  },
  buttonDisabled: {
    backgroundColor: 'grey',
    textTransform: 'capitalize',
    marginBottom: '5px',
    '&.Mui-disabled': {
      background: '#c0c0c0',
      color: '#fff',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonRed: {
    color: theme.palette.common.white,
    backgroundColor: 'tomato',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    textTransform: 'capitalize',
    padding: '5px 15px',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  marginLeft: { marginLeft: '20px' },
  paddingLeft: {
    paddingLeft: '20px',
  },
  columnStyle: {
    fontSize: '11px',
    color: '#ADBAC3',
  },
  paddingRight: {
    padding: '10px',
    fontSize: '11px',
    color: '#ADBAC3',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: { marginLeft: '0px' },
    paddingLeft: {
      paddingLeft: '0px',
    },
  },
}));

//!D
const ViewBOQList = ({
  setGCListView,
  hideActions = false,
  openTheFieldNote,
  setIsViewBOQ,
  handleEdit,
  scopeList,
  fetchParentPageData,
}) => {
  const [currentProject, setCurrentProject] = useState('');
  const [classifiedProject] = useState('');
  const [LabourContractor, setLabourContractor] = useState(0);
  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    filterBy: 0,
    searchModal: '',
    filterModalBy: 0,
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filteredArray, setFilteredArray] = useState([]);
  const [boqList, setBoqList] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    rowsPerPage: 20,
    count: 100,
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const [curSOWExpanded, setCurSOWExpanded] = useState(-1);
  const [calculations, setCalculations] = useState({
    percentage: { inProcess: true, value: 0 },
    amount: { inProcess: true, value: 0 },
  });
  const [curView, setCurView] = useState('VIEW_BOQ');

  const [selectedBoq, setSelectedBoq] = useState(null);

  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();

  //! SELECTORS
  const { projectClassificationList } = useSelector(projectReducer);
  const {
    boqListGCViewMode,
    loading,
    financialGraphLoading,
    productivitySelectedProject,
    boqListV1,
  } = useSelector(productivityReducer);
  const { contractorList } = useSelector(usersReducer);

  //! LIFECYCLE
  useEffect(() => {
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
    if (projectClassificationList.length > 0) {
      if (!contractorList)
        dispatch(getContractors(projectClassificationList[0]?.projectId));
      // if (!scopeList) dispatch(getScopeList());
      setCurrentProject(
        productivitySelectedProject ?? projectClassificationList[0]
      );
      dispatch(
        getListOfBOQ(
          productivitySelectedProject?.projectId ??
            projectClassificationList[0]?.projectId,
          LabourContractor?.userId,
          paginationData.page + 1,
          paginationData.rowsPerPage
        )
      );
    }
  }, [projectClassificationList]);

  useEffect(() => {
    const fetchV1Boq = () => {
      dispatch(getBoqListV1(currentProject.projectId));
    };
    if (currentProject) {
      fetchV1Boq();
    }
  }, [currentProject]);

  useEffect(() => {
    const calculateBoqGrandTotal = () => {
      setCalculations((prev) => ({
        amount: { value: 0, inProcess: true },
        percentage: { value: 0, inProcess: true },
      }));
      const totalActualAmount = getGrandTotalAmount(boqListGCViewMode);
      setCalculations((prev) => ({
        ...prev,
        amount: { value: totalActualAmount, inProcess: false },
      }));
      const totalPercentage = getGrandTotalPercentage(boqListGCViewMode);
      setCalculations((prev) => ({
        ...prev,
        percentage: { value: totalPercentage, inProcess: false },
      }));
    };
    if (boqListGCViewMode?.length > 0) {
      setBoqList(
        boqListGCViewMode.map((item) => ({ ...item, showMore: false }))
      );
      calculateBoqGrandTotal();
    }
  }, [boqListGCViewMode]);

  const handleChange = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleSOWExpanded = (sowIndex) => {
    setCurSOWExpanded(sowIndex);
  };

  //! METHODS
  const classifyProject = (project) => {
    if (project) {
      dispatch(selectProductivityParentProject(project));

      setCurrentProject(project);
      dispatch(getContractors(project?.projectId));
      setLabourContractor(0);
      dispatch(getListOfBOQ(project?.projectId));
    } else {
      dispatch(selectProductivityParentProject(projectClassificationList[0]));

      setCurrentProject(projectClassificationList[0]);
      dispatch(getContractors(projectClassificationList[0]?.projectId));
      setLabourContractor(0);
      dispatch(getListOfBOQ(projectClassificationList[0]?.projectId));
    }
  };

  const handleLabourContractor = (val) => {
    if (!val) {
      setLabourContractor(0);
      return dispatch(getListOfBOQ(currentProject?.projectId, 0));
    }
    setLabourContractor(val);
    dispatch(getListOfBOQ(currentProject?.projectId, val.userId));
  };

  //! FILTERS
  const search = (e) => {
    const { value } = e.target;
    setFilters({ ...filters, search: value });
    const data = boqListGCViewMode.filter((item) => {
      return Object.values(item)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    setFilteredArray(data);
  };

  const getGrandTotalAmount = useCallback(
    (boqList) => {
      return boqList.reduce((grandTotal, item) => {
        const totalAmountForItem = item.boQs.reduce(
          (totalBoqAmount, subItem) => {
            const totalAmountForSubItem = subItem.titles.reduce(
              (totalTitleAmount, title) => {
                const totalAmountForTitle = title.descriptions.reduce(
                  (total, current) => total + current.amount,
                  0
                );
                return totalTitleAmount + totalAmountForTitle;
              },
              0
            );
            return totalBoqAmount + totalAmountForSubItem;
          },
          0
        );
        return grandTotal + totalAmountForItem;
      }, 0);
    },
    [boqList]
  );

  const getGrandTotalPercentage = useCallback(
    (boqList) => {
      let weightedPercentageSum = 0;
      let totalAmount = 0;

      boqList.forEach((item) => {
        item.boQs.forEach((subItem) => {
          subItem.titles.forEach((title) => {
            title.descriptions.forEach((description) => {
              if (description.amount > 0) {
                weightedPercentageSum +=
                  (description.actualAmount / description.amount) *
                  description.amount;
                totalAmount += description.amount;
              }
            });
          });
        });
      });

      const overallPercentage = (weightedPercentageSum / totalAmount) * 100;
      return overallPercentage;
    },
    [boqList]
  );

  const getValueInLakhsString = (value) => {
    if (value < 100000) {
      return `₹ ${value}`;
    }

    const totalCostInLakhs = value / 100000;

    const formattedTotalCost = totalCostInLakhs.toFixed(1);

    const resultString = `₹ ${formattedTotalCost} Lakhs`;
    return resultString;
  };

  const moveBack = () => {
    setIsViewBOQ(false);
    getNewProductivity();
    fetchParentPageData(productivitySelectedProject);
  };

  const handleBack = () => {
    if (curView !== 'VIEW_BOQ') {
      setCurView(false);
      return;
    } else {
      setIsViewBOQ(false);
      getNewProductivity();
      fetchParentPageData(productivitySelectedProject);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPaginationData({ ...paginationData, page: newPage });

    dispatch(
      getListOfBOQ(
        currentProject.projectId,
        LabourContractor?.userId,
        newPage + 1,
        paginationData.rowsPerPage
      )
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      rowsPerPage: Number(event.target.value),
    });
    dispatch(
      getListOfBOQ(
        currentProject.projectId,
        LabourContractor?.userId,
        paginationData.page + 1,
        Number(event.target.value)
      )
    );
  };

  const openEditProgress = (boq, item) => {
    handleEdit(boq, item);
  };

  const renderBreadCrumbs = () => {
    const history = (
      <Typography color="inherit" style={{ cursor: 'pointer' }}>
        History
      </Typography>
    );
    const shortCloseBoq = (
      <Typography color="inherit" style={{ cursor: 'pointer' }}>
        Short Close BOQ
      </Typography>
    );
    const productivity = (
      <Typography
        color="inherit"
        to="/"
        onClick={moveBack}
        style={{ cursor: 'pointer' }}
      >
        Productivity
      </Typography>
    );
    const view = (
      <Typography
        color="inherit"
        onClick={() => setCurView('VIEW_BOQ')}
        style={{ cursor: 'pointer' }}
      >
        View BOQ
      </Typography>
    );

    if (curView === 'HISTORY') {
      return (
        <Breadcrumbs>
          {productivity}
          {view}
          {history}
        </Breadcrumbs>
      );
    }
    if (curView === 'SHORT_CLOSE_BOQ_LIST') {
      return (
        <Breadcrumbs>
          {productivity}
          {view}
          {shortCloseBoq}
        </Breadcrumbs>
      );
    }
    return (
      <Breadcrumbs>
        {productivity}
        {view}
      </Breadcrumbs>
    );
  };

  const closeItem = async (boqId) => {
    let resp = await dispatch(closeCostCode({ contractorBOQId: boqId }));
    if (!resp.data.success) {
      toast.error(resp.data.errors[0]);
    } else {
      toast.success('Cost Code closed successfully');
      dispatch(getListOfBOQ(currentProject?.projectId));
    }
  };

  const handleReject = async (request) => {
    const response = await dispatch(rejectChangeRequest(request));
    if (!response.data.success) {
      toast.error(response.data.errors[0]);
    } else {
      toast.success('Change request has been successfully rejected');
      dispatch(getListOfBOQ(currentProject?.projectId));
    }
  };

  const handleApprove = async (request) => {
    const response = await dispatch(approveChangeRequest(request));
    if (!response.data.success) {
      toast.error(response.data.errors[0]);
    } else {
      toast.success('Change request has been successfully approved');
      dispatch(getListOfBOQ(currentProject?.projectId));
    }
  };

  const renderTabSelector = () => {
    let tabScopeOfWorkStatus = 'inactive';
    let tabBOQsStatus = 'inactive';

    if (curView === 'VIEW_BOQ') {
      tabScopeOfWorkStatus = 'active';
    }
    if (curView === 'SHORT_CLOSE_BOQ_LIST') {
      tabBOQsStatus = 'active';
    }

    const scopeOfWorkClasses = `${classes.button} ${tabScopeOfWorkStatus}`;
    const BOQsClasses = `${classes.button} ${tabBOQsStatus}`;

    return (
      <Grid
        item
        xs={12}
        style={{
          padding: '20px 10px',
          borderBottom: '1px solid rgba(1,1,1,0.1)',
        }}
      >
        <Button
          className={scopeOfWorkClasses}
          onClick={() => setCurView('VIEW_BOQ')}
          style={{ padding: '5px 50px' }}
        >
          Scope Of Work
        </Button>
        <Button
          className={BOQsClasses}
          onClick={() => setCurView('SHORT_CLOSE_BOQ_LIST')}
          style={{ padding: '5px 50px' }}
        >
          BOQs
        </Button>
      </Grid>
    );
  };
  const historyStep = (
    <BoqHistory
      handleEdit={(boq) => {
        console.log(boq);
      }}
      scopeList={scopeList}
      fetchParentPageData={classifyProject}
    />
  );

  const shortCloseBoqList = (
    <ShortCloseBoqList
      currentProject={currentProject}
      LabourContractor={LabourContractor}
    />
  );

  const viewBoq = (
    <Grid
      container
      // maxWidth={false}
      style={{
        background: 'white',
        borderRadius: '10px',
        // padding: "1rem",
      }}
    >
      <Grid item container>
        <Grid item container style={{ overflow: 'hidden' }}>
          {!boqListGCViewMode?.length ? (
            <TableBody>
              <div style={{ padding: '1rem 0 1rem 10px' }}>No Data Found!</div>
            </TableBody>
          ) : filteredArray?.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnStyle} align="left">
                    BOQ ID
                  </TableCell>

                  {/* Add more table header cells as needed */}
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: 'hidden' }}>
                {filteredArray?.map((item, index) => (
                  <Grid
                    container
                    alignItems="center"
                    key={item.boqId}
                    // style={{ overflow: "hidden" }}
                  >
                    <Grid item xs={1}>
                      <TableCell
                        align="left"
                        className={classes.columnStyle}
                        style={{ border: 'none' }}
                      >
                        <Typography align="left" style={{ fontSize: '12px' }}>
                          {item?.boqId ?? 'N/A'}
                        </Typography>
                      </TableCell>
                    </Grid>
                    <Grid
                      xs={11}
                      item
                      style={{
                        margin: '10px 0',
                      }}
                    >
                      {item?.boQs?.map((subItem, subIndex) => (
                        <Accordion
                          key={subIndex}
                          // style={{ width: "100%" }}
                          style={{
                            overflowY: 'auto', // Enable vertical scrolling if content exceeds the height
                            width: '100%',
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Grid container justify="space-between">
                              <Grid item>
                                <Typography
                                  align="left"
                                  style={{
                                    fontSize: '12px',
                                  }}
                                >
                                  {`BOQ - ${index + 1}`}
                                </Typography>
                              </Grid>
                              <Grid item container xs={4} justify="flex-end">
                                <Typography
                                  variant="body1"
                                  style={{
                                    boxShadow: '1px 1px 5px 1px',
                                    padding: '2px 5px',
                                    fontSize: '12px',
                                    marginRight: '7px',
                                  }}
                                >
                                  INR{' '}
                                  {subItem?.titles
                                    ?.map((ii) =>
                                      ii.descriptions?.reduce(
                                        (total, current) =>
                                          total + current.amount,
                                        0
                                      )
                                    )
                                    ?.reduce(
                                      (total, current) => total + current,
                                      0
                                    )
                                    .toLocaleString()}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  style={{
                                    boxShadow: '1px 1px 5px 1px',
                                    padding: '2px 5px',
                                    fontSize: '12px',
                                  }}
                                >
                                  {subItem?.titles
                                    .reduce(
                                      (total, current) =>
                                        total +
                                          (current.totalAmount /
                                            current.totalAcutalAmount) *
                                            100 ===
                                        Infinity
                                          ? 0
                                          : (current.totalAmount /
                                              current.totalAcutalAmount) *
                                            100,
                                      0
                                    )

                                    .toLocaleString()}{' '}
                                  %
                                </Typography>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Table
                              className={classes.table}
                              size="small"
                              aria-label="DAR Table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    className={classes.columnStyle}
                                    align="left"
                                  >
                                    SCOPE OF WORK
                                  </TableCell>
                                  {/* Add more table header cells as needed */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {/* Iterate over titles array and create accordion for each */}

                                <Accordion
                                  style={{
                                    width: '100%',
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Grid container justify="space-between">
                                      <Grid item>
                                        <Typography
                                          align="left"
                                          style={{
                                            fontSize: '12px',
                                          }}
                                        >
                                          {subItem.scopeOfWork ?? 'N/A'}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        xs={4}
                                        justify="flex-end"
                                      >
                                        <Typography
                                          variant="body1"
                                          style={{
                                            boxShadow: '1px 1px 5px 1px',
                                            padding: '2px 5px',
                                            fontSize: '12px',
                                            marginRight: '7px',
                                          }}
                                        >
                                          INR{' '}
                                          {subItem?.titles
                                            ?.map((ii) =>
                                              ii.descriptions?.reduce(
                                                (total, current) =>
                                                  total + current.amount,
                                                0
                                              )
                                            )
                                            ?.reduce(
                                              (total, current) =>
                                                total + current,
                                              0
                                            )
                                            .toLocaleString()}
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          style={{
                                            boxShadow: '1px 1px 5px 1px',
                                            padding: '2px 5px',
                                            fontSize: '12px',
                                          }}
                                        >
                                          {subItem?.titles
                                            .reduce(
                                              (total, current) =>
                                                total +
                                                  (current.totalAmount /
                                                    current.totalAcutalAmount) *
                                                    100 ===
                                                Infinity
                                                  ? 0
                                                  : (current.totalAmount /
                                                      current.totalAcutalAmount) *
                                                    100,
                                              0
                                            )

                                            .toLocaleString()}{' '}
                                          %
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container>
                                      <Table
                                        className={classes.table}
                                        size="small"
                                        aria-label="DAR Table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              className={classes.columnStyle}
                                              align="left"
                                            >
                                              TITLE
                                            </TableCell>
                                            {/* Add more table header cells as needed */}
                                          </TableRow>
                                        </TableHead>
                                      </Table>
                                      {subItem?.titles?.map(
                                        (titleItem, titleIndex) => (
                                          <Grid item xs={12}>
                                            <Accordion key={titleIndex + 123}>
                                              <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                              >
                                                <Grid container>
                                                  <Grid item>
                                                    <Typography
                                                      align="left"
                                                      style={{
                                                        width: '100%',
                                                        fontSize: '14px',
                                                        fontWeight: 700,
                                                        textTransform:
                                                          'capitalize',
                                                      }}
                                                    >
                                                      {titleItem.title ?? 'N/A'}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                <Table>
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell
                                                        className={
                                                          classes.columnStyle
                                                        }
                                                        align="left"
                                                      >
                                                        DESCRIPTION
                                                      </TableCell>
                                                      <TableCell
                                                        className={
                                                          classes.columnStyle
                                                        }
                                                        align="left"
                                                      >
                                                        UNIT
                                                      </TableCell>
                                                      <TableCell
                                                        className={
                                                          classes.columnStyle
                                                        }
                                                        align="left"
                                                      >
                                                        QUANTITY
                                                      </TableCell>
                                                      <TableCell
                                                        className={
                                                          classes.columnStyle
                                                        }
                                                        align="left"
                                                      >
                                                        AMOUNT
                                                      </TableCell>
                                                      <TableCell
                                                        className={
                                                          classes.columnStyle
                                                        }
                                                        align="left"
                                                      >
                                                        RATE
                                                      </TableCell>
                                                      <TableCell
                                                        className={
                                                          classes.columnStyle
                                                        }
                                                        align="left"
                                                      >
                                                        ACTUAL QUANTITY
                                                      </TableCell>

                                                      <TableCell
                                                        className={
                                                          classes.columnStyle
                                                        }
                                                        align="left"
                                                      >
                                                        ACTUAL AMOUNT
                                                      </TableCell>
                                                      <TableCell
                                                        className={
                                                          classes.columnStyle
                                                        }
                                                        align="left"
                                                      >
                                                        AMOUNT
                                                      </TableCell>
                                                      <TableCell
                                                        className={
                                                          classes.columnStyle
                                                        }
                                                        align="left"
                                                      >
                                                        PERCENTAGE
                                                      </TableCell>
                                                      <TableCell
                                                        className={
                                                          classes.columnStyle
                                                        }
                                                        align="left"
                                                      >
                                                        WORK ORDER #
                                                      </TableCell>
                                                      <TableCell
                                                        className={
                                                          classes.columnStyle
                                                        }
                                                        align="left"
                                                      >
                                                        BOQ NUMBER
                                                      </TableCell>
                                                      {/* Add more table header cells as needed */}
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    {/* Iterate over descriptions array and render table rows */}
                                                    {titleItem?.descriptions?.map(
                                                      (desc, descIndex) => (
                                                        <TableRow
                                                          key={descIndex}
                                                        >
                                                          <TableCell
                                                            align="left"
                                                            className={
                                                              classes.columnStyle
                                                            }
                                                            style={{
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            <Typography>
                                                              {desc.description ??
                                                                'N/A'}
                                                            </Typography>
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            className={
                                                              classes.columnStyle
                                                            }
                                                          >
                                                            {desc.unitCode ??
                                                              'N/A'}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            className={
                                                              classes.columnStyle
                                                            }
                                                          >
                                                            {desc.quantity ??
                                                              'N/A'}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            className={
                                                              classes.columnStyle
                                                            }
                                                          >
                                                            {desc.amount ??
                                                              'N/A'}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            className={
                                                              classes.columnStyle
                                                            }
                                                          >
                                                            {desc.rate ?? 'N/A'}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            className={
                                                              classes.columnStyle
                                                            }
                                                          >
                                                            {desc.actualQuantity ??
                                                              'N/A'}
                                                          </TableCell>

                                                          <TableCell
                                                            align="left"
                                                            className={
                                                              classes.columnStyle
                                                            }
                                                          >
                                                            {desc.actualAmount ??
                                                              'N/A'}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            className={
                                                              classes.columnStyle
                                                            }
                                                          >
                                                            {desc.amount ??
                                                              'N/A'}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            className={
                                                              classes.columnStyle
                                                            }
                                                          >
                                                            {desc.percentage +
                                                              '%'}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            className={
                                                              classes.columnStyle
                                                            }
                                                          >
                                                            {desc?.workOrderNumber ??
                                                              'N/A'}
                                                          </TableCell>
                                                          <TableCell
                                                            align="left"
                                                            className={
                                                              classes.columnStyle
                                                            }
                                                          >
                                                            {desc?.boqNumber ??
                                                              'N/A'}
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )}
                                                  </TableBody>
                                                </Table>
                                              </AccordionDetails>
                                            </Accordion>
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </TableBody>
                            </Table>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </TableBody>
            </Table>
          ) : filters.search !== '' || filteredArray === null ? (
            <TableRow>
              <div style={{ padding: '1rem 0 1rem 10px' }}>
                No Search Found!
              </div>
            </TableRow>
          ) : (
            <Grid container item>
              <Grid item container>
                {boqList?.map((sowItem, index) => {
                  return (
                    <SOWAccordian
                      classes={classes}
                      sowItem={sowItem}
                      index={index}
                      scopeList={scopeList}
                      handleSOWExpanded={handleSOWExpanded}
                      expandedSOW={curSOWExpanded}
                      contractorId={LabourContractor?.userId}
                      curProject={currentProject}
                      handleEdit={openEditProgress}
                      closeItem={closeItem}
                      handleReject={handleReject}
                      handleApprove={handleApprove}
                    />
                  );
                })}
              </Grid>
              <Grid container item justify="flex-end" alignItems="center">
                {/* <TablePagination
                      </Grid>
                    ))}
                  </TableBody>
                </Table>
              ) : filters.search !== '' || filteredArray === null ? (
                <TableRow>
                  <div style={{ padding: '1rem 0 1rem 10px' }}>
                    No Search Found!
                  </div>
                </TableRow>
              ) : (
                <Grid container item>
                  <Grid item container>
                    {boqList?.map((sowItem, index) => {
                      return (
                        <SOWAccordian
                          classes={classes}
                          sowItem={sowItem}
                          index={index}
                          scopeList={scopeList}
                          handleSOWExpanded={handleSOWExpanded}
                          expandedSOW={curSOWExpanded}
                          contractorId={LabourContractor?.userId}
                          curProject={currentProject}
                          handleEdit={openEditProgress}
                          closeItem={closeItem}
                        />
                      );
                    })}
                  </Grid>
                  <Grid container item justify="flex-end" alignItems="center">
                    {/* <TablePagination
                      component="div"
                      count={1000}
                      rowsPerPageOptions={[
                        5,
                        10,
                        20,
                        { label: 'All', value: boqList?.length },
                      ]}
                      rowsPerPage={paginationData?.rowsPerPage}
                      page={paginationData?.page}
                      colSpan={3}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}

                <Grid
                  item
                  style={{ maxWidth: 260, padding: '10px 0px' }}
                  container
                >
                  <Grid item container xs={8}>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        width: '100%',
                      }}
                    >
                      {calculations.amount.inProcess ? (
                        <>Loading ...</>
                      ) : (
                        getValueInLakhsString(calculations.amount.value)
                      )}
                    </Typography>
                    <Typography
                      style={{
                        color: '#ADBAC3',
                        fontSize: 12,
                      }}
                    >
                      Total Actual Amount
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={6} container>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            width: '100%',
                          }}
                        >
                          {calculations.percentage.inProcess ? (
                            <>Loading ...</>
                          ) : (
                            `${calculations.percentage.value.toFixed(2)}%`
                          )}
                        </Typography>
                        <Typography
                          style={{
                            color: '#ADBAC3',
                            fontSize: 10,
                          }}
                        >
                          % Percentage
                        </Typography>
                      </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const renderView = () => {
    if (curView === 'HISTORY') {
      return historyStep;
    }
    if (curView === 'SHORT_CLOSE_BOQ_LIST') {
      return shortCloseBoqList;
    }
    return viewBoq;
  };

  return (
    <Page
      className={classes.root}
      id="zoom-level"
      title="Productivity"
      style={{ zoom: '90%' }}
    >
      <div style={{ marginLeft: '10px' }}>{renderBreadCrumbs()}</div>
      <Container maxWidth={true}>
        <Grid item container justify="space-between" alignItems="center">
          <Grid item>
            <Button
              style={{ width: '20px', marginBottom: '10px' }}
              onClick={handleBack}
            >
              <ArrowBackIcon style={{ color: '#000' }} />
            </Button>
          </Grid>
          <Grid item>
            {curView === 'VIEW_BOQ' && (
              <Button
                size="small"
                className={classes.buttonGreen}
                style={{ marginBottom: '10px', padding: '5px 15px' }}
                onClick={() => setCurView('HISTORY')}
              >
                History
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          // maxWidth={false}
          style={{
            background: 'white',
            borderRadius: '10px',
            // padding: "1rem",
          }}
        >
          {renderTabSelector()}
          <Grid
            container
            item
            alignItems="flex-end"
            style={{
              padding: '1rem',
            }}
          >
            <Grid item container md={3} xs={12}>
              {/* <Grid item container alignItems="center">
                <Typography variant="subtitle">Select Project</Typography>
              </Grid> */}
              <Grid item container alignItems="end">
                <Grid item>
                  <FilterProjectComponent
                    projectClassificationList={projectClassificationList}
                    src={
                      !classifiedProject[0]?.url
                        ? defaultProjectImage
                        : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                    }
                    value={currentProject}
                    onChange={classifyProject}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={2} xs={12}>
              <FilterByLabourContractorComponent
                value={LabourContractor}
                onChange={handleLabourContractor}
                setInitialUser={(user) => setLabourContractor(user)}
                currentProject={projectClassificationList[0]}
              />
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            style={{ marginTop: '1rem' }}
            alignItems="center"
            className="input-container-nav"
            justify="flex-end"
          >
            <Grid
              md={5}
              xs={12}
              item
              container
              justify="flex-end"
              alignItems="center"
            >
              <Grid item xs={2}>
                <small className="font14">Search:</small>
              </Grid>
              <Grid item md={10} xs={8} style={{ position: 'relative' }}>
                <SearchInput search={filters.search} handleSearch={search} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {renderView()}
      </Container>
    </Page>
  );
};

export default ViewBOQList;
