import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  MenuItem,
  CircularProgress,
  Input,
  Slider,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  buldAddWorkers,
  createWorker,
  setWorkerForDetail,
  workersReducer,
} from 'src/redux/workers';
import { useToasts } from 'react-toast-notifications';
import { Link, useNavigate } from 'react-router-dom';
// import ImagePlaceHolder from 'src/assests/ImagePlaceHolder.png';
import { getJobs, getSkills, jobReducer } from 'src/redux/jobs';
import PhoneInput from 'react-phone-input-2';
import usePlacesAutocomplete from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import moment from 'moment';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { read, utils } from 'xlsx';
import toast, { Toaster } from 'react-hot-toast';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { getJsDateFromExcel } from 'excel-date-to-js';
import AvatarEditor from 'react-avatar-editor';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
  },
  input: {
    display: 'none',
  },

  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      color: 'rgba(126, 131, 134, 0.7)',
    },
  },
  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
  },
  input1: {
    display: 'none',
  },
  ImagePlaceHolder: {
    cursor: 'pointer',
  },
  inputFieldsContainer: {
    paddingRight: '1rem',
    // marginTop: 20,
  },

  bankAccountNumberField: {
    paddingRight: '1rem',
  },

  commonStyleInput: {
    paddingBottom: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    inputFieldsContainer: {
      paddingRight: '0px',
    },
    bankAccountNumberField: {
      paddingRight: '0px',
      paddingBottom: '10px',
    },
  },
}));
const CreateWorker = () => {
  const [preview, setPreview] = useState(null);
  const [adharCard, setAdharCard] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [subSkillFound, setSubSkillFound] = useState(false);
  const [listOfJobs, setListOfJobs] = useState(null);
  const subSkills = [
    'Shuttering Carpenter',
    'Staging',
    'Scaffolding Rigger',
    'Barbending and Reinforcement',
    'Mason',
  ];
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [aspect, setAspect] = useState(16 / 9);
  const [rotation, setRotation] = useState(0);
  const [croppedImage, setCroppedImage] = useState('');
  const [zoomProfile, setZoomProfile] = useState(0.5);
  const [rotateProfile, setRotateProfile] = useState(0);
  const [skillLevelArray, setSkillLevelArray] = useState([
    'Supervisor',
    'Skilled',
    'Helper',
  ]);
  const [subSkill, setSubSkill] = useState([
    'Bobcat',
    'Bulldozer',
    'Tower Crane',
    'Tough Rider',
    'Signalman',
    'Concrete Mixer',
    'Forklift',
    'Tractor',
    'JCB',
    'RSP -Rope Suspended Platform',
    'Driver',
    'Hoist Operator',
    'Sewage Treatment Operator',
  ]);

  const fileInputRef = useRef(null);
  const previewRef = useRef(null);
  const editorRef = useRef();

  const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 0.9,
      label: '0.9',
    },
    {
      value: 0.8,
      label: '0.8',
    },
    {
      value: 0.7,
      label: '0.7',
    },
    {
      value: 0.6,
      label: '0.6',
    },
    {
      value: 0.5,
      label: '0.5',
    },
    {
      value: 0.4,
      label: '0.4',
    },
    {
      value: 0.3,
      label: '0.3',
    },
    {
      value: 0.2,
      label: '0.2',
    },
    {
      value: 0.1,
      label: '0.1',
    },
  ];

  //! INSTANCES
  const classes = useStyles();
  const formData = new FormData();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  // const user = JSON.parse(localStorage.getItem('userDetails'));
  //! SELECTORS
  const { loading, bulkLoading } = useSelector(workersReducer);
  const { skillsList, jobsList } = useSelector(jobReducer);
  const { projectClassificationList } = useSelector(projectReducer);

  const convertBaseToBinary = (canvas) => {
    const dataURL = canvas.toDataURL(); // Get the base64-encoded image data

    // Convert the base64 data to binary
    const base64Data = dataURL.split(',')[1]; // Remove the "data:image/png;base64," prefix
    const binaryData = atob(base64Data);

    // Create a Uint8Array to hold the binary data
    const length = binaryData.length;
    const arrayBuffer = new ArrayBuffer(length);
    const uintArray = new Uint8Array(arrayBuffer);

    // Fill the Uint8Array with the binary data
    for (let i = 0; i < length; i++) {
      uintArray[i] = binaryData.charCodeAt(i);
    }

    return uintArray;
  };

  //! METHODS
  //* 1. FORMIK
  const validationSchema = yup.object({
    fullName: yup.string().required('Full name is required'),
    address: yup.string().required('Address is required'),
    // bankName: yup.string().required('Bank name is required'),
    // bankAccountNumber: yup.string().required('Bank A/C is required'),
    // IFSCCode: yup.str ing().required('IFSC code is required'),
    jobId: yup.string().required('Job is required'),
  });
  var formik = useFormik({
    initialValues: {
      fullName: '',
      address: '',
      identity: '',
      phoneNumber: '',
      skillId: '',
      healthCard: true,
      policeVerification: true,
      IFSCCode: '',
      bankName: '',
      bankAccountNumber: '',
      panCard: '',
      adharCard: '',
      jobId: '',
      skillTypeId: '',
      gender: 'Male',
      dateOfBirth: '1990-01-01',
      identityNumber: '',
      // contractorId: '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const { adharCard, panCard, identity, ...rest } = values;
      const objectKeys = Object.keys(rest);
      const objectValues = Object.values(rest);
      function generateIndianPhoneNumber() {
        // Indian mobile numbers typically start with 7, 8, or 9 and are 10 digits long.
        //const firstDigit = Math.floor(Math.random() * 3) + 7; // Generates 7, 8, or 9
        const firstDigit = 0; // Generates 7, 8, or 9
        const remainingDigits =
          Math.floor(Math.random() * 9000000000) + 100000000; // Generates a 9-digit number
        return `+91${firstDigit}${remainingDigits}`;
      }

      objectKeys.forEach((key, index) => {
        //console.log('Key', key);
        //console.log('phoneNumber', objectValues[index]);

        if (key === 'phoneNumber' && objectValues[index] === '') {
          formData.append('phoneNumber', generateIndianPhoneNumber());
        }
        formData.append(key, objectValues[index]);
      });

      const canvas = editorRef?.current?.getImageScaledToCanvas();
      const formDataProfile = new FormData();

      canvas?.toBlob((blob) => {
        if (blob) {
          // Create a FormData object to send the file to the server
          formDataProfile.append('file', blob, 'image.jpg');
          // formDataProfile = blob;
        }
      }, 'image/jpeg');

      // return;
      let resp = await dispatch(
        createWorker(formData, adharCard, panCard, formDataProfile) //indetity
      );
      if (resp?.status === 200) {
        setTimeout(() => {
          addToast('Worker Created', {
            appearance: 'success',
            autoDismiss: true,
          });
          formik.resetForm();
          navigate('..');
        }, [2000]);
      } else if (resp?.data?.errors) {
        addToast('There are some validation error(s)', {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        addToast(resp?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
  });
  //! LIFE CYCLE
  useEffect(() => {
    if (!skillsList) {
      dispatch(getSkills());
    }
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
    if (!jobsList) {
      dispatch(getJobs());
    }
    // if (!usersList) {
    // 	dispatch(getUsers());
    // }
  }, []);

  useEffect(() => {
    if (formik.values.skillId) {
      let found = skillsList?.filter(
        (skill) => skill.skillId === formik.values.skillId
      );
      let isFound = subSkills?.filter((sk) => sk === found[0]?.name);
      if (isFound.length > 0) {
        setSubSkillFound(true);
      } else {
        setSubSkillFound(false);
      }
    }
  }, [formik.values.skillId]);

  const {
    // ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {});

  const handleImage = (e) => {
    // let selectedImage = e.target.files[0];

    if (e.target.files.length) {
      setPreview(URL?.createObjectURL(e.target.files[0]));
      formik.setFieldValue('identity', e.target.files[0], true);
      setRotation(0); // Reset rotation angle
    }
  };
  const handleAdharCard = (e) => {
    // let selectedImage = e.target.files[0];
    setAdharCard(URL.createObjectURL(e.target.files[0]));
    formik.setFieldValue('adharCard', e.target.files[0], true);
  };
  const handlePanCard = (e) => {
    // let selectedImage = e.target.files[0];
    setPanCard(URL.createObjectURL(e.target.files[0]));
    formik.setFieldValue('panCard', e.target.files[0], true);
  };

  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = (main_text, secondary_text) => () => {
    setValue(`${main_text},${secondary_text}`, false);
    clearSuggestions();
    formik.setFieldValue('address', `${main_text},${secondary_text}`, true);
    // Get latitude and longitude via utility functions
  };

  // const renderSuggestions = () =>
  //   data.map((suggestion) => {
  //     const {
  //       place_id,
  //       structured_formatting: { main_text, secondary_text },
  //     } = suggestion;

  //     return (
  //       <li
  //         key={place_id}
  //         onClick={handleSelect(main_text, secondary_text)}
  //         style={{
  //           cursor: "pointer",
  //           padding: "5px",
  //           borderBottom: "1px solid #222",
  //           fontFamily: "Roboto",
  //         }}
  //       >
  //         <strong>{main_text}</strong> <small>{secondary_text}</small>
  //       </li>
  //     );
  //   });

  const handleProjectId = (e) => {
    if (e.target.value === '') {
      setProjectId('');
      return setListOfJobs(jobsList);
    }

    const projectId = parseInt(e.target.value);
    setProjectId(projectId);
    setListOfJobs(
      jobsList?.filter((job) => job.projectId === projectId && job)
    );
  };

  const handleFileUpload = (event) => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Perform further actions with the selected file
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      const headers = jsonData?.shift(); // Remove the header row
      const jsonDataObjects = jsonData
        ?.filter((item, index) => item?.length > 0)
        ?.map((row) => {
          const obj = {};
          if (headers?.length) {
            headers
              ?.filter((item) => item?.length > 0)
              ?.forEach((header, index) => {
                console.log('header', header);
                obj[header] = [
                  'identityNumber',
                  'bankAccountNumber',
                  'ifscCode',
                ]?.includes(header)
                  ? row[index]?.toString()
                  : (obj[header] = ['dateOfBirth']?.includes(header)
                      ? getJsDateFromExcel(row[index]?.toString())
                      : (header === 'isVerified' ||
                          header === 'healthCard' ||
                          header === 'policeVerification' ||
                          header === 'receiveJob') &&
                        row[index] === 0
                      ? false
                      : (header === 'isVerified' ||
                          header === 'healthCard' ||
                          header === 'policeVerification' ||
                          header === 'receiveJob') &&
                        row[index] === 1
                      ? true
                      : header === 'phoneNumber' &&
                        !String(row[index]).startsWith('+91') &&
                        !String(row[index]).startsWith('91')
                      ? row[index] === undefined
                        ? ''
                        : '+91' + row[index]
                      : header === 'phoneNumber' &&
                        String(row[index]).startsWith('91')
                      ? '+' + row[index]
                      : row[index]);
              });
            // obj.workerId = 0;
            // obj.receiveJob = false;
            // obj.dateOfBirth = "2023-05-21T22:48:40.735Z";
          }
          return obj;
        });

      let resp = await dispatch(buldAddWorkers(jsonDataObjects));
      if (resp?.status === 200) {
        console.log('check', resp);

        if (resp?.data[0]?.fullName) {
          setTimeout(() => {
            navigate(-1);
          }, 5000);
          return toast.success('Worker uploaded successfully in bulk');
        }
        resp.data?.forEach((item) =>
          toast.error(item, {
            duration: Infinity, // Make the toast persistent until manually closed
          })
        );
        setTimeout(() => {
          navigate(-1);
        }, 40000);
      } else if (resp?.status === 400) {
        toast.error(resp.data?.error);
      } else {
        toast.error(resp.data?.title);
      }
      // Do something with the extracted data
    };

    reader?.readAsArrayBuffer(file);
  };

  return (
    <Grid container>
      {loading && (
        <div style={{ position: 'absolute', right: '50%', top: '50%' }}>
          <CircularProgress />
        </div>
      )}
      <div style={{ opacity: loading ? 0.4 : 1 }}>
        <Grid container alignItems="center" justify="space-between">
          <Grid
            item
            xs={2}
            style={{ paddingLeft: '2rem', fontWeight: 700, fontSize: '16px' }}
          >
            New Worker
          </Grid>
          <Grid
            item
            xs={5}
            container
            justify="flex-end"
            // xs={12}
            // md={4}
            style={{
              marginRight: '1rem',
            }}
          >
            <Button
              startIcon={<SystemUpdateAltIcon />}
              className={classes.buttonGreen}
              size="small"
              variant="contained"
              style={{
                padding: '8px',
                fontSize: '12px',
                textTransform: 'capitalize',
              }}
            >
              <a
                href="https://docs.google.com/spreadsheets/d/1puNwNj9Ndgm1s9sxGNy-PKD-EIRatdhNYskF8lBfWF4/edit?pli=1#gid=0"
                // download
                target="_blank"
                style={{ color: 'white' }}
              >
                {' '}
                Excel File Format
              </a>
            </Button>
            <Input
              type="file"
              inputRef={fileInputRef}
              accept=".xlsx, application/vnd.ms-excel"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button
              onClick={handleFileUpload}
              startIcon={
                bulkLoading ? (
                  <CircularProgress size="20px" style={{ color: '#ddd' }} />
                ) : (
                  <CloudUploadIcon />
                )
              }
              className={classes.buttonGreen}
              size="small"
              variant="contained"
              style={{
                padding: '8px',
                fontSize: '12px',
                textTransform: 'capitalize',
                marginLeft: '10px',
              }}
            >
              Bulk Import Workers
            </Button>
          </Grid>
        </Grid>

        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            item
            xs={12}
            justify="space-between"
            alignItems="flex-start"
            style={{ paddingTop: '1rem' }}
          >
            <Grid
              item
              md={4}
              xs={11}
              style={{ paddingLeft: '2rem' }}
              container
              // justify='center'
              direction="column"
              // alignItems='center'
            >
              {/* Profile */}
              <Grid item>
                {!preview && (
                  <input
                    accept="image/*"
                    className={classes.input1}
                    id="icon-button-file"
                    type="file"
                    onChange={handleImage}
                  />
                )}
                <label
                  htmlFor="icon-button-file"
                  className={classes.ImagePlaceHolder}
                >
                  {preview ? (
                    <>
                      <div>
                        <AvatarEditor
                          ref={editorRef}
                          image={preview}
                          width={250}
                          height={250}
                          border={50}
                          color={[255, 255, 255, 0.6]} // Background color, in RGBA format
                          scale={zoomProfile} // Zoom level
                          rotate={rotateProfile} // Initial rotation angle
                        />

                        <div style={{ width: '250px', height: '250px' }}>
                          <Typography id="discrete-slider" gutterBottom>
                            Zoom
                          </Typography>
                          <Slider
                            defaultValue={zoomProfile}
                            // getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider"
                            onChange={(e, value) => setZoomProfile(value)}
                            valueLabelDisplay="auto"
                            step={0.1}
                            // marks
                            min={0.1}
                            max={1}
                            marks={marks}
                          />
                          <Typography id="discrete-slider" gutterBottom>
                            Rotate
                          </Typography>
                          <Button
                            variant="outlined"
                            onClick={() => setRotateProfile(rotateProfile + 1)}
                            size="small"
                            style={{ marginRight: '10px' }}
                          >
                            +
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => setRotateProfile(rotateProfile - 1)}
                            size="small"
                          >
                            -
                          </Button>
                          <Button
                            variant="outlined"
                            // onClick={() => setRotateProfile(rotateProfile - 1)}
                            size="small"
                            style={{ marginTop: '10px', padding: '5px' }}
                          >
                            <input
                              accept="image/*"
                              // className={classes.input1}
                              // id="icon-button-file"
                              type="file"
                              onChange={handleImage}
                            />
                          </Button>
                        </div>
                      </div>
                      {/* <img
                        src={preview}
                        ref={previewRef}
                        alt="project prev"
                        style={{
                          width: "300px",
                          height: "250px",
                          borderRadius: "10px",
                          // transform: `rotate(${rotation}deg)`, // Apply rotation using CSS transform

                          // border: Boolean(formik.errors.image) && '1px solid red',
                        }}
                      /> */}
                    </>
                  ) : (
                    <Grid
                      container
                      style={{
                        width: '300px',
                        height: '250px',
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        border: '1px solid #ddd',
                        marginBottom: '10px',
                      }}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        justify="center"
                        alignItems="center"
                        direction="column"
                      >
                        <Grid item>
                          <PersonAddOutlinedIcon
                            style={{
                              color: 'RGB(183, 194, 202)',
                              width: '50px',
                              height: '50px',
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          style={{
                            color: 'RGB(151, 196, 87)',
                            fontSize: '12px',
                          }}
                        >
                          +{' '}
                          <span style={{ textDecoration: 'underline' }}>
                            UPLOAD PROFILE PICTURE
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </label>
                <p
                  style={{
                    margin: '4px 14px 0px',
                    color: 'red',
                    fontSize: '12px',
                    fontWeight: '0',
                  }}
                >
                  {' '}
                  {formik.touched.image && formik.errors.image}
                </p>
              </Grid>

              {/* Adhar Card */}
              <Grid item>
                <input
                  accept="image/*"
                  className={classes.input1}
                  id="icon-button-file1"
                  type="file"
                  onChange={handleAdharCard}
                />
                <label
                  htmlFor="icon-button-file1"
                  className={classes.ImagePlaceHolder}
                >
                  {adharCard ? (
                    <img
                      src={adharCard}
                      alt="Adhar Card Preview"
                      style={{
                        width: '300px',
                        height: '150px',
                        borderRadius: '10px',
                        // border: Boolean(formik.errors.image) && '1px solid red',
                      }}
                    />
                  ) : (
                    <Grid
                      container
                      style={{
                        width: '300px',
                        height: '150px',
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        border: '1px solid #ddd',
                        marginBottom: '10px',
                      }}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        justify="center"
                        alignItems="center"
                        direction="column"
                      >
                        <Grid item>
                          <PanoramaOutlinedIcon
                            style={{
                              color: 'RGB(183, 194, 202)',
                              width: '50px',
                              height: '50px',
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          style={{
                            color: 'RGB(151, 196, 87)',
                            fontSize: '12px',
                          }}
                        >
                          +{' '}
                          <span style={{ textDecoration: 'underline' }}>
                            UPLOAD ADHAR CARD
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </label>
                <p
                  style={{
                    margin: '4px 14px 0px',
                    color: 'red',
                    fontSize: '12px',
                    fontWeight: '0',
                  }}
                >
                  {' '}
                  {formik.touched.image && formik.errors.image}
                </p>
              </Grid>
              {/* Pan card */}
              {/* <Grid item>
								<input
									accept='image/*'
									className={classes.input1}
									id='icon-button-file2'
									type='file'
									onChange={handlePanCard}
								/>
								<label
									htmlFor='icon-button-file2'
									className={classes.ImagePlaceHolder}>
									{panCard ? (
										<img
											src={panCard}
											alt='Pan card'
											style={{
												width: '300px',
												height: '150px',
												borderRadius: '10px',
												// border: Boolean(formik.errors.image) && '1px solid red',
											}}
										/>
									) : (
										<Grid
											container
											style={{
												width: '300px',
												height: '150px',
												borderRadius: '10px',
												backgroundColor: 'white',
												border: '1px solid #ddd',
												marginBottom: '10px',
											}}
											justify='center'
											alignItems='center'>
											<Grid
												item
												container
												justify='center'
												alignItems='center'
												direction='column'>
												<Grid item>
													<AssignmentIndOutlinedIcon
														style={{
															color: 'RGB(183, 194, 202)',
															width: '50px',
															height: '50px',
														}}
													/>
												</Grid>
												<Grid
													item
													style={{
														color: 'RGB(151, 196, 87)',
														fontSize: '12px',
													}}>
													+{' '}
													<span style={{ textDecoration: 'underline' }}>
														UPLOAD PAN CARD
													</span>
												</Grid>
											</Grid>
										</Grid>
									)}
								</label>
								<p
									style={{
										margin: '4px 14px 0px',
										color: 'red',
										fontSize: '12px',
										fontWeight: '0',
									}}>
									{' '}
									{formik.touched.image && formik.errors.image}
								</p>
							</Grid> */}
            </Grid>
            <Grid
              container
              item
              md={8}
              xs={11}
              // justify='center'
              alignItems="space-between"
              className={classes.inputFieldsContainer}
            >
              <Grid
                container
                item
                xs={12}
                className={classes.commonStyleInput}
                justify="center"
              >
                <TextField
                  label="FULL NAME"
                  fullWidth
                  variant="filled"
                  id="fullName"
                  name="fullName"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.fullName && Boolean(formik.errors.fullName)
                  }
                  helperText={formik.touched.fullName && formik.errors.fullName}
                  // value={selectedWorker?.fullName ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              {/* <Grid item xs={12}>
								<TextField
									select
									label='Contractor'
									size='small'
									style={{ fontFamily: 'Roboto' }}
									// placeholder='----'
									variant='filled'
									fullWidth
									// style={{ width: "300px" }}
									id='contractorId'
									name='contractorId'
									value={formik.values.contractorId}
									onChange={formik.handleChange}
									error={
										formik.touched.contractorId &&
										Boolean(formik.errors.contractorId)
									}
									helperText={
										formik.touched.contractorId && formik.errors.contractorId
									}
									InputProps={{
										disableUnderline: true,
										classes: {
											input: `${classes.textFieldStyle}  `,
											root: classes.input,
										},
									}}>
									<MenuItem value=''>Select Contractor</MenuItem>

									{usersList
										?.filter((user) => user.userTypeId === 'Contractor')
										.map((user) => (
											<MenuItem
												key={user.userId}
												value={user.userId}
												style={{ fontFamily: 'Roboto' }}>
												{user.fullName}
											</MenuItem>
										))}
								</TextField>
							</Grid> */}
              <Grid
                container
                item
                xs={12}
                justify="space-between"
                className={classes.commonStyleInput}
              >
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    type="date"
                    label="DATE OF BIRTH"
                    fullWidth
                    variant="filled"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    value={formik.values.dateOfBirth}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dateOfBirth &&
                      Boolean(formik.errors.dateOfBirth)
                    }
                    helperText={
                      formik.touched.dateOfBirth && formik.errors.dateOfBirth
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                    inputProps={{
                      max: moment().subtract(18, 'years').format('YYYY-MM-DD'),
                      min: moment().subtract(58, 'years').format('YYYY-MM-DD'),
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    label="GENDER"
                    size="small"
                    style={{
                      fontFamily: 'Roboto',
                      '& .MuiFilledInput-inputMarginDense': {
                        paddingTop: '110px',
                      },
                    }}
                    variant="filled"
                    fullWidth
                    id="gender"
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                    helperText={formik.touched.gender && formik.errors.gender}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value="0">Select Gender</MenuItem>

                    <MenuItem value={'Male'} style={{ fontFamily: 'Roboto' }}>
                      Male
                    </MenuItem>
                    <MenuItem value={'Female'} style={{ fontFamily: 'Roboto' }}>
                      Female
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: '1rem' }}>
                <TextField
                  inputProps={{ style: { fontFamily: 'Roboto' } }}
                  id="address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  variant="filled"
                  label="ADDRESS"
                  size="small"
                  fullWidth
                  // value={value}
                  // onChange={handleInput}
                  style={{
                    width: '100%',
                    // padding: '10px 14px',
                    // background: 'none',
                    borderRadius: '4px',
                    // border: 'none',
                    backgroundColor: '#F9F9F9',
                    // borderColor: '',
                    fontFamily: 'Roboto',
                    // outline: 'none',
                    // border:
                    // 	formik.touched.address &&
                    // 	Boolean( formik.errors.address ) &&
                    // 	'1px solid red',
                  }}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid
                container
                xs={12}
                justify="space-between"
                className={classes.commonStyleInput}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingRight: '1rem',
                  }}
                >
                  <TextField
                    label="ADHAR CARD NUMBER"
                    fullWidth
                    variant="filled"
                    id="identityNumber"
                    name="identityNumber"
                    value={formik.values.identityNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.identityNumber &&
                      Boolean(formik.errors.identityNumber)
                    }
                    helperText={
                      formik.touched.identityNumber &&
                      formik.errors.identityNumber
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PhoneInput
                    style={{
                      fontFamily: 'Roboto',
                    }}
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={(value) =>
                      formik.setFieldValue('phoneNumber', value)
                    }
                    country={'in'}
                    onlyCountries={['in']}
                    countryCodeEditable={false}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.commonStyleInput} container>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    select
                    label="SKILL"
                    size="small"
                    style={{ fontFamily: 'Roboto' }}
                    // placeholder='----'
                    variant="filled"
                    fullWidth
                    // style={{ width: "300px" }}
                    id="skillId"
                    name="skillId"
                    value={formik.values.skillId}
                    onChange={(e) => {
                      formik.setFieldValue('skillId', e.target.value);

                      // let check = skillsList.filter(
                      //   (skill) =>
                      //     skill.skillId === e.target.value &&
                      //     skill.name === 'Machine Operator'
                      // );

                      // setSkillLevelArray(['Supervisor', 'Skilled', 'Helper']);
                    }}
                    error={
                      formik.touched.skillId && Boolean(formik.errors.skillId)
                    }
                    helperText={formik.touched.skillId && formik.errors.skillId}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value="Select Skill">Select Skill</MenuItem>

                    {skillsList?.map((skill) => (
                      <MenuItem
                        key={skill.skillId}
                        value={skill.skillId}
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {skill.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid
                  // container
                  item
                  xs={6}
                  // className={classes.commonStyleInput}
                  // justify='center'
                >
                  <TextField
                    select
                    label="SKILL LEVEL"
                    size="small"
                    style={{ fontFamily: 'Roboto' }}
                    // placeholder='----'
                    variant="filled"
                    fullWidth
                    // style={{ width: "300px" }}
                    id="skillTypeId"
                    name="skillTypeId"
                    value={formik.values.skillTypeId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.skillTypeId &&
                      Boolean(formik.errors.skillTypeId)
                    }
                    helperText={
                      formik.touched.skillTypeId && formik.errors.skillTypeId
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value="Select Skill">Select Skill Level</MenuItem>

                    {skillLevelArray?.map((skill, index) => (
                      <MenuItem
                        key={index}
                        value={skill}
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {skill}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>{' '}
              {skillsList?.filter(
                (skill) =>
                  skill.skillId === formik.values.skillId &&
                  skill.name === 'Machine Operator'
              )[0]?.name === 'Machine Operator' && (
                <Grid item xs={12} className={classes.commonStyleInput}>
                  <TextField
                    select
                    label="SUB SKILL"
                    size="small"
                    style={{ fontFamily: 'Roboto' }}
                    // placeholder='----'
                    variant="filled"
                    fullWidth
                    // style={{ width: "300px" }}
                    id="skillTypeId"
                    name="skillTypeId"
                    // value={formik.values.skillTypeId}
                    // onChange={formik.handleChange}
                    // error={
                    //   formik.touched.skillTypeId &&
                    //   Boolean(formik.errors.skillTypeId)
                    // }
                    // helperText={
                    //   formik.touched.skillTypeId && formik.errors.skillTypeId
                    // }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value="Select Skill">Select Sub Skill </MenuItem>

                    {subSkill?.map((skill, index) => (
                      <MenuItem
                        key={index}
                        value={skill}
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {skill}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}{' '}
              <Grid
                container
                item
                xs={12}
                className={classes.commonStyleInput}
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <TextField
                    select
                    label="PROJECTS"
                    size="small"
                    style={{
                      fontFamily: 'Roboto',

                      paddingRight: '1rem',
                    }}
                    // placeholder='----'
                    variant="filled"
                    fullWidth
                    // style={{ width: "300px" }}
                    value={projectId}
                    onChange={handleProjectId}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}`,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value={''}>Select Project</MenuItem>

                    {projectClassificationList?.map((project) => (
                      <MenuItem
                        key={project.name}
                        value={project?.projectId}
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {project.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    label="JOBS"
                    size="small"
                    style={{ fontFamily: 'Roboto' }}
                    // placeholder='----'
                    variant="filled"
                    fullWidth
                    // style={{ width: "300px" }}

                    id="jobId"
                    name="jobId"
                    value={formik.values.jobId}
                    onChange={formik.handleChange}
                    error={formik.touched.jobId && Boolean(formik.errors.jobId)}
                    helperText={formik.touched.jobId && formik.errors.jobId}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}`,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value="">Select Job</MenuItem>

                    {listOfJobs
                      ?.filter(
                        (item) => item?.skillId === formik.values.skillId
                      )
                      ?.filter((item) => !item.isCompleted)
                      ?.map((job) => (
                        <MenuItem
                          key={job.jobName}
                          value={job?.jobId}
                          style={{ fontFamily: 'Roboto' }}
                        >
                          {job.jobName + ' - ' + job.description}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid xs={12} className={classes.commonStyleInput}>
                {' '}
                <TextField
                  label="BANK NAME"
                  fullWidth
                  variant="filled"
                  id="bankName"
                  name="bankName"
                  value={formik.values.bankName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.bankName && Boolean(formik.errors.bankName)
                  }
                  helperText={formik.touched.bankName && formik.errors.bankName}
                  // style={{ width: '715px' }}
                  // value={selectedWorker?.bankName ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />{' '}
              </Grid>
              <Grid
                container
                item
                xs={12}
                className={classes.commonStyleInput}
                justify="center"
              >
                <Grid item md={6} xs={12}>
                  <TextField
                    label="BANK ACCOUNT NUMBER"
                    fullWidth
                    id="bankAccountNumber"
                    name="bankAccountNumber"
                    value={formik.values.bankAccountNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.bankAccountNumber &&
                      Boolean(formik.errors.bankAccountNumber)
                    }
                    helperText={
                      formik.touched.bankAccountNumber &&
                      formik.errors.bankAccountNumber
                    }
                    variant="filled"
                    className={classes.bankAccountNumberField}
                    // value={selectedWorker?.bankAccountNumber ?? 'N/A'}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="IFSC CODE"
                    fullWidth
                    variant="filled"
                    // style={{ width: '715px' }}
                    // value={selectedWorker?.ifscCode ?? 'N/A'}
                    id="IFSCCode"
                    name="IFSCCode"
                    value={formik.values.IFSCCode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.IFSCCode && Boolean(formik.errors.IFSCCode)
                    }
                    helperText={
                      formik.touched.IFSCCode && formik.errors.IFSCCode
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              {/* <Grid
								container
								item
								xs={12}
								className={classes.commonStyleInput}
								justify='center'>
								<Grid
									item
									xs={6}
									container
									alignItems='center'
									// justify='space-between'
									style={
										{
											// paddingLeft: '10px',
										}
									}>
									<Checkbox
										style={{ color: 'rgba(152, 111, 239, 1)' }}
										id='healthCard'
										name='healthCard'
										value={formik.values.healthCard}
										onChange={formik.handleChange}
										error={
											formik.touched.healthCard &&
											Boolean(formik.errors.healthCard)
										}
									/>
									<Grid style={{ paddingLeft: '10px' }}>Health Card</Grid>
								</Grid>
								<Grid
									item
									xs={6}
									container
									alignItems='center'
									style={{
										// backgroundColor: '#ddd',
										paddingLeft: '10px',
									}}>
									<Checkbox
										style={{ color: 'rgba(152, 111, 239, 1)' }}
										id='policeVerification'
										name='policeVerification'
										value={formik.values.policeVerification}
										onChange={formik.handleChange}
										error={
											formik.touched.policeVerification &&
											Boolean(formik.errors.policeVerification)
										}
										// name='gilad'
									/>
									<Grid>Police Verification</Grid>
								</Grid>
							</Grid> */}
              <Grid
                container
                item
                justify="flex-end"
                style={{ marginTop: '0rem' }}
              >
                <Grid item style={{ marginRight: '1rem' }}>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    style={{
                      background: '#81B734',
                      color: '#fff',
                      // borderRadius: '10px',
                      padding: '10px 1.5rem',
                      fontSize: '12px',
                      textTransform: 'capitalize',
                    }}
                  >
                    Create Worker
                  </Button>
                </Grid>
                <Grid item>
                  <Link
                    to=".."
                    style={{ color: 'black' }}
                    onClick={() => dispatch(setWorkerForDetail(null))}
                  >
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      style={{
                        background: 'RGB(156, 117, 240)',
                        color: '#fff',
                        // borderRadius: '10px',
                        padding: '10px 2.5rem',
                        fontSize: '12px',
                        textTransform: 'capitalize',
                      }}
                    >
                      Cancel
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Toaster
            toastOptions={{
              // Define default options
              // className: "",
              duration: 10000,
              // style: {
              //   background: "#363636",
              //   color: "#fff",
              // },

              // Default options for specific types
              // success: {
              //   duration: 3000,
              //   theme: {
              //     primary: "green",
              //     secondary: "black",
              //   },
              // },
            }}
          />
        </form>
      </div>
    </Grid>
  );
};

export default CreateWorker;
