import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Avatar,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import defaultProjectImage from '../../../assests/Projects Icon.png';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const CustomTextField = withStyles({
  root: {
    '& .MuiInput-underlin': {
      // borderBottomColor: '#133', // Default underline color
    },
    '& .MuiInput-underline:hover:before': {
      // borderBottomColor: '#1976d2', // Hover underline color
    },
    '& .MuiInput-underline:after': {
      // borderBottomColor: '#1976d2', // Focused underline color
    },
  },
})(TextField);

export default function GlobalRow(props) {
  return (
    <Grid
      container
      id="topRow"
      style={
        {
          // marginBottom: '10px',
        }
      }
    >
      <Grid
        container
        item
        md={3}
        xs={12}
        className={props.classes.inputFieldGridItem}
        alignItems="center"
      >
        <Grid item style={{ paddingRight: '10px' }}>
          <Avatar
            alt="Project Image"
            src={
              !props.formik.values.projectId?.url
                ? defaultProjectImage
                : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${props.formik.values.projectId?.url}`
            }
            style={{ width: '45px', height: '45px' }}
          />
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              fontSize: 12,
              color: '#ADBAC3',
              marginBottom: 5,
              fontFamily: 'Roboto',
            }}
          >
            SELECT PROJECT
          </Typography>
          <CustomTextField
            select
            size="small"
            style={{
              marginBottom: '1rem',
              fontFamily: 'Roboto',
            }} // placeholder='----'
            // variant="outlined"
            fullWidth
            disabled={props.selectedJob ? true : false} // style={{ width: "300px" }}
            id="projectId"
            name="projectId"
            value={props.formik.values.projectId}
            onChange={(data) => props.handleProjectChange(data.target.value)}
            error={
              props.formik.touched.projectId &&
              Boolean(props.formik.errors.projectId)
            }
            helperText={
              props.formik.touched.projectId && props.formik.errors.projectId
            }
            InputProps={{
              style: {
                // Default underline
                borderBottom: '1px solid #ADBAC3',
                // Focused underline
                '&:focus': {
                  borderBottom: '2px solid #1976d2',
                },
              },
              disableUnderline: false, // Allows customizing the underline
            }}
          >
            {props.projectsList?.map((project) => (
              <MenuItem
                key={project.projectId}
                value={props.selectedJob ? project?.projectId : project}
                style={{
                  fontFamily: 'Roboto',
                }} // onClick={() => handleProjectChange(project)}
              >
                {project.name}
              </MenuItem>
            ))}
          </CustomTextField>
        </Grid>
      </Grid>

      <Grid item md={2} xs={12} className={props.classes.inputFieldGridItem}>
        <Typography
          style={{
            fontSize: 12,
            color: '#ADBAC3',
            marginBottom: 5,
            fontFamily: 'Roboto',
          }}
        >
          SUPERVISOR NAME
        </Typography>
        <CustomTextField
          size="small"
          // variant="outlined"
          fullWidth // type="number"
          style={{
            marginBottom: '1rem',
          }}
          id="supervisorName"
          name="supervisorName"
          value={props.formik.values.supervisorName}
          onChange={props.formik.handleChange}
          error={
            props.formik.touched.supervisorName &&
            Boolean(props.formik.errors.supervisorName)
          }
          helperText={
            props.formik.touched.supervisorName &&
            props.formik.errors.supervisorName
          }
          inputProps={{
            style: {
              fontFamily: 'Roboto',
            },
          }}
        />
      </Grid>

      <Grid item md={2} xs={12} className={props.classes.inputFieldGridItem}>
        <Typography
          style={{
            fontSize: 12,
            color: '#ADBAC3',
            marginBottom: 5,
            fontFamily: 'Roboto',
          }}
        >
          START DATE
        </Typography>
        <TextField
          size="small"
          id="startDate"
          disabled={props.selectedJob ? true : false}
          style={{
            marginBottom: '1rem',
          }}
          name="startDate"
          value={props.formik.values.startDate}
          onChange={props.formik.handleChange}
          error={
            props.formik.touched.startDate &&
            Boolean(props.formik.errors.startDate)
          }
          helperText={
            props.formik.touched.startDate && props.formik.errors.startDate
          }
          // variant="outlined" // type='datetime-local'
          type="date"
          className={props.classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            style: {
              fontFamily: 'Roboto',
            },
            min: !props.selectedJob && moment().format('YYYY-MM-DD'),
          }}
        />
      </Grid>

      <Grid item md={2} xs={12} className={props.classes.inputFieldGridItem}>
        <Typography
          style={{
            fontSize: 12,
            color: '#ADBAC3',
            marginBottom: 5,
            fontFamily: 'Roboto',
          }}
        >
          REPORTING TIME
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            margin="normal"
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            size="small"
            style={{
              margin: '0',
              // marginBottom: '1rem',
              fontFamily: 'Roboto',
            }} // variant="outlined"
            className={props.classes.textField}
            id="reportingTime"
            name="reportingTime"
            value={props.formik.values.reportingTime}
            onChange={(time) => {
              props.formik.setFieldValue('reportingTime', time);
            }}
            error={
              props.formik.touched.reportingTime &&
              Boolean(props.formik.errors.reportingTime)
            } // disabled
            helperText={
              props.formik.touched.reportingTime &&
              props.formik.errors.reportingTime
            }
            TextFieldComponent={(props) => (
              <TextField
                {...props}
                size="small"
                placeholder="----------"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </MuiPickersUtilsProvider>
        {/* <Divider orientation="vertical" flexItem /> */}
      </Grid>
      <Grid container md={3} xs={12} alignItems="center">
        <Grid item style={{ paddingRight: '15px' }}>
          <Divider orientation="vertical" style={{ height: '40px' }} />
        </Grid>
        <Grid item>
          <Typography
            style={{
              fontSize: 12,
              color: '#ADBAC3',
              marginBottom: 5,
              fontFamily: 'Roboto',
            }}
          >
            ENABLE FACILITIES
          </Typography>

          <Grid container item flexGrow={1}>
            <Grid item>
              {' '}
              <FormControlLabel
                control={
                  <Switch
                    // color="primary"
                    id="isFood"
                    name="isFood"
                    checked={props.formik.values.isFood}
                    onChange={props.formik.handleChange}
                  />
                  // <Checkbox // checked={jason}
                  //   color="primary"
                  //   disabled={props.selectedJob ? true : false}
                  //   checked={props.formik.values.isFood}
                  //   id="isFood"
                  //   name="isFood"
                  //   value={props.formik.values.isFood}
                  //   onChange={props.formik.handleChange}
                  // />
                }
                label="Food"
              />
            </Grid>
            <Grid item>
              {' '}
              <FormControlLabel
                control={
                  <Switch
                    // color="primary"
                    disabled={props.selectedJob ? true : false}
                    checked={props.formik.values.isAccomodation}
                    id="isAccomodation"
                    name="isAccomodation"
                    value={props.formik.values.isAccomodation}
                    onChange={props.formik.handleChange}
                  />
                  // <Checkbox // checked={jason}
                  //   color="primary"
                  //   disabled={props.selectedJob ? true : false}
                  //   checked={props.formik.values.isAccomodation}
                  //   id="isAccomodation"
                  //   name="isAccomodation"
                  //   value={props.formik.values.isAccomodation}
                  //   onChange={props.formik.handleChange}
                  // />
                }
                label="Accomodation"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
