import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { useState } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const JobRowItem = ({
  row,
  classes,
  handleRowInputField,
  skillsList,
  addNewRow,
  isLastItem,
  deleteRow,
  checkHover,
  validateRowFields,
  index,
}) => {
  const [errors, setErrors] = useState({});
  const validateField = (field, value) => {
    if (!value || value === 'Select Skill') {
      setErrors((prev) => ({ ...prev, [field]: true }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, [field]: false }));
      return true;
    }
  };

  const validateRow = () => {
    const isSkillSetValid = validateField('skillId', row.skillId);
    const isSkillLevelValid = validateField('skillTypeId', row.skillTypeId);
    const isJobTypeValid = validateField('isNMR', row.isNMR);
    const isWorkersValid = validateField(
      'requiredWorkers',
      row.requiredWorkers
    );
    const isManDaysValid = validateField('manDays', row.manDays);
    const isDescriptionValid = validateField('description', row.description);

    return (
      isSkillSetValid &&
      isSkillLevelValid &&
      isJobTypeValid &&
      isWorkersValid &&
      isManDaysValid &&
      isDescriptionValid
    );
  };

  // React.useEffect(() => {
  //   validateRowFields(row.id, validateRow());
  // }, [row]);

  return (
    <Card
      container
      item
      style={{
        borderRadius: '5px',
        width: '98%',
        marginBottom: '20px',
      }}
      className={classes.cardRoot}
      onMouseEnter={() => checkHover(row.id)}
      onMouseLeave={() => checkHover(false)}
    >
      <CardHeader
        style={{ paddingBottom: 0 }}
        avatar={
          <Avatar style={{ width: '25px', height: '25px', fontSize: '12px' }}>
            {index < 8 ? `0${index + 1}` : index + 1}
          </Avatar>
        }
        action={
          row.isHovered && (
            <IconButton size="small" style={{ padding: 0 }}>
              {
                //   isLastItem ? (
                //   <AddCircleOutlineIcon
                //     style={{ color: '81B734', cursor: 'pointer' }}
                //     onClick={addNewRow}
                //   />
                // ) :

                <HighlightOffIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteRow(row.id)}
                />
              }
            </IconButton>
          )
        }
        title={
          <Grid container md={3} xs={12} alignItems="center">
            <TextField
              select
              size="small"
              style={{
                fontFamily: 'Roboto',
              }}
              fullWidth
              value={row.skillId}
              onChange={(e) => {
                handleRowInputField('skillId', row.id, e.target.value);
              }}
              error={!!errors.skillId}
              helperText={errors.skillId && 'This field is required'}
              InputProps={{
                disableUnderline: true,
              }}
            >
              <MenuItem value="Select Skill">Select Skill</MenuItem>

              {skillsList?.map((skill) => (
                <MenuItem
                  key={skill.skillId}
                  value={skill}
                  style={{
                    fontFamily: 'Roboto',
                  }}
                >
                  {skill.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        }
      />
      <CardContent style={{ paddingTop: '0' }}>
        <Divider style={{ margin: '5px 0 10px 0' }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <TextField
              label="SKILL LEVEL"
              select
              size="small"
              style={{
                fontFamily: 'Roboto',
              }} // placeholder='----'
              //  disabled={selectedJob ? true : false}
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              fullWidth // style={{ width: "300px" }}
              // id="skillId"
              // name="skillId"
              value={row.skillTypeId}
              onChange={(e) => {
                handleRowInputField('skillTypeId', row.id, e.target.value);
              }}
              error={!!errors.skillTypeId}
              helperText={errors.skillTypeId && 'This field is required'}
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRadius: '8px', // Set border radius
                },
              }}
            >
              {['Supervisor', 'Skilled', 'Helper']?.map((skill) => (
                <MenuItem
                  key={skill}
                  value={skill}
                  style={{
                    fontFamily: 'Roboto',
                  }}
                >
                  {skill}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="JOB TYPE"
              select
              size="small"
              style={{
                fontFamily: 'Roboto',
              }} // placeholder='----'
              //  disabled={selectedJob ? true : false}
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              fullWidth // style={{ width: "300px" }}
              // id="skillId"
              // name="skillId"
              value={row?.isNMR}
              onChange={(e) => {
                handleRowInputField('isNMR', row.id, e.target.value);
              }}
              error={!!errors.isNMR}
              helperText={errors.isNMR && 'This field is required'}
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRadius: '8px', // Set border radius
                },
              }}
            >
              {[
                { name: 'NMR', value: true },
                { name: 'PRW', value: false },
              ]?.map((skill) => (
                <MenuItem
                  key={skill.value}
                  value={skill.value}
                  style={{
                    fontFamily: 'Roboto',
                  }}
                >
                  {skill.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="REQUIRED WORKERS"
              size="small"
              variant="filled"
              value={row.requiredWorkers}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                handleRowInputField('requiredWorkers', row.id, e.target.value);
              }}
              error={!!errors.requiredWorkers}
              helperText={errors.requiredWorkers && 'This field is required'}
              inputProps={{
                style: {
                  fontFamily: 'Roboto',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRadius: '8px', // Set border radius
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              label="MAN DAYS"
              size="small"
              variant="filled" // type='datetime-local'
              type="number"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              value={row.manDays}
              onChange={(e) => {
                handleRowInputField('manDays', row.id, e.target.value);
              }}
              error={!!errors.manDays}
              helperText={errors.manDays && 'This field is required'}
              inputProps={{
                style: {
                  fontFamily: 'Roboto',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRadius: '8px', // Set border radius
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} className={classes.scopeOfWork}>
            <TextField
              label="SCOPE OF WORK"
              size="small"
              style={{
                marginBottom: '1rem',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={row.description}
              onChange={(e) => {
                handleRowInputField('description', row.id, e.target.value);
              }}
              error={!!errors.description}
              helperText={errors.description && 'This field is required'}
              variant="filled"
              fullWidth // type='number'
              inputProps={{
                style: {
                  fontFamily: 'Roboto',
                },
              }} // style={{ width: "300px" }}
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRadius: '8px', // Set border radius
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} className={classes.scopeOfWork}>
            <TextField
              label="DAILY WAGE"
              type="number"
              size="small"
              style={{
                marginBottom: '1rem',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={row.dailyWage}
              onChange={(e) => {
                handleRowInputField('dailyWage', row.id, e.target.value);
              }}
              error={!!errors.dailyWage}
              helperText={errors.dailyWage && 'This field is required'}
              variant="filled"
              fullWidth // type='number'
              inputProps={{
                style: {
                  fontFamily: 'Roboto',
                },
              }} // style={{ width: "300px" }}
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRadius: '8px', // Set border radius
                },
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default JobRowItem;
