import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  Grid,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  LinearProgress,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Zoom,
} from '@material-ui/core';
import { jobReducer, getJobs, selectJob, completeJob } from '../../redux/jobs';
import { useSelector, useDispatch } from 'react-redux';

import SortSelection from 'src/components/SortSelection';
import DatePicker, { registerLocale } from 'react-datepicker';
import SearchInput from 'src/components/SearchInput';
import EventNoteIcon from '@material-ui/icons/EventNote';
import es from 'date-fns/locale/es';
import { projectReducer } from 'src/redux/projects';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { useNavigate } from 'react-router';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import defaultProjectImage from '../../assests/Projects Icon.png';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import UserContext from 'src/context/UserContext';
import { getContractors } from 'src/redux/users';

registerLocale('es', es);

const useStyles = makeStyles((theme) => ({
  listSection: {
    backgroundColor: 'inherit',
    margin: '5px',
    cursor: 'pointer',
  },

  ul: {
    backgroundColor: 'inherit',
    // padding: 0,
  },
  root: {
    // backgroundColor: theme.palette.background.dark,
    // minHeight: '100%',
    // maxWidth: '400px',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    borderRadius: '10px',
    padding: '.5rem 1rem ',
    textTransform: 'capitalize',
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '100%',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  searchFilterContainer: {
    // marginLeft: '15px',
  },
  marginBottom: {
    marginBottom: 0,
  },

  [theme.breakpoints.down('sm')]: {
    searchFilterContainer: {
      marginLeft: '0px',
    },
    marginBottom: {
      marginBottom: '15px',
    },
  },
}));

const JobList = () => {
  const [currentProject, setCurrentProject] = useState('');
  const [classifiedProject, setClassifiedProject] = useState('');
  const [filteredArray, setFilteredArray] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    range: '',
    project: 0,
  });
  const [LabourContractor, setLabourContractor] = useState('');

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [modals, setModals] = useState({
    createNewJob: false,
    details: false,
    editMode: false,
    endJob: false,
  });
  const [userData, setUserData] = useContext(UserContext);

  //! INTANCES
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { addToast } = useToasts();
  const classes = useStyles();

  //! SELECTORS
  const {
    loading,
    jobsList,
    skillsList,
    completeJobLoading,
    filteredJobList,
    error,
  } = useSelector(jobReducer);
  const { projectClassificationList } = useSelector(projectReducer);

  useEffect(() => {
    if (filteredJobList) {
      if (filteredJobList?.length === 0) {
        setFilteredArray(null);
      } else {
        setFilteredArray(filteredJobList);
      }
    }
  }, [filteredJobList]);

  //! METHODS
  const handleFilters = (e) => {
    const { value, name } = e.target;
    if (name === 'project') {
      setFilters({ ...filters, project: value });
      if (
        jobsList?.filter((job) => job.projectId === parseInt(value)).length ===
          0 &&
        value != 0
      ) {
        return setFilteredArray(null);
      }
      return setFilteredArray(
        jobsList.filter((job) => job.projectId === parseInt(value))
      );
    }

    if (value !== 0) {
      setFilters({ ...filters, sortBy: value });
      if (
        jobsList?.filter((job) => job.jobName === value).length === 0 &&
        value != 0
      )
        return setFilteredArray(null);

      setFilteredArray(jobsList.filter((job) => job.jobName === value));
    }
  };

  const classifyProject = (project) => {
    if (project) {
      const { projectId } = project;
      dispatch(getContractors(projectId));
      setLabourContractor(0);

      const list = jobsList?.filter(
        (job) => job.projectId === projectId && job
      );
      console.log(
        'jobList',
        jobsList.filter((job) => job.jobId === 948)
      );
      console.log('filteredJobList', list);

      setCurrentProject(project);

      setClassifiedProject(
        projectClassificationList?.filter(
          (project) => project.projectId === projectId && project
        )
      );

      if (list?.length === 0) {
        return setFilteredArray(null);
      }
      setFilteredArray(list);
    } else {
      dispatch(getContractors());
      setLabourContractor(0);
      setCurrentProject('');
      setClassifiedProject(
        projectClassificationList?.filter(
          (p) => p.projectId === project?.projectId && p
        )
      );

      return setFilteredArray([]);
    }
  };

  const openEndJobModal = (job) => {
    setSelectedJob(job);
    setModals({ ...modals, endJob: true });
  };

  const closeEndJobModal = () => {
    setModals({ ...modals, endJob: false });
  };
  const endTheJob = async () => {
    let resp = await dispatch(completeJob(selectedJob?.jobId));
    if (resp.status === 200) {
      dispatch(getJobs());
      setModals({ endJob: false });
      return addToast('Job Completed Successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
    if (resp?.response?.data) {
      return addToast(resp.response.data, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    setFilters({ ...filters, search: value });

    if (value) {
      const data =
        parseInt(filters.sortBy) !== 0 || dateRange[1]
          ? filteredArray
          : jobsList;

      let newFilter = data.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilteredArray(newFilter);
    }

    if (!value) {
      return setFilteredArray([]);
    }
  };
  const handleDateRange = (update) => {
    let [start, end] = update;
    setDateRange(update);
    const dynamicJobList = filteredArray?.length > 0 ? filteredArray : jobsList;

    if (start && !end) {
      let newData = dynamicJobList.filter((job) => {
        const startDateOnly = new Date(job.startDate).setHours(0, 0, 0, 0);
        // const endDateOnly = new Date(job.endDate).setHours(0, 0, 0, 0);
        if (startDateOnly === start.getTime()) {
          return job;
        }
      });
      if (newData?.length === 0) {
        setFilteredArray(null);
      } else {
        setFilteredArray(newData);
      }
    }

    if (end) {
      let newData = dynamicJobList.filter((job) => {
        const startDateOnly = new Date(job.startDate).setHours(0, 0, 0, 0);

        if (
          startDateOnly >= start.getTime() &&
          startDateOnly <= end.getTime()
        ) {
          return job;
        }
      });

      if (newData?.length === 0) {
        return setFilteredArray(null);
      } else {
        return setFilteredArray(newData);
      }
    }
    if (!start && !end) {
      if (filters.sortBy !== 0) {
        return handleFilters({ target: { value: filters.sortBy } });
      }
      return setFilteredArray([]);
    }
  };
  const openDetails = (val) => {
    dispatch(selectJob(val));
    navigate('detail');
  };

  //! SUB-COMPONENTS
  const handleLabourContractor = (val) => {
    setLabourContractor(val);
    if (!val) {
      return dispatch(getJobs());
    }
    dispatch(getJobs(val?.userId));
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ marginTop: '10px' }}
    >
      <Dialog open={modals.endJob} onClose={closeEndJobModal} keepMounted>
        <DialogTitle>Job Name: {selectedJob?.jobName ?? 'N/A'}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ width: '350px' }}>
            {completeJobLoading && <LinearProgress />}
            Are you sure you want to end the job?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container xs={9} justify="flex-end" alignItems="center">
            <Grid item xs={6} style={{ paddingRight: '10px' }}>
              <Button
                fullWidth
                // disabled
                onClick={endTheJob}
                className={`${classes.buttonPurpleOpacity}`}
                style={{
                  backgroundColor: '#908DA6',
                  color: '#FFFFFF',
                  borderRadius: '9px',
                  fontSize: '11px',
                  fontWeight: 500,
                  textTransform: 'capitalize',
                }}
                size="small"
                // startIcon={<ReceiptIcon />}
              >
                End Job
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                autoFocus
                variant="contained"
                // disabled
                className={`${classes.buttonPurpleOpacity}`}
                style={{
                  backgroundColor: '#81B734',
                  color: '#fff',
                  borderRadius: '9px',
                  fontSize: '11px',
                  fontWeight: 500,
                  textTransform: 'capitalize',
                }}
                size="small"
                // startIcon={<ReceiptIcon />}
                onClick={closeEndJobModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Grid container xs={12} justify="flex-start">
        <Grid
          item
          md={4}
          xs={12}
          container
          alignItems="center"
          justify="flex-start"
          style={{ marginBottom: '1rem' }}
        >
          <Grid item style={{ fontWeight: 600 }}>
            Jobs |{' '}
          </Grid>
          <Grid
            item
            // md={5}
            // xs={12}
            // container
            // alignItems='center'
            // justify='flex-start'
            style={{ padding: '2px' }}
          >
            <FilterProjectComponent
              projectClassificationList={projectClassificationList}
              src={
                !classifiedProject[0]?.url
                  ? defaultProjectImage
                  : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
              }
              value={currentProject}
              onChange={classifyProject}
            />
          </Grid>
        </Grid>
        {/* //! 1.Filter Labour Contractor */}
        <Grid
          item
          md={3}
          xs={12}
          style={{ paddingTop: '5px' }}
          // style={{ marginBottom: "15px" }}
          className={classes.marginBottom}
        >
          {userData?.user?.leadTypeId !== 'LabourContractor' && (
            // <Grid
            //   // style={{ border: '2px solid red' }}
            //   item
            //   md={3}
            //   xs={12}
            //   className={classes.marginBottom}
            // >
            <FilterByLabourContractorComponent
              value={LabourContractor}
              onChange={handleLabourContractor}
              setInitialUser={(user) => setLabourContractor(user)}
            />
            // </Grid>
          )}
        </Grid>
      </Grid>

      {/* //! UPPER FILTERS */}
      <Grid
        xs={12}
        container
        alignItems="center"
        className="input-container-nav"
        justify="space-between"
        // spacing={2}
        // style={{border: '2px solid red'}}
      >
        {/* //! 1.SortInput */}
        <Grid
          item
          md={3}
          xs={12}
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid item md={2}>
            <small>Skills:</small>
          </Grid>
          <Grid item xs={9}>
            <SortSelection
              initialValue="skills"
              value={filters.sortBy}
              handleFilters={handleFilters}
              data={skillsList?.map((skill) => ({
                value: skill.name,
                label: skill.name,
              }))}
            />
          </Grid>
        </Grid>
        {/* //! 2.DatePickerInput */}
        <Grid
          item
          md={4}
          xs={12}
          container
          alignItems="center"
          justify="space-between"
        >
          {/* <div style={{ width: '100%', position: 'relative' }}> */}
          <Grid item xs={3} md={3} className={classes.searchFilterContainer}>
            <small>Search Filters:</small>
          </Grid>
          <Grid
            item
            style={{
              position: 'relative',
              // padding: '1rem',
            }}
            xs={9}
          >
            <DatePicker
              dateFormat="MMMM d, yyyy"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                handleDateRange(update);
              }}
              isClearable={true}
              className="font12 date-picker "
            />
            {!startDate && (
              <EventNoteIcon
                style={{
                  position: 'absolute',
                  right: '5px',
                  top: '7px',
                  color: '#ADBAC3',
                }}
              />
            )}
          </Grid>
        </Grid>
        {/* //! 3.SearchInput */}
        <Grid
          item
          md={3}
          xs={12}
          container
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={2}>
            <small>Search:</small>
          </Grid>
          <Grid item style={{ position: 'relative' }} xs={9}>
            <SearchInput
              // style={{ width: '100%' }}
              search={filters.search}
              handleSearch={handleSearch}
              // className='font12'
            />
          </Grid>
        </Grid>
      </Grid>
      {/* //! LOWER FILTERS */}
      <Grid
        container
        item
        xs={12}
        style={{
          marginTop: '1rem',
          // overflowX: "scroll",
        }}
      >
        {loading ? (
          <div
            style={{
              textAlign: 'center',
              height: '76vh',
              width: '100%',
            }}
          >
            <CircularProgress style={{ color: '#81B734' }} />
          </div>
        ) : (
          // <Zoom in={jobsList?.length > 0 && !loading ? true : false}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.headerCellText1}>
                  JOB ID
                </TableCell>
                <TableCell align="center" className={classes.headerCellText1}>
                  JOB NAME
                </TableCell>
                <TableCell align="center" className={classes.headerCellText1}>
                  JOB DESCRIPTION
                </TableCell>
                <TableCell align="center" className={classes.headerCellText1}>
                  PROJECT NAME
                </TableCell>
                <TableCell align="center" className={classes.headerCellText1}>
                  START DATE
                </TableCell>
                <TableCell align="center" className={classes.headerCellText1}>
                  LOCATION
                </TableCell>

                <TableCell align="center" className={classes.headerCellText1}>
                  WAGE
                  {/* ₹ */}
                </TableCell>
                <TableCell align="center" className={classes.headerCellText1}>
                  REQUIRED WORKERS
                </TableCell>

                <TableCell align="center" className={classes.headerCellText1}>
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredArray?.length ? (
                filteredArray?.map((val) => (
                  <TableRow hover key={val.jobId}>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="14%"
                    >
                      {val.jobId}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="14%"
                    >
                      {val.jobName}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="12%"
                    >
                      {val.description ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="12%"
                    >
                      {val.projectName}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="12%"
                    >
                      <div className="flex" style={{ paddingBottom: '5px' }}>
                        <div className="circle-green"></div>
                        {moment(val.startDate).format('DD MMM YYYY')}
                      </div>
                      {/* <div className='flex'>
													<div className='circle-red'></div>
													{moment(val.endDate).format(' DD MMM YYYY')}
												</div> */}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="12%"
                    >
                      <Grid container>{val.cityName}</Grid>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="12%"
                    >
                      <div>
                        <div>
                          Total:{' '}
                          <strong>₹{val?.totalWage?.toLocaleString()}</strong>
                        </div>
                        <div>
                          Daily:{' '}
                          <strong>₹{val?.dailyWage?.toLocaleString()}</strong>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="14%"
                    >
                      {val.activeWorkers} /{val.requiredWorkers}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="40%"
                    >
                      <Grid
                        container
                        xs={11}
                        justify="center"
                        alignItems="center"
                        // style={{ border: '2px solid red' }}
                      >
                        <Grid item xs={12} style={{ marginBottom: '10px' }}>
                          <Button
                            fullWidth
                            // disabled
                            className={`${classes.buttonPurpleOpacity}`}
                            style={{
                              // padding: '.6rem 1rem',
                              borderRadius: '9px',
                              fontSize: '11px',
                              fontWeight: 500,
                              textTransform: 'capitalize',
                            }}
                            size="small"
                            // startIcon={<ReceiptIcon />}
                            onClick={() => openDetails(val)}
                          >
                            Details
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            onClick={() => openEndJobModal(val)}
                            fullWidth
                            variant="contained"
                            disabled={val.isCompleted}
                            className={`${classes.buttonPurpleOpacity}`}
                            style={{
                              backgroundColor: '#908DA6',
                              color: '#fff',
                              padding: '.5rem .5rem',
                              borderRadius: '9px',
                              fontSize: '11px',
                              fontWeight: 500,
                              textTransform: 'capitalize',
                              opacity: val.isCompleted ? 0.5 : 1,
                            }}
                            size="small"
                            // startIcon={<ReceiptIcon />}
                            // onClick={() => openDetails(val)}
                          >
                            {val.isCompleted ? 'Completed' : 'End Job'}
                          </Button>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                          <a
                            href={`https://api.whatsapp.com/send?text=https://www.bettamint.com/jobs/job.php?id=${val.jobId}`}
                            data-action="share/whatsapp/share"
                            target="_blank"
                            without
                            rel="noreferrer"
                          >
                            <Button
                              fullWidth
                              // disabled
                              className={`${classes.buttonPurpleOpacity}`}
                              style={{
                                // padding: '.6rem 1rem',
                                borderRadius: '9px',
                                fontSize: '11px',
                                fontWeight: 500,
                                textTransform: 'capitalize',
                                color: 'green',
                                backgroundColor: 'RGBA(0, 128, 0, .2)',
                              }}
                              size="small"
                              startIcon={<WhatsAppIcon />}
                            >
                              Share
                            </Button>
                          </a>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              ) : filters.search !== '' || filteredArray === null ? (
                <TableRow>No Search Found!</TableRow>
              ) : loading === false && error ? (
                <TableRow>{error}</TableRow>
              ) : loading === false && error === null && jobsList === null ? (
                <TableRow>No Jobs yet!</TableRow>
              ) : (
                jobsList?.map((val) => (
                  <TableRow hover key={val.jobId}>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="7%"
                    >
                      {val.jobId}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="14%"
                    >
                      {val.jobName}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="12%"
                    >
                      {val.description ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="12%"
                    >
                      {val.projectName}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="12%"
                    >
                      <div className="flex" style={{ paddingBottom: '5px' }}>
                        <div className="circle-green"></div>
                        {moment(val.startDate).format('DD MMM YYYY')}
                      </div>
                      {/* <div className='flex'>
													<div className='circle-red'></div>
													{moment(val.endDate).format(' DD MMM YYYY')}
												</div> */}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.headerCellText}
                      width="12%"
                    >
                      <Grid container>{val.cityName}</Grid>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="12%"
                    >
                      <div>
                        <div>
                          Total:{' '}
                          <strong>₹{val?.totalWage?.toLocaleString()}</strong>
                        </div>
                        <div>
                          Daily:{' '}
                          <strong>₹{val?.dailyWage?.toLocaleString()}</strong>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="14%"
                    >
                      {val.activeWorkers} /{val.requiredWorkers}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText}
                      width="40%"
                    >
                      <Grid
                        container
                        xs={11}
                        justify="center"
                        alignItems="center"
                        // style={{ border: '2px solid red' }}
                      >
                        <Grid item xs={12} style={{ marginBottom: '10px' }}>
                          <Button
                            fullWidth
                            // disabled
                            className={`${classes.buttonPurpleOpacity}`}
                            style={{
                              // padding: '.6rem 1rem',
                              borderRadius: '9px',
                              fontSize: '11px',
                              fontWeight: 500,
                              textTransform: 'capitalize',
                            }}
                            size="small"
                            // startIcon={<ReceiptIcon />}
                            onClick={() => openDetails(val)}
                          >
                            Details
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            onClick={() => openEndJobModal(val)}
                            fullWidth
                            variant="contained"
                            disabled={val.isCompleted}
                            className={`${classes.buttonPurpleOpacity}`}
                            style={{
                              // backgroundColor: "#81B734",
                              backgroundColor: '#908DA6',

                              color: '#fff',
                              padding: '.5rem .5rem',
                              borderRadius: '9px',
                              fontSize: '11px',
                              fontWeight: 500,
                              textTransform: 'capitalize',
                              opacity: val.isCompleted ? 0.5 : 1,
                            }}
                            size="small"
                            // startIcon={<ReceiptIcon />}
                            // onClick={() => openDetails(val)}
                          >
                            {val.isCompleted ? 'Completed' : 'End Job'}
                          </Button>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                          <a
                            href={`https://api.whatsapp.com/send?text=https://www.bettamint.com/jobs/job.php?id=${val.jobId}`}
                            data-action="share/whatsapp/share"
                            target="_blank"
                            without
                            rel="noreferrer"
                          >
                            <Button
                              fullWidth
                              // disabled
                              className={`${classes.buttonPurpleOpacity}`}
                              style={{
                                // padding: '.6rem 1rem',
                                borderRadius: '9px',
                                fontSize: '11px',
                                fontWeight: 500,
                                textTransform: 'capitalize',
                                color: 'green',
                                backgroundColor: 'RGBA(0, 128, 0, .2)',
                              }}
                              size="small"
                              startIcon={<WhatsAppIcon />}
                            >
                              Share
                            </Button>
                          </a>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          //  </Zoom>
        )}
      </Grid>
    </Grid>
  );
};
export default JobList;
